/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";

import Storage from "utils/storage";
import Config from "config/env";
import FullStory from "utils/fullstory";
import Tracker from "utils/tracker";
import { div, h2 } from "react-dom-factories";
import { createStore } from "@evertrue/et-flux";
import ApiSource from "base/api-source";
import SessionSource from "apps/auth/sources/session-source";
import PoolSource from "core/apps/pools/pool-source";
import Decorator from "@evertrue/client-decorator";
import { OverlayActions } from "@evertrue/et-components";
import UserDetails from "apps/auth/components/user-details";
import SocketSource from "base/socket-source";

const { Raven } = window;

export default createStore("SessionStore", {
  getInitialState() {
    return {
      session: {},
      loading: true, // set to true b/c we want to load this before letting routing kick in. So deep linking works
      impersonated_user: {},
    };
  },

  registerActions() {
    this.on(SessionSource.actions.setSession, function(session) {
      const prev_user = __guard__(this.getState("session"), x => x.user);
      const new_user = session.user || {};

      SocketSource.subscribeUser(new_user.id);
      this.setState({ session });

      if (new_user && !_.isEqual(prev_user, new_user)) {
        return this.respondToNewUser(new_user);
      } else if (!new_user) {
        return Storage.remove("user_id");
      }
    });

    this.on(SessionSource.actions.loading, function(is_loading) {
      return this.setState({ loading: is_loading });
    });

    this.on(SessionSource.actions.setImpersonatedUser, this.respondToSetImpersonatedUser);

    this.on(ApiSource.actions.sessionRefreshed, session => SessionSource.setRefreshedSession(session));

    this.on(ApiSource.actions.forbidden, SessionSource.logout);

    return this.on(ApiSource.actions.mfaRequired, SessionSource.mfaRequired);
  },

  lastListenerWillUnmount() {
    const user_id = __guard__(this.state.session != null ? this.state.session.user : undefined, x => x.id);
    if (user_id) {
      return SocketSource.unsubscribeUserFromPusher(user_id);
    }
  },

  respondToSetImpersonatedUser(impersonated_user) {
    const session = _.cloneData(this.getState("session"));

    // if no user to impersonate, restore user to original and remove impersonator
    if (_.isEmpty(impersonated_user)) {
      const real_user = JSON.parse(Storage.get("real_super_user"));
      if (real_user) {
        Tracker.trackIsImpersonating(false);
        if (Raven != null) {
          Raven.setExtraContext({ is_impersonating: false });
        }
        Storage.remove("impersonated_user");
        Storage.remove("real_super_user");
        session.user = real_user;
      }
    } else {
      Tracker.trackIsImpersonating(true);
      if (Raven != null) {
        Raven.setExtraContext({ is_impersonating: true });
      }
      Storage.set("real_super_user", JSON.stringify(session.user));
      Storage.set("impersonated_user", JSON.stringify(impersonated_user));
      session.user = impersonated_user;
    }

    this.setState({ impersonated_user, session });
    // reload data, may need to improve this in the future to do more than just refresh pools
    return PoolSource.fetch();
  },

  respondToNewUser(new_user) {
    if (Raven != null) {
      Raven.setUserContext(new_user);
    }
    FullStory.setUser(new_user);

    const old_id = parseInt(Storage.get("user_id"));
    Storage.set("user_id", new_user.id);

    if (old_id && old_id !== (new_user != null ? new_user.id : undefined)) {
      return this.renderUserChange();
    }
  },

  renderUserChange() {
    const user = this.getUser();
    const component = div(
      { className: "user-details--alert" },
      h2(null, "Logged in as"),
      UserDetails({ user, avatar: this.getUserAvatar() }),
    );

    if (!_.isEmpty(user)) {
      OverlayActions.overlay(component, { key: "user-details", top: 16, right: 16, zIndex: 10000 });

      return _.delay(() => OverlayActions.removeOverlay("user-details"), 3000);
    }
  },

  api: {
    hasSession() {
      return !!__guard__(this.getState("session"), x => x.token);
    },

    getLoading() {
      return this.getState("loading");
    },

    getUser() {
      return __guard__(this.getState("session"), x => x.user);
    },

    getUserContactId(org_id) {
      return Decorator.User.getContactId(
        __guard__(this.getState("session"), x => x.user),
        org_id,
      );
    },

    getUserAvatar() {
      const user = this.getUser();
      if (user) {
        return user.user_profile_picture_url;
      }
    },

    isGivingTreeUser(org_id) {
      const user = this.getUser();
      if (org_id && user) {
        if (Decorator.User.hasAffiliationTo(user, org_id)) {
          const roles = _.pluck(Decorator.User.getRoles(user, org_id), "name");
          return _.contains(roles, "GivingTree User") || _.contains(roles, "GivingTree Owner");
        }
      }
    },

    getIsImpersonating() {
      return !_.isEmpty(this.getState("impersonated_user"));
    },

    getImpersonatedUser() {
      return this.getState("impersonated_user");
    },
    getSession() {
      return this.state.session;
    },
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
