import React from "react";
import PropTypes from "prop-types";
import NotificationStore from "apps/notifications/stores/notification-store";
import { IconButton } from "@evertrue/et-components";
import { useFluxStore } from "@evertrue/et-flux";
import IsGated from "core/apps/gates/is-gated";

const mapStateToProps = () => {
  return {
    count: NotificationStore.getCount(),
  };
};

const NotificationIcon = React.forwardRef(({ onClick, className }, ref) => {
  const { count } = useFluxStore(NotificationStore, mapStateToProps);
  return (
    <IsGated gate="volunteer_gift_notifications">
      <div style={{ position: "relative" }}>
        <IconButton
          title="Notifications"
          className={className}
          style={{ display: "flex" }}
          icon="notifications"
          size={2}
          onClick={onClick}
          ref={ref}
        />
        {count ? <span className="header--notifications-badge">{count}</span> : null}
      </div>
    </IsGated>
  );
});

NotificationIcon.propTypes = { onClick: PropTypes.func };
export default NotificationIcon;
