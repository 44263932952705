import _ from "underscore";
import React, { Component } from "react";
import moment from "moment";
import DatePicker from "components/date-picker";
import PropTypes from "prop-types";

class DateRange extends Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: {
      gte: "",
      lte: "",
    },
  };

  handleChange = (prop, val) => {
    const date = val ? moment(val).format("MM/DD/YYYY") : null;
    let val_obj = _.clone(this.props.value) || {};
    if (date) {
      val_obj[prop] = date;
    } else {
      val_obj = _.omit(val_obj, prop);
    }
    this.props.onChange(val_obj);
  };

  render() {
    const {
      value: { gte, lte },
    } = this.props;

    return (
      <React.Fragment>
        <div className="date-range">
          <div className="date-input">
            <DatePicker value={gte} placeholder="From" onDayChange={val => this.handleChange("gte", val)} />
          </div>

          <div className="date-range--dash" />
          <div className="date-input">
            <DatePicker value={lte} placeholder="To" onDayChange={val => this.handleChange("lte", val)} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DateRange;
