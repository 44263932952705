import React, { Component } from "react";
import _ from "underscore";

class YearRange extends Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: {},
  };

  handleKeyPress(e) {
    if (/[a-z]/i.test(e.target.value)) {
      return e.preventDefault();
    }
    return null;
  }

  handleChange(e, prop, val) {
    if (val.length <= 4) {
      const year = _.toNumber(val);
      let val_obj = _.clone(this.props.value) || {};
      if (year) {
        val_obj[prop] = year;
      } else {
        val_obj = _.omit(val_obj, prop);
      }
      return this.props.onChange(val_obj);
    }
    return null;
  }

  render() {
    return (
      <div className="year-range">
        <input
          type="text"
          value={this.props.value.gte || ""}
          placeholder="1989"
          className="year-range--min"
          onKeyPress={this.handleKeyPress}
          onChange={e => this.handleChange(e, "gte", e.target.value)}
        />
        <div className="year-range--dash" />
        <input
          type="text"
          value={this.props.value.lte || ""}
          placeholder={new Date().getFullYear()}
          className="year-range--max"
          onKeyPress={this.handleKeyPress}
          onChange={e => this.handleChange(e, "lte", e.target.value)}
        />
      </div>
    );
  }
}

export default YearRange;
