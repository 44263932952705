/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from "react";

import classNames from "classnames";
import { createComponent } from "@evertrue/et-flux";
import { div, strong } from "react-dom-factories";
import OrgStore from "apps/auth/stores/org-store";
import { Avatar } from "components/et-factories";

export default createComponent("UserDetails", {
  propTypes: {
    user: PropTypes.object,
    avatar: PropTypes.string,
  },

  registerStores() {
    return this.on(OrgStore, () => ({ org: OrgStore.getCurrentOrg() }));
  },

  render() {
    const org = this.state.org || {};
    const user = this.props.user || {};

    return div(
      { className: classNames("user-details fs-exclude-data", this.props.className) },
      Avatar({ url: this.props.avatar, width: 50, name: user.name }),
      div(
        { className: "user-details--text" },
        strong(null, user.name),
        div(null, user != null ? user.email : undefined),
        org != null ? org.name : undefined,
      ),
    );
  },
});
