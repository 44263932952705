/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Config from "config/env";

import Decorator from "@evertrue/client-decorator";
const { FS } = window;

export default {
  setUser(user) {
    if (user == null) {
      user = {};
    }
    try {
      __guardMethod__(FS, "identify", o =>
        o.identify(user.id, {
          displayName: user.name,
          email: user.email,
          internalUser_bool: Decorator.User.isInternal(user) || user.super_user,
          environment_str: Config.dataEnv,
        }),
      );

      // Disable FullStory console tracking so that errors link to correct file
      if (Config.isDevelopment) {
        return __guardMethod__(FS, "disableConsole", o1 => o1.disableConsole());
      }
    } finally {
    }
  },
};

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== "undefined" && obj !== null && typeof obj[methodName] === "function") {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
