import PropTypes from "prop-types";
import React from "react";
import { createComponent } from "@evertrue/et-flux";
import Decorator from "@evertrue/client-decorator";
import classNames from "classnames";
import ProspectAvatar from "apps/prospects/components/prospect-avatar";

const PROPTYPES = {
  primary: PropTypes.object,
  secondary: PropTypes.object,
  showYear: PropTypes.bool,
  label: PropTypes.string,
};

const DisplayName = ({ contact = {} }) => {
  const name = Decorator.Contacts.getFullName(contact);
  const deceased_prop = contact.properties && contact.properties.deceased;
  const is_deceased = deceased_prop && deceased_prop.value;

  return <span className={classNames({ "is-deceased": is_deceased })}>{name}</span>;
};

const MiniProspectCard = ({ contact, secondary, showYear, label, children }) => {
  const year = Decorator.Contacts.getClassYear(contact);

  return (
    <div className="mini-prospect-card fs-exclude-data">
      <ProspectAvatar prospect={contact} secondary={secondary} />

      <div className="mini-prospect-card--info-section">
        <DisplayName contact={contact} />
        {secondary && " | "}
        {secondary && <DisplayName contact={secondary} />}

        {showYear && <div>{year}</div>}
        {label && <div>{label}</div>}
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

MiniProspectCard.propTypes = PROPTYPES;
export default MiniProspectCard;
