import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "@evertrue/et-flux";
import _ from "underscore";
import StageSource from "core/apps/prospects/stage-source";
import StageStore from "core/apps/prospects/stage-store";

const mapStateToProps = () => ({
  stageGroups: StageStore.getStageGroups(),
  loading: StageStore.getLoading(),
});

class StageResolver extends Component {
  static defaultProps = {
    stageGroups: {},
  };

  static propTypes = {
    stageGroups: PropTypes.object,
    stageGroupId: PropTypes.number,
    render: PropTypes.func,
    loading: PropTypes.bool,
    includeInactive: PropTypes.bool,
  };

  componentDidMount() {
    StageSource.fetchStageGroupsUnlessCached();
  }

  componentDidUpdate(prevProps) {
    if (this.props.stageGroupId && this.props.stageGroupId !== prevProps.stageGroupId) {
      StageSource.fetchGroupUnlessCached(this.props.stageGroupId);
    }
  }

  render() {
    // stages are associated to assignObj w/ a string, so unique the names
    const group = this.props.stageGroups[this.props.stageGroupId] || {};
    let stagesForGroup = _.uniq(group.stages, ({ stage }) => stage);
    if (!this.props.includeInactive) {
      stagesForGroup = stagesForGroup.filter(stage => stage.active);
    }

    const renderProp = typeof this.props.render === "function" ? this.props.render : this.props.children;

    return renderProp({
      stagesForGroup,
      loading: this.props.loading,
    });
  }
}

export default connect(
  StageResolver,
  [StageStore],
  mapStateToProps,
);
