import { createStore } from "@evertrue/et-flux";
import MagicLinkSource from "./magic-link-source";

const MagicLinkStore = createStore("MagicLinkStore", {
  getInitialState() {
    return {
      auth_email_status: false,
      sending: false,
      magic_token: null,
    };
  },

  registerActions() {
    this.on(MagicLinkSource.actions.setMagicToken, magic_token => {
      this.setState({ magic_token });
    });

    this.on(MagicLinkSource.actions.setTokenEmailStatus, status => {
      this.setState({ auth_email_status: status });
    });

    this.on(MagicLinkSource.actions.sending, sending => {
      this.setState({ sending });
    });
  },

  api: {
    getSending() {
      return this.getState("sending");
    },

    getAuthEmailSent() {
      return this.getState("auth_email_status") === "success";
    },

    getAuthEmailFailed() {
      return this.getState("auth_email_status") === "error";
    },

    getMagicToken() {
      return this.getState("magic_token");
    },
  },
});

export default MagicLinkStore;
