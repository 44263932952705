import PropTypes from "prop-types";
import React, { Component } from "react";
import { AdvancedCombobox, AdvancedComboboxContent, Loading } from "@evertrue/et-components";
import _ from "underscore";
import StageResolver from "core/apps/prospects/resolvers/stage-resolver";
import StageSource from "core/apps/prospects/stage-source";

class StageSelector extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    selectedStage: PropTypes.string,
    className: PropTypes.string,
    stageGroupId: PropTypes.number,
    assignmentId: PropTypes.number,
  };

  static defaultProps = {
    stageGroupId: undefined,
    assignmentId: undefined,
  };

  handleChange = ({ value }) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    } else if (this.props.selectedStage !== value) {
      StageSource.updateStage(this.props.assignmentId, value);
    }
  };

  render() {
    const { selectedStage, stageGroupId, isMobile } = this.props;
    if (!stageGroupId) {
      return null;
    }
    return (
      <StageResolver
        stageGroupId={stageGroupId}
        render={({ stagesForGroup, loading }) => {
          if (loading) return isMobile ? <Loading /> : null;

          let options = stagesForGroup.map(opt => ({ label: opt.stage, value: opt.stage }));
          let value = _.findWhere(options, { value: selectedStage });
          // JIC a stage gets deleted, still show it in selector list
          if (!value && selectedStage) {
            value = { value: selectedStage, label: selectedStage };
            options = [value, ...options];
          }
          return !isMobile ? (
            <AdvancedCombobox
              disabled={this.props.disabled}
              className={this.props.className}
              options={options}
              value={value}
              onChange={this.handleChange}
            />
          ) : (
            <div className="filter-dropdown">
              <AdvancedComboboxContent value={value} options={options} mobile onChange={this.handleChange} />
            </div>
          );
        }}
      />
    );
  }
}

export default StageSelector;
