import { createStore } from "@evertrue/et-flux";
import NotificationSource from "apps/notifications/sources/notification-source";
import SocketSource from "core/base/socket-source";
import get from "lodash.get";

const NotificationStore = createStore("NotificationStore", {
  getInitialState() {
    return {
      loading: false,
      error: undefined,
      notifications: {},
    };
  },

  firstListenerDidMount() {
    SocketSource.bindUser("refresh_inbox");
  },

  lastListenerWillUnmount() {
    SocketSource.unbindUser("refresh_inbox");
  },

  registerActions() {
    this.on(NotificationSource.actions.loading, this.respondToLoading);
    this.on(NotificationSource.actions.error, this.respondToError);
    this.on(NotificationSource.actions.fetchedNotifications, this.respondToFetch);
    this.on(SocketSource.actions.refresh_inbox, () => {
      NotificationSource.fetch();
    });
  },

  respondToLoading(loading) {
    this.setState({ loading });
  },

  respondToFetch(notifications) {
    this.setState({ notifications });
  },

  respondToError(error) {
    this.setState({ error });
  },

  api: {
    getNotifications() {
      return this.state.notifications.notifications;
    },

    getCount() {
      return get(this.state.notifications, "meta.badge_count");
    },

    getLoading() {
      return this.state.loading;
    },

    getError() {
      return this.state.error;
    },
  },
});

export default NotificationStore;
