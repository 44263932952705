import React from "react";
import { GateContext } from "core/apps/gates/gate-context";

const useGate = ({ gate }) => {
  const { gates } = React.useContext(GateContext);
  const current_gate = gates[gate] || {};
  const { enabled = false } = current_gate;
  return enabled;
};

export default useGate;
