import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, PlainButton } from "@evertrue/et-components";

class CopyButton extends Component {
  static defaultProps = {
    timeout: 3000,
    onClick: () => {},
  };

  static propTypes = {
    onClick: PropTypes.func,
    timeout: PropTypes.number,
    textToCopy: PropTypes.string,
  };

  state = {
    copied: false,
  };

  textarea = React.createRef();

  copyToClipboard = e => {
    this.textarea.current.select();
    document.execCommand("copy");
    e.target.focus();
    this.props.onClick();
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, this.props.timeout);
  };

  render() {
    const { textToCopy, children, timeout, ...rest } = this.props;

    return (
      <React.Fragment>
        <PlainButton {...rest} onClick={this.copyToClipboard}>
          {this.state.copied ? <Icon icon="check" /> : children}
        </PlainButton>

        {/* HACK: you cant copy stuff that's hidden so put it way off the DOM */}
        <textarea
          readOnly
          style={{ position: "absolute", left: "-1000px", top: "-1000px" }}
          ref={this.textarea}
          value={textToCopy}
        />
      </React.Fragment>
    );
  }
}

export default CopyButton;
