import PropTypes from "prop-types";
import _ from "underscore";
import React from "react";
import { createComponent } from "@evertrue/et-flux";
import { div } from "react-dom-factories";
import { Redirect } from "components/router";
import PoolStore from "apps/pools/pool-store";
import { ProspectsStore, MyProspectsStore } from "core/apps/prospects/prospects-store";

export default createComponent("PoolRedirects", {
  propTypes: {
    pool_id: PropTypes.number,
    tab: PropTypes.string,
    prospect_id: PropTypes.number,
    profile_section: PropTypes.string,
  },

  registerStores() {
    this.on(PoolStore, () => ({ pools: PoolStore.getPools() }));

    this.on(MyProspectsStore, "pool_id", ({ pool_id }) => ({
      assignments: __guard__(MyProspectsStore.getForPool(pool_id), x => x.items),
      loading: MyProspectsStore.getLoading(),
    }));

    this.on(ProspectsStore, "pool_id", ({ pool_id }) => ({
      prospects: __guard__(ProspectsStore.getForPool(pool_id), x => x.items),
    }));
  },

  renderPoolRedirect(pool_id) {
    const first_pool = _.first(this.state.pools);
    if (first_pool && !pool_id) {
      pool_id = first_pool.id;
    }

    if (pool_id) {
      return Redirect({ to: `/pools/${pool_id}/assignments` });
    } else {
      return null;
    }
  },

  renderProspectRedirect(pool_id, tab) {
    const list_data = (() => {
      switch (tab) {
        case "prospects":
          return this.state.prospects;
        default:
          return this.state.assignments;
      }
    })();

    const first_item = _.first(list_data);
    if (!this.state.loading && first_item) {
      return Redirect({ to: `/pools/${pool_id}/${tab}/${first_item != null ? first_item.id : undefined}` });
    } else {
      return null;
    }
  },

  render() {
    const { pool_id, tab, prospect_id } = this.props;
    return div(
      null,
      !pool_id
        ? this.renderPoolRedirect()
        : !tab
        ? this.renderPoolRedirect(pool_id)
        : !prospect_id
        ? this.renderProspectRedirect(pool_id, tab)
        : null,
    );
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
