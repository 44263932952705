/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "base/api";
import Storage from "utils/storage";
import Config from "config/env";
import Navigator from "utils/navigator";
import Tracker from "utils/tracker";
import ErrorLogger from "utils/error-logger";

export default createSource("OrgSource", {
  actions: {
    loading: true,
    fetchedOrgs(orgs) {
      return this.require(_.isArray(orgs), "orgs should be an array");
    },
    fetchedOrgById(org) {
      return this.require(_.isObject(org), "og should be an object");
    },
    setCurrentOrg(oid) {
      return this.require(_.isNumber(oid) || _.isEmpty(oid), "oid should be a number or undefined");
    },
    setCurrentOrgObject: true,
  },

  api: {
    fetchCurrentOrgFromCache() {
      const oid = Storage.get("oid");
      if (oid) {
        this.setCurrentOrg(oid);
      }
    },

    setCurrentOrg(id) {
      if (id) {
        Storage.set("oid", id);
      } else {
        Storage.remove("oid");
      }
      Api.set({ oid: id });
      this.actions.setCurrentOrg(parseInt(id, 10));
    },

    setCurrentOrgObject(orgObj) {
      const { id } = orgObj;
      if (id) {
        Storage.set("oid", id);
      } else {
        Storage.remove("oid");
      }
      Api.set({ oid: id });
      this.actions.setCurrentOrgObject(orgObj);
    },

    clear() {
      this.setCurrentOrg();
    },

    fetch() {
      this.actions.loading(true);
      Api.AUTH.ORGS.get({
        success: resp => {
          this.actions.fetchedOrgs(resp);
          this.actions.loading(false);
        },
        error: error => {
          this.actions.loading(false);
          ErrorLogger.captureRequest("error fetching orgs", error);
        },
      });
    },

    fetchById(id) {
      Api.AUTH.ORGS.get({
        urlExtend: `/${id}`,
        success: resp => {
          this.actions.fetchedOrgById(resp);
        },
        error: error => {
          ErrorLogger.captureRequest("error fetching org by id", error);
        },
      });
    },

    getSlugAndStartAuth(id, token) {
      Api.AUTH.ORGS.get({
        urlExtend: `/${id}`,
        success: resp => {
          Tracker.send("auth_started", { type: "new_sso_session" });
          // redirect to auth web to complete login
          let new_path = Navigator.getAccountsUrl();
          new_path += `/volunteers/login/${resp.slug}`;
          if (Config.isDevelopment) {
            new_path += `?redirect=${window.location.origin}`;
          }
          return (window.location = new_path);
        },
        error: error => {
          ErrorLogger.captureRequest("error getting slug and starting auth", error);
        },
      });
    },
  },
});
