/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const S3_ROUTE = "https://s3.amazonaws.com/assets.evertrue.com/global/volunteers";

export default {
  S3_ROUTE,
  isMobile() {
    const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(window.navigator.userAgent);
  },

  isiOS() {
    const regex = /iPhone|iPad|iPod/i;
    return regex.test(window.navigator.userAgent);
  },

  isAndroid() {
    const regex = /Android/i;
    return regex.test(window.navigator.userAgent);
  },

  getAppStoreLink() {
    if (this.isAndroid()) {
      return "https://play.google.com/apps/testing/com.evertrue.etvolunteers";
    } else {
      return "https://itunes.apple.com/us/app/volunteer-by-evertrue/id1173581930?ls=1&mt=8";
    }
  },

  getAppStoreIcon() {
    if (this.isAndroid()) {
      return `${S3_ROUTE}/google-play-store-button.png`;
    } else {
      return `${S3_ROUTE}/apple-store-button.png`;
    }
  },
};
