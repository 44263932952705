import React from "react";
import PropTypes from "prop-types";
import MiniProspectCard from "apps/profile/components/mini-prospect-card";
import RespondTo from "components/respond-to";
import StageSelector from "apps/profile/components/stage-selector";

class MiniProspectCardWithStageSelector extends React.Component {
  static propTypes = {
    tab: PropTypes.string,
    pool: PropTypes.object.isRequired,
    stage: PropTypes.string,
    handleStageChange: PropTypes.func.isRequired,
    contact: PropTypes.object.isRequired,
  };

  render() {
    return (
      <MiniProspectCard
        contact={this.props.contact}
        show_year={false}
        children={
          !this.props.hideStageSelector ? (
            <RespondTo to={["tablet", "desktop"]} className="interaction-form--stage-section">
              <label className="interaction-form--stage-label">Stage:</label>
              <StageSelector
                selectedStage={this.props.stage}
                stageGroupId={this.props.pool.stage_group_id}
                onChange={this.props.handleStageChange}
              />
            </RespondTo>
          ) : undefined
        }
      />
    );
  }
}
export default MiniProspectCardWithStageSelector;
