import React from "react";
import classNames from "classnames";
import { createComponent } from "@evertrue/et-flux";
import { Route } from "react-router-dom";
import get from "lodash.get";
import Config from "config/env";
import UserHeaderDropdown from "apps/auth/components/user-header-dropdown";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import ResponsiveSource from "apps/layout/sources/responsive-source";
import PoolStore from "apps/pools/pool-store";
import Logo from "apps/layout/components/logo";
import MobileHeader from "apps/layout/components/mobile-header";
import SessionStore from "apps/auth/stores/session-store";
import NotificationSource from "apps/notifications/sources/notification-source";
import RespondTo from "components/respond-to";
import NotificationInboxController from "apps/notifications/controllers/notification-inbox-controller";
import NotificationIcon from "apps/notifications/components/notification-icon";
import PoolNameDropdown from "apps/pools/components/pool-name-dropdown";
import { Icon, Popover2 } from "@evertrue/et-components";

export default createComponent("HeaderController", {
  registerStores() {
    this.on(PoolStore, () => ({ pools: PoolStore.getPoolsIndex() }));

    this.on(ResponsiveStore, () => ({
      is_mobile: ResponsiveStore.isMobile(),
      is_tablet: ResponsiveStore.isTablet(),
      is_collapsed: ResponsiveStore.isPoolCollapsed(),
    }));

    this.on(SessionStore, () => ({
      user: SessionStore.getUser(),
      has_session: SessionStore.hasSession(),
    }));
  },

  componentDidMount() {
    if (this.state.user != null ? this.state.user.name : undefined) {
      NotificationSource.fetch();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (
      !(prevState.user != null ? prevState.user.name : undefined) &&
      (this.state.user != null ? this.state.user.name : undefined)
    ) {
      NotificationSource.fetch();
    }
  },

  handleMagicClick(e) {
    e.preventDefault();
  },

  handleToggleNav() {
    if (this.state.is_collapsed) {
      ResponsiveSource.poolExpand();
    } else {
      ResponsiveSource.poolCollapse();
    }
  },

  renderLogo() {
    const is_collapsed = this.state.is_collapsed && !this.state.is_mobile;

    return (
      <div
        className={classNames("header--logo-wrapper", {
          "is-mobile": this.state.is_mobile,
          "is-collapsed": is_collapsed,
        })}
      >
        <Logo hideText={is_collapsed} hideIcon={this.state.is_mobile} />
        {!this.state.is_mobile ? (
          <Icon
            className="header--nav-toggle"
            icon={this.state.is_collapsed ? "more-vert" : "collapse"}
            onClick={this.handleToggleNav}
          />
        ) : undefined}
      </div>
    );
  },

  renderUserAccount() {
    if (this.state.user != null ? this.state.user.name : undefined) {
      return (
        <RespondTo className="header--options">
          {!this.state.is_mobile && (
            <Popover2
              wrapperClassName="header--notifications"
              placement="bottom-end"
              contentWidth={350}
              trigger={({ togglePopover }) => <NotificationIcon onClick={togglePopover} />}
            >
              {({ closePopover }) => <NotificationInboxController onClose={closePopover} />}
            </Popover2>
          )}
          <UserHeaderDropdown user={this.state.user} isMobile={this.state.is_mobile} />
        </RespondTo>
      );
    } else {
      return null;
    }
  },

  render() {
    const is_dev = Config.isDevelopment && !Config.isStageUrl;
    const { is_mobile } = this.state;
    return (
      <div className="header">
        {
          // Logo
          <div className="header--container page-wrapper">
            {
              // Mobile / Tablet Header Titles & Actions
              this.state.has_session ? (
                is_mobile ? (
                  // Match inner pool route, which should NOT show the logo, but instead the title/action
                  <Route
                    render={({ location }) => {
                      if (
                        !__guard__(location != null ? location.pathname : undefined, (x) =>
                          x.match(/^\/?pools\/?\d+.*$/),
                        )
                      ) {
                        return this.renderLogo();
                      } else {
                        return null;
                      }
                    }}
                  />
                ) : (
                  <Route render={this.renderLogo} />
                )
              ) : undefined
            }
            {is_mobile ? (
              <Route
                path="/pools/:pool_id/:tab?/:prospect_id?"
                render={({ match: { params } }) => {
                  const { pool_id } = params;
                  const pool = get(this.state, `pools[${pool_id}]`, {});
                  if (params.prospect_id) {
                    return (
                      <MobileHeader iconLink={`/pools/${params.pool_id}/${params.tab}`}>
                        {params.tab === "prospects" ? "Prospect" : "Assignment"}
                      </MobileHeader>
                    );
                  } else {
                    return (
                      <MobileHeader iconLink="/pools">
                        <div className="mobile-header--title">
                          <PoolNameDropdown isMobile={true} pool={pool} />
                        </div>
                      </MobileHeader>
                    );
                  }
                }}
              />
            ) : (
              <Route
                path="/pools/:pool_id/:tab"
                render={({ match: { params } }) => {
                  const { pool_id } = params;
                  const pool = get(this.state, `pools[${pool_id}]`);
                  return <PoolNameDropdown pool={pool} />;
                }}
              />
            )}
            {
              // User Dropdown
              is_dev && !is_mobile ? (
                <span className="environment-tag" onClick={Config.switchEnvironment}>
                  {Config.dataEnv}
                </span>
              ) : undefined
            }
            <Route path="/pools" render={this.renderUserAccount} />
            <Route path="/auth/*" render={this.renderUserAccount} />
          </div>
        }
      </div>
    );
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
