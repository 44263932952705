import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import Config from "config/env";
import Navigator from "utils/navigator";
import MobileUtils from "utils/mobile-utils";
import AppController from "./app-controller";
import SetGlobalParams from "apps/auth/components/set-global-params";
import InviteConnectController from "apps/auth/controllers/invite-connect-controller";
import MobileRedirectController from "apps/layout/controllers/mobile-redirect-controller";
import OrgSelectController from "apps/auth/controllers/org-select-controller";
import AuthTokenSsoController from "apps/auth/controllers/auth-token-sso-controller";
import LayoutController from "apps/layout/controllers/layout-controller";
import PoolRouteController from "apps/pools/pool-route-controller";
import { Loading } from "@evertrue/et-components";
import Utils from "core/utils/utils";
import PageErrorBoundary from "apps/layout/page-error-boundary";

export default class AppRouteController extends React.Component {
  handleRedirectToMobilePicker = ({ referrer: _, ...query_params } = {}) => {
    const { pathname } = window.location;
    const redirect_to = `${pathname}?${Utils.param(query_params)}`;
    const mobile_path = `/mobile?redirect_route=${encodeURIComponent(redirect_to)}`;
    return <Redirect to={mobile_path} />;
  };

  render() {
    return (
      <PageErrorBoundary>
        <Route
          path="*"
          render={() => {
            const query_params = Navigator.getQueryParams();
            if (MobileUtils.isMobile() && query_params.referrer) {
              return this.handleRedirectToMobilePicker(query_params);
            }

            return (
              <div>
                {/*  if ?token= comes in to the app route right from the get-go,
                SetGlobalParams is gonna redirect it to a magic link login flow in accounts web */}
                <SetGlobalParams params={query_params} />

                {query_params.token ? (
                  <Loading />
                ) : (
                  <LayoutController>
                    <Switch>
                      <Route
                        path="/mobile"
                        render={() => {
                          return <MobileRedirectController redirectTo={query_params.redirect_route} />;
                        }}
                      />

                      {/*  Should be able to replace inviteConnectController with accounts.com/volunteers/account/register:invite_token */}
                      <Route
                        path="/invite/:invite_token"
                        render={({ match: { params } }) => {
                          const { email } = query_params;
                          return <InviteConnectController email={email} invite_token={params.invite_token} />;
                        }}
                      />

                      {/* This route will redirect to auth web where the user can set their password and register at the same time */}
                      <Route
                        path="/register_email_password/:invite_token"
                        render={({ match: { params } }) => {
                          const { invite_token } = params;
                          const { email } = query_params;
                          const encoded_email = encodeURIComponent(email);
                          const url = `${Config.accountsUrl}/volunteers/account/register_email_password?invite_token=${invite_token}&email=${encoded_email}`;
                          window.location = url;
                          return null;
                        }}
                      />

                      <Route
                        path="/login"
                        render={() => {
                          return (
                            <AppController
                              render={has_session => {
                                if (has_session) {
                                  return <Redirect to="/pools" />;
                                } else {
                                  const login_url = `${Config.accountsUrl}/volunteers/start`;
                                  window.location = login_url;
                                }
                              }}
                            />
                          );
                        }}
                      />

                      <Route
                        path="/auth/org"
                        render={() => {
                          return (
                            <AppController
                              render={has_session => {
                                return has_session ? <OrgSelectController /> : null;
                              }}
                            />
                          );
                        }}
                      />

                      <Route
                        path="/auth/:magic_token?"
                        render={({ match: { params = {} } }) => {
                          const { oid } = query_params;
                          return <AuthTokenSsoController oid={oid} magic_token={params.magic_token} />;
                        }}
                      />

                      <Route
                        path="/pools"
                        render={() => {
                          return (
                            <AppController
                              render={(has_session, has_org, current_org_id) => {
                                const check = has_session && has_org;
                                return check ? (
                                  <PoolRouteController currentOrgId={current_org_id} />
                                ) : (
                                  <Redirect to={"/auth/org"} />
                                );
                              }}
                            />
                          );
                        }}
                      />

                      <Route
                        render={() => {
                          return (
                            <AppController
                              render={(has_session, has_org) => {
                                if (has_session && has_org) {
                                  return <Redirect to="/pools" />;
                                } else if (has_session) {
                                  return <Redirect to="/auth/org" />;
                                }
                                return null;
                              }}
                            />
                          );
                        }}
                      />
                    </Switch>
                  </LayoutController>
                )}
              </div>
            );
          }}
        />
      </PageErrorBoundary>
    );
  }
}
