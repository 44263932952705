const endpoint_config = {
  AUTH: {
    ORGS: "auth/organizations",
    SESSION: "auth/session",
    SKIFF: "skiff",
    REGISTER: "skiff/register",
    USERS_SEARCH: "auth/users/search",
    USER: "auth/users",
  },
  DNA: {
    SETTING: "dna/setting_values/:setting_key",
    GATES: "dna/gates",
  },
  VOLUNTEERS: {
    AUTH: "assignments-java/v2/auth",
    SOLICITOR: "assignments-java/v2/solicitors/profiles",
    ASSIGNMENTS: "assignments-java/v2/assignments",
    SELF_ASSIGN: "assignments-java/v2/assignments/:id/self_assign",
    INTERACTIONS: "assignments-java/v2/interactions/prospects/:id",
    INTERACTIONS_SEARCH: "assignments-java/v2/interactions/prospects/:id/search",
    STAGES: "assignments-java/v2/stages",
    UPDATE_STAGE: "assignments-java/v2/assignments/:assignment_id/stage",
    COMMITTED_AMOUNT: "assignments-java/v2/committed",
    POOLS: "assignments-java/v2/pools",
    ANALYTICS: "search/v2/assignment_analytics/search",
    WARM_ANALYTICS: "assignments-java/v2/analytics",
    SOLICITORS_BULK: "assignments-java/v2/solicitors/bulk",
    SEARCH: "assignments-java/v2/search/contacts",
  },
  CONTACTS: {
    SEARCH: "contacts/v2/search",
    SINGLE: "contacts/v1/contacts/:id",
    SCHEMA: "contacts/v1/properties",
    FACETS: "contacts/v2/search/facet",
  },
  LIDS: {
    ACCESS_TOKEN: "lids/callback",
    ASSOCIATE: "lids/auth/association",
    AUTH_URL: "lids/auth",
    SOCIAL_PROFILE: "lids/contacts",
  },
  SEARCH: {
    ASSIGNMENTS: "search/v2/assignment/search",
    CONTACT_NOTE: "search/v2/contact_note/search",
    SESSIONS: "search/v2/sessions/search",
  },
  EMS: {
    NOTIFICATIONS: "ems/notifications",
    PREFS: "ems/preferences/:key",
  },
  SUGGESTIONS: {
    SUBMISSIONS: "suggestions/v1/submissions",
  },
};

export default endpoint_config;
