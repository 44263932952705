import React, { Component } from "react";
import PropTypes from "prop-types";
import RespondTo from "components/respond-to";
import DayPickerInput from "react-day-picker/DayPickerInput";
import classNames from "classnames";
import { formatDate, parseDate } from "react-day-picker/moment";
import YearMonthForm from "components/year-month-form";

class MyInput extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  input = React.createRef();

  focus = () => {
    this.input.current.focus();
  };
  onClick = () => {
    this.input.current.scrollIntoView();
  };
  render() {
    const { onClick, disabled, ...rest } = this.props;

    return (
      <input
        className={classNames("date-picker--input", { "is-disabled": disabled })}
        ref={this.input}
        disabled={disabled}
        onClick={this.onClick}
        type="text"
        {...rest}
      />
    );
  }
}

class DatePicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    dayPickerProps: PropTypes.object,
    disabled: PropTypes.bool,
    value: PropTypes.string,
  };

  state = {
    month: new Date(),
  };

  handleFromYearMonthChange = month => {
    this.setState({ month });
  };

  render() {
    const { className, dayPickerProps, disabled, value, ...rest } = this.props;

    return (
      <RespondTo className="date-picker">
        <DayPickerInput
          // hideOnDayClick={false}
          keepFocus={false}
          disabled
          clickUnselectsDay
          component={MyInput}
          inputProps={{
            disabled,
          }}
          formatDate={formatDate}
          parseDate={parseDate}
          className={classNames("date-picker--picker", className)}
          value={value}
          dayPickerProps={{
            month: this.state.month,

            captionElement: ({ date, localeUtils }) => (
              <YearMonthForm date={date} localeUtils={localeUtils} onChange={this.handleFromYearMonthChange} />
            ),
            ...dayPickerProps,
          }}
          {...rest}
        />
      </RespondTo>
    );
  }
}

export default DatePicker;
