import _ from "underscore";
import Config from "config/env";
import Requester from "@evertrue/request-manager";
import ApiSource from "./api-source";
import ErrorLogger from "utils/error-logger";
import { AlertActions } from "core/utils/alert-actions";
import endpoint_config from "./endpoint-config";
import Storage from "storage";
import { SESSION_KEY } from "core/apps/auth/constants";
import refreshSession from "./refresh";

let _refresh_count = 0;

const getErrorFromResponse = (response) => {
  if (response.error && typeof response.error === "string") {
    return response.error;
  } else if (typeof response.text === "function") {
    tryParse(response).then((parsed_response) => {
      return parsed_response;
    });
  }

  return "An error occurred";
};

const tryParse = (res) => {
  return res.text().then((response) => {
    try {
      return JSON.parse(response);
    } catch (e) {
      return response;
    }
  });
};

// array of all session refresh attempts
let refresh_promises = [
  {
    promise: Promise.resolve(Storage.get(SESSION_KEY)),
    fetched_at: null,
  },
];

refresh_promises.invalidate = () => {
  const now = Date.now();
  refresh_promises.push({
    promise: Promise.resolve(`${now}`),
    fetched_at: now,
  });
};

// nonsense for current debugging
// android got no window
if (window) {
  window.refresh_promises = refresh_promises;
}

// will resolve to a new token
// and appends to the promises array
// todo: initiate logout on skiff 401
// todo: handle MFA + request retry on 403

const getNewToken = (expired_token) => {
  const refresh_promise = _.last(refresh_promises).promise;

  return refresh_promise.then((current_token) => {
    if (current_token && current_token !== expired_token) {
      // somebody refreshed for us, we're good to go
      return refresh_promise;
    } else {
      // we're the first to find out that current token is invalid
      // we need to initiate the refresh and append to the refresh_promises
      // array so additional 401s can hook to that for their retries.

      _refresh_count += 1;
      const oid = Storage.get("oid");
      const new_refresh_promise = refreshSession(oid)
        .then((response) => {
          if (response.ok) {
            return tryParse(response).then((session) => {
              ApiSource.sessionRefreshed(session);
              return session.token;
            });
          } else if (response.status === 403) {
            return tryParse(response).then((resp = {}) => {
              if (resp.error_code === "otp_required") {
                ApiSource.mfaRequired();
              } else if (["mfa_locked", "mfa_required"].includes(resp.error_code)) {
                AlertActions.error(response.message.error_code);
              }
            });
          } else {
            ErrorLogger.captureRequest("Failed to refresh session", response);
            ApiSource.forbidden();
          }
        })
        .catch((response) => {
          ErrorLogger.captureRequest("Failed to refresh session", response);
          ApiSource.forbidden();
        });

      refresh_promises.push({
        promise: new_refresh_promise,
        fetched_at: Date.now(),
      });

      return new_refresh_promise;
    }
  });
};

// run a request, with 3 possible outcomes:
// (1) success, therefore resolve
// (2) rando error, reject
// (3) 401, don't resolve or reject
//     - renew skiff session
//     - then retry original call with new token,
//.    - resolve or reject original promise accordingly

const runRequest = (url, fetch_options = {}) => {
  const promise = new Promise((resolve, reject) => {
    fetch(url, fetch_options)
      .then((response) => {
        if (response.ok) {
          return tryParse(response).then(resolve).catch(reject);
        } else if (response.status === 401 && !fetch_options.bypassDefaultResponse) {
          if (_refresh_count < 100) {
            return getNewToken(fetch_options.headers.Authorization).then((token) => {
              const new_options = {
                ...fetch_options,
                headers: { ...fetch_options.headers, Authorization: token },
              };
              if (token) {
                return fetch(url, new_options).then(tryParse).then(resolve).catch(reject);
              } else {
                return reject({ error: "Session did not refresh" });
              }
            });
          } else {
            ErrorLogger.captureRequest("Refresh count over 100");
            ApiSource.forbidden();
          }
        } else {
          return reject(response);
        }
      })
      .catch(() => {
        return reject({ error: "A network or some other type error occurred" });
      });
  })
    // support old success / error semantics on completion of main promise
    .then((response) => {
      if (fetch_options.success) {
        fetch_options.success(response);
      }
      return response;
    })
    .catch((response) => {
      const error = getErrorFromResponse(response);

      if (fetch_options.error) {
        fetch_options.error(error);
      }

      return Promise.reject(error);
    });

  return promise;
};

const Api = Requester.Manager(endpoint_config, {
  debug: Config.isDevelopment,
  baseRoute: Config.apiBase,
  appKey: Config.appKey,

  onRequest(options) {
    const opts = { ...options };

    const refresh_promise = _.last(refresh_promises).promise;

    // always get the token out of the refresh promise
    // this deals with the case of two concurrent 401s.
    // the first enacts a promise, the second waits for the first

    return refresh_promise.then((token) => {
      const headers = { ...opts.headers };

      const provider = headers["Authorization-Provider"];

      if ((provider && provider.toLowerCase() === "evertrueauthtoken") || !provider) {
        headers.Authorization = token;
        headers["Authorization-Provider"] = "EverTrueAuthToken";
      }

      const fetch_options = {
        ...opts._original_options,
        url: opts.url,
        headers,
        method: opts.type,
      };

      if (opts.data) {
        fetch_options.body = JSON.stringify(opts.data);
      }

      return runRequest(fetch_options.url, fetch_options);
    });
  },
});

Api.pushTokenToRefreshPromise = (token) => {
  if (token) {
    refresh_promises.push({
      promise: Promise.resolve(token),
      fetched_at: null,
    });
  }
};

export default Api;
