import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import { ProspectsSource, MyProspectsSource } from "./prospects-source";
import ProspectAssignmentsSource from "./prospect-assignments-source";
import ContactsSource from "core/apps/contacts/contacts-source";
import SocketSource from "base/socket-source";
import Utils from "core/utils/utils";
import FilterUtils from "core/apps/filters/filter-utils";

const { getQuery } = FilterUtils;

const LIMIT = 10;
const ES_DELAY = 1000;

const ProspectsStoreConfig = (Source, assigned_to_me) => {
  const _debouncedSearchInfinite = _.debounce(Source.fetchInfiniteWithQuery, 300);
  const _assigned_to_me = assigned_to_me;

  return {
    getInitialState() {
      return {
        loading: false,
        loading_infinite: false,
        prospects_by_pool: {},
        filters: _assigned_to_me ? { assigned_to_me: true } : {},
        totals_by_pool_id: {},
      };
    },

    firstListenerDidMount() {
      SocketSource.bindOrg("prospect_change");
      SocketSource.bindOrg("assignment_change");
      SocketSource.bindOrg("joint_assignment_change");
    },

    registerActions() {
      this.on(Source.actions.loading, loading => {
        return this.setState({ loading });
      });
      this.on(Source.actions.loadingInfinite, loading => {
        return this.setState({ loading_infinite: loading });
      });
      this.on(Source.actions.startFetch, this.fetchWithQuery);
      this.on(Source.actions.fetchedProspects, this.respondToFetchedContacts);
      this.on(Source.actions.fetchedInfiniteProspects, this.respondToFetchedInfiniteContacts);

      this.on(Source.actions.filter, this.respondToFilter);
      this.on(Source.actions.paginate, this.respondToPagination);
      this.on(Source.actions.infiniteLoad, this.respondToInfiniteLoad);

      this.on(SocketSource.actions.prospect_change, this.respondToWebSocket);
      this.on(SocketSource.actions.assignment_change, this.respondToWebSocket);
      this.on(SocketSource.actions.joint_assignment_change, this.respondToWebSocket);

      this.on(ProspectAssignmentsSource.actions.change, pool_id => {
        if (_assigned_to_me && this.hasListeners()) {
          this.fetchWithQuery(pool_id);
        }
      });
    },
    respondToWebSocket({ pool_id }) {
      if (this.hasListeners()) {
        this.setState({ loading: true });
        return Utils.wait(ES_DELAY, () => {
          // TODO: investigate and possibly remove
          // this seems to fetch contacts so is probably not really doing anything since it's the assignments we want updates for
          this.fetchWithQuery(pool_id);
        });
      }
    },

    respondToFetchedInfiniteContacts(pool_id, contacts) {
      const prospects_by_pool = Utils.cloneData(this.state.prospects_by_pool);
      const current_items = prospects_by_pool[pool_id] ? prospects_by_pool[pool_id].items : [];
      const contact_items = contacts ? contacts.items : [];
      const updated_items = _.uniq([...current_items, ...contact_items], item => item.id);
      prospects_by_pool[pool_id] = { ...contacts, items: updated_items };
      this.setState({ prospects_by_pool });

      const ids = _.pluck(contacts.items, "id");
      if (!_.isEmpty(ids)) {
        ProspectAssignmentsSource.fetchFromContacts(pool_id, ids);
      }
      ContactsSource.cache(contacts.items);

      this.setPoolTotal(pool_id, contacts);
    },

    respondToFetchedContacts(pool_id, contacts) {
      const prospects_by_pool = Utils.cloneData(this.state.prospects_by_pool);
      prospects_by_pool[pool_id] = contacts;
      this.setState({ prospects_by_pool });

      const ids = _.pluck(contacts.items, "id");
      if (!_.isEmpty(ids)) {
        ProspectAssignmentsSource.fetchFromContacts(pool_id, ids);
      }
      ContactsSource.cache(contacts.items);
      this.setPoolTotal(pool_id, contacts);
    },

    respondToPagination(pool_id, page, givingCategoryLabel) {
      this.fetchWithQuery(pool_id, page, givingCategoryLabel);
    },

    respondToInfiniteLoad(pool_id, givingCategoryLabel) {
      const loaded_data = this.state.prospects_by_pool[pool_id];
      const offset = loaded_data.offset;
      const params = { offset: offset + LIMIT, limit: LIMIT };
      const query = getQuery(pool_id, this.state.filters, givingCategoryLabel);
      _debouncedSearchInfinite(pool_id, query, params);
    },

    respondToFilter(pool_id, filters = {}, givingCategoryLabel) {
      let updated_filters = filters;
      if (_assigned_to_me) {
        updated_filters = { ...filters, assigned_to_me: true };
      }
      this.setState({ filters: updated_filters });
      this.fetchWithQuery(pool_id, 1, givingCategoryLabel);
    },

    fetchWithQuery(pool_id, page, givingCategoryLabel) {
      let offset;
      if (page) {
        offset = (page - 1) * LIMIT;
      } else {
        ({ offset } = this.state.prospects_by_pool[pool_id] || { offset: 0 });
      }

      const params = { offset, limit: LIMIT };
      const query = getQuery(pool_id, this.state.filters, givingCategoryLabel);
      Source.fetchWithQuery(pool_id, query, params);
    },

    setPoolTotal(poolId, resp) {
      const has_total_set = typeof this.state.totals_by_pool_id[poolId] === "number";
      if (!has_total_set) {
        this.setState({
          totals_by_pool_id: {
            ...this.state.totals_by_pool_id,
            [poolId]: resp.total,
          },
        });
      }
    },

    api: {
      getForPool(pool_id) {
        if (pool_id) {
          return this.state.prospects_by_pool[pool_id];
        }
        return {};
      },

      getLoading() {
        return !!this.state.loading;
      },

      getLoadingInfinite() {
        return !!this.state.loading_infinite;
      },

      getFilters() {
        return this.state.filters;
      },

      getSample(pool_id) {
        return this.state.sample_by_pool[pool_id];
      },

      getPage(pool_id) {
        const { offset, limit, total } = this.state.prospects_by_pool[pool_id];
        const page = offset / limit + 1;
        return Math.min(Math.max(1, page), Math.ceil(total / limit));
      },

      getPoolTotal(poolId) {
        return this.state.totals_by_pool_id[poolId];
      },
    },
  };
};

const ProspectsStore = createStore("ProspectsStore", ProspectsStoreConfig(ProspectsSource));
const MyProspectsStore = createStore("MyProspectsStore", ProspectsStoreConfig(MyProspectsSource, true));
export { ProspectsStore, MyProspectsStore };
