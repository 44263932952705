import _ from "underscore";
import Api from "base/api";
import { createSource } from "@evertrue/et-flux";
import Config from "config/env";
import Decorator from "@evertrue/client-decorator";
import ErrorLogger from "utils/error-logger";

const _filterVolunteerFields = schema => {
  const customSchema = Decorator.Schema.getCustomFields(schema);
  return _.filter(customSchema, property => Array.from(property.app_keys).includes(Config.appKey) && !property.deleted);
};

const PropertySource = createSource("PropertySource", {
  actions: {
    fetchedCustomFields(fields) {
      this.require(_.isArray(fields), "fields should be an array");
    },
  },

  api: {
    fetchCustomFields() {
      Api.CONTACTS.SCHEMA.get({
        success: resp => {
          const custom_fields = _filterVolunteerFields(resp);
          this.actions.fetchedCustomFields(custom_fields);
        },
        error: error => {
          ErrorLogger.captureRequest("fetch property schema error", error);
        },
      });
    },
  },
});

export default PropertySource;
