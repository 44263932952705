import PropTypes from "prop-types";
import React from "react";
import { MobileSheet, OverlayActions } from "@evertrue/et-components";
import Avatar from "apps/prospects/components/prospect-avatar";
import StageSelector from "apps/profile/components/stage-selector";
import StageSource from "core/apps/prospects/stage-source";

class UpdateStageMobileSheet extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedStage: PropTypes.string,
    stageGroupId: PropTypes.number,
    assignmentId: PropTypes.number,
  };

  state = {
    stage: this.props.selectedStage,
  };

  handleSave = () => {
    const { stage } = this.state;
    if (this.props.selectedStage !== stage) {
      StageSource.updateStage(this.props.assignmentId, stage);
    }
    OverlayActions.removeOverlay();
  };

  render() {
    return (
      <MobileSheet
        actions={[
          {
            label: "Cancel",
            onClick: () => OverlayActions.removeOverlay(),
            className: "interaction-form--mobile-sheet--cancel-button",
          },
          {
            label: "Save",
            onClick: this.handleSave,
            className: "interaction-form--mobile-sheet--save-button",
          },
        ]}
        label="Edit Stage"
        onBack={() => OverlayActions.removeOverlay()}
        backgroundColor="#FFF"
      >
        <Avatar prospect={this.props.contact} />

        <StageSelector
          isMobile
          selectedStage={this.state.stage}
          className={this.props.className}
          onChange={stage => this.setState({ stage })}
          stageGroupId={this.props.stageGroupId}
          assignmentId={this.props.assignmentId}
        />
      </MobileSheet>
    );
  }
}
export default UpdateStageMobileSheet;
