import React from "react";
import _ from "underscore";
import useGate from "core/apps/gates/use-gate";
import { isLeadVol } from "core/utils/data-utils";
// returns a boolean
// takes in a pool from the /profiles endpoint which gives us pool {solicitor}
// use this along with the gate to determine whether to enable lead vol features
const logOnce = _.once(() => console.warn("you must pass in pool to useIsLeadVol"));
const useIsLeadVol = pool => {
  const head_agent_gate_enabled = useGate({ gate: "vms_head_agent" });
  if (!pool || !Object.keys(pool).length) {
    if (process.env.NODE_ENV === "development") {
      logOnce();
    }
    return false;
  }
  const is_lead_vol = isLeadVol(pool);
  const lead_vol_and_head_agent_gate = head_agent_gate_enabled && is_lead_vol;

  return lead_vol_and_head_agent_gate;
};

export default useIsLeadVol;
