import React from "react";
import {
  BrowserRouter as B,
  Redirect as RD,
  Route as R,
  Switch as S,
  Link as L,
  NavLink as NL,
} from "react-router-dom";

const BrowserRouter = React.createFactory(B);
const Route = React.createFactory(R);
const Redirect = React.createFactory(RD);
const Switch = React.createFactory(S);
const Link = React.createFactory(L);
const NavLink = React.createFactory(NL);

/*
* Depracated Do Not Use. just import { Router } from "react-router-dom"
*/

export { BrowserRouter, Redirect, Route, Switch, Link, NavLink };
