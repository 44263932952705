import React from "react";
import { Button } from "@evertrue/et-components";
import PropTypes from "prop-types";
import UtilMethods from "utils/util-methods";

export default class extends React.Component {
  static displayName = "EmailInput";

  static propTypes = {
    onSendEmail: PropTypes.func,
    emailSendFailed: PropTypes.bool,
    buttonText: PropTypes.string,
  };

  state = {
    email: "",
    invalid_email: false,
  };

  handleEmailChange = e => {
    this.setState({ email: e.currentTarget != null ? e.currentTarget.value : undefined });
  };

  handleSendEmail = e => {
    e.preventDefault();
    const { email } = this.state;
    if (UtilMethods.validateEmail(email)) {
      return this.props.onSendEmail(email);
    } else {
      return this.setState({ invalid_email: true });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSendEmail}>
        <input
          type="email"
          name="email"
          value={this.state.email}
          placeholder="enter your email..."
          onChange={this.handleEmailChange}
        />
        {(() => {
          if (this.state.invalid_email) {
            return <div className="magic-link-request--invalid">The email entered is not a vaild format.</div>;
          } else if (this.props.emailSendFailed) {
            return (
              <div className="magic-link-request--invalid">
                We were unable to send your email. Please try again soon.
              </div>
            );
          }
        })()}
        <Button onClick={this.handleSendEmail}>{this.props.buttonText || "Send My Link"}</Button>
      </form>
    );
  }
}
