import _ from "underscore";
import React from "react";
import classNames from "classnames";
import { connect } from "@evertrue/et-flux";
import { MobileSheet } from "@evertrue/et-components";
import FilterControl from "apps/prospects/components/filters/filter-control";
import FilterDropdown from "apps/prospects/components/filters/filter-dropdown";
import StageFilterDropdown from "apps/prospects/components/filters/stage-filter-dropdown";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import FilterUtils from "apps/filters/filter-utils";
import { GateContext } from "core/apps/gates/gate-context";
import PropTypes from "prop-types";

const mapStateToProps = () => ({
  is_mobile: ResponsiveStore.isMobile(),
  is_tablet: ResponsiveStore.isTablet(),
});

const ProspectFilters = ({ stageGroupId, givingCategoryLabel, isAssignmentsTab, filters = {}, ...props }) => {
  const { gates } = React.useContext(GateContext);
  const [temp_filters, setTempFilters] = React.useState(filters);
  const [active_dropdown, setActiveDropdown] = React.useState({});

  const is_drillin = !_.isEmpty(active_dropdown);
  const active_dropdown_label = active_dropdown.label;

  let filters_for_tab = FilterUtils.getFiltersForTab(isAssignmentsTab, givingCategoryLabel);
  // filter out any filters that should be gated
  filters_for_tab = _.filter(filters_for_tab, filter => {
    const has_gate = filter.show_gate ? gates[filter.show_gate] : null;
    const gate_enabled = has_gate ? has_gate.enabled : false;
    return !filter.show_gate || gate_enabled;
  });

  const handleChange = (key, value) => {
    setTempFilters(prev => {
      let temp_filters = { ...prev };
      if (_.isNull(value) || (_.isArray(value) && _.isEmpty(value))) {
        temp_filters = _.omit(temp_filters, key);
      } else {
        temp_filters[key] = value;
      }
      return temp_filters;
    });
  };

  const handleApply = () => {
    props.onApply(temp_filters);
  };

  const handleReset = () => {
    setTempFilters({});
    props.onApply({});
  };

  const handleClickList = (field = {}) => {
    setActiveDropdown(field);
  };

  const handleBack = () => {
    handleClickList();
  };

  const renderActiveDropdown = () => {
    const { key } = active_dropdown;
    if (key === "stages") {
      return (
        <StageFilterDropdown
          value={temp_filters[key]}
          stageGroupId={stageGroupId}
          onChange={val => {
            handleChange(key, val);
          }}
        />
      );
    }
    return (
      <FilterDropdown
        filter={active_dropdown}
        value={temp_filters[key]}
        onChange={val => {
          handleChange(key, val);
        }}
      />
    );
  };

  return (
    <MobileSheet
      className={classNames("prospect-filters", props.className, {
        "is-drillin": is_drillin,
        "is-desktop": !(props.is_mobile || props.is_tablet),
        "is-mobile": props.is_mobile,
      })}
      label={active_dropdown_label || (!isAssignmentsTab ? "Filter Prospects" : "Filter Assignments")}
      backgroundColor="#FFF"
      headerActionLabel={!is_drillin ? "X" : undefined}
      onHeaderAction={!is_drillin ? props.onClose : undefined}
      onBack={is_drillin ? handleBack : undefined}
      actions={
        !is_drillin
          ? [
              { label: "Reset Filters", className: "prospect-filters--reset", onClick: handleReset },
              { label: "Apply Filters", className: "prospect-filters--apply", onClick: handleApply },
            ]
          : null
      }
    >
      <div className={classNames("prospect-filters--body", { "is-drillin": is_drillin })}>
        {is_drillin
          ? renderActiveDropdown()
          : _.map(filters_for_tab, (filter = {}) => {
              return (
                <FilterControl
                  key={filter.key}
                  filter={filter}
                  current_val={temp_filters[filter.key]}
                  onChange={handleChange}
                  onClickList={handleClickList}
                />
              );
            })}
      </div>
    </MobileSheet>
  );
};

ProspectFilters.propTypes = {
  stageGroupId: PropTypes.number,
  givingCategoryLabel: PropTypes.string,
  isAssignmentsTab: PropTypes.bool,
  filters: PropTypes.object,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  className: PropTypes.string,
};

export default connect(ProspectFilters, [ResponsiveStore], mapStateToProps);
