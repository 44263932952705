import _ from "underscore";
import React from "react";
import { div, span, a } from "react-dom-factories";
import { createComponent } from "@evertrue/et-flux";
import classnames from "classnames";
import ProfileInteractionStore from "core/apps/interactions/interaction-store";
import ProfileInteractionSource from "core/apps/interactions/interaction-source";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import SessionStore from "apps/auth/stores/session-store";
import InteractionForm from "apps/profile/components/interaction-form";
import { AdvancedCombobox, EmptyStateMessage, Loading, Icon, ModalTriggerDeprecated } from "components/et-factories";
import PropTypes from "prop-types";

const InteractionItem = React.createFactory(require("apps/profile/components/interaction-item").default);
const RespondTo = React.createFactory(require("components/respond-to").default);

const ALL_ACTIVITY_OPTION = { value: "all", label: "All Activity" };

export default createComponent("ProfileActivityController", {
  propTypes: {
    contact: PropTypes.object,
    pool: PropTypes.object,
  },

  getDefaultProps() {
    return { contact: {} };
  },

  registerStores() {
    this.on(ProfileInteractionStore, "contact", (props) => ({
      interactions:
        ProfileInteractionStore.getInteractionsFor(props.contact != null ? props.contact.id : undefined) || {},
      loading: ProfileInteractionStore.getLoading(),
      loading_more: ProfileInteractionStore.getLoadingMore(),
      types: ProfileInteractionStore.getInteractionTypes(),
      filter: ProfileInteractionStore.getFilters().interaction_type,
      hasMoreToLoad: ProfileInteractionStore.hasMoreToLoad(props.contact != null ? props.contact.id : undefined),
    }));

    this.on(ResponsiveStore, () => ({
      is_mobile: ResponsiveStore.isMobile(),
      is_tablet: ResponsiveStore.isTablet(),
    }));

    this.on(SessionStore, () => ({ is_impersonating: SessionStore.getIsImpersonating() }));
  },

  componentDidMount() {
    if (this.props.contact.id) {
      this.fetchInteractions(this.props);
    }
  },

  fetchInteractions(props) {
    ProfileInteractionSource.fetch(props.contact != null ? props.contact.id : undefined);
  },

  handlePagination() {
    ProfileInteractionSource.paginate(this.props.contact != null ? this.props.contact.id : undefined);
  },

  handleFilter(val) {
    const { id } = this.props.contact;
    if (val.value === "all") {
      return ProfileInteractionSource.filter(id);
    } else {
      return ProfileInteractionSource.filter(id, { interaction_type: val.value });
    }
  },

  render() {
    const { interactions } = this.state;
    const modal_trigger_child = this.state.is_mobile
      ? Icon({ icon: "add-circle", size: 2 })
      : span(null, "+ Add Note / Log Activity");

    return div(
      { className: "profile-activity profile-module" },
      div(
        { className: classnames("profile-activity--subheader", { empty: !interactions.length }) },
        RespondTo(
          { className: "profile-activity--subheader-label" },
          (this.state.interactions != null ? this.state.interactions.total : undefined) || this.state.filter
            ? AdvancedCombobox({
                className: "profile-activity--activity-dropdown advanced-combobox-secondary",
                value: _.findWhere(this.state.types, { value: this.state.filter }) || ALL_ACTIVITY_OPTION,
                clearable: false,
                options: [ALL_ACTIVITY_OPTION].concat(
                  this.state.types.map((item) => _.extend({}, item, { label: `${item.label}s` })),
                ),
                onChange: this.handleFilter,
              })
            : undefined,
        ),

        div(
          { className: "profile-activity--subheader-count" },
          ModalTriggerDeprecated(
            {
              disable: this.state.is_impersonating,
              modal: InteractionForm({
                tab: this.props.tab,
                assignment: this.props.assignment,
                pool: this.props.pool,
                contact: this.props.contact,
                header: "Add Note / Log Activity",
                isMobile: this.state.is_mobile,
              }),
            },
            modal_trigger_child,
          ),
        ),
      ),

      div(
        { className: "profile-activity--feed" },
        (() => {
          if (this.state.loading && !this.state.loading_more) {
            return Loading();
          } else if (interactions != null ? interactions.total : undefined) {
            return _.map(interactions.items, (interaction) => {
              return InteractionItem({
                tab: this.props.tab,
                assignment: this.props.assignment,
                key: interaction.id,
                contact: this.props.contact,
                interaction,
                pool: this.props.pool,
              });
            });
          } else {
            const text = this.state.filter !== "all" ? "of this type" : "";
            return EmptyStateMessage({
              icon: "search",
              text: `No Activity ${text}`,
            });
          }
        })(),

        this.state.hasMoreToLoad
          ? a(
              { href: "#", onClick: this.handlePagination },
              this.state.loading_more ? Icon({ icon: "spinner", spin: true }) : "View More",
            )
          : undefined,
      ),
    );
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
