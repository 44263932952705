import ErrorLogger from "utils/error-logger";
import Config from "config/env";

const env = Config.dataEnv;
const _formatKey = key => `etvol_${env}_${key}`;

export default {
  get(key) {
    try {
      return typeof localStorage !== "undefined" && localStorage !== null
        ? localStorage.getItem(_formatKey(key))
        : undefined;
    } catch (error) {}
  },

  set(key, value) {
    try {
      return typeof localStorage !== "undefined" && localStorage !== null
        ? localStorage.setItem(_formatKey(key), value)
        : undefined;
    } catch (e) {
      return ErrorLogger.warn("LocalStorage Unavailable", { error: e });
    }
  },

  remove(key) {
    try {
      return typeof localStorage !== "undefined" && localStorage !== null
        ? localStorage.removeItem(_formatKey(key))
        : undefined;
    } catch (error) {}
  },
};
