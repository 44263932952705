import React from "react";
import PropTypes from "prop-types";
import { ModalTrigger2, PlainButton, Popover2 } from "@evertrue/et-components";
import { connect } from "@evertrue/et-flux";
import ResponsiveStore from "apps/layout/stores/responsive-store";

const mapStateToProps = () => ({ isMobile: ResponsiveStore.isMobile() });
// we got a new design for mobile popover

const ResponsivePopover2 = ({ alignPopover, isMobile, triggerButton, mobileContent, content }) => {
  return isMobile ? (
    <ModalTrigger2
      overlayClassName="pool-card-dropdown--mobile-overlay"
      contentClassName="pool-card-dropdown--mobile-modal"
      trigger={({ open, close, is_open }) => triggerButton({ toggle: is_open ? close : open, isOpen: is_open })}
      disableDefaultStyles
    >
      {({ close }) => (
        <React.Fragment>
          <div className="responsive-popover2--mobile-content">
            {typeof mobileContent === "function" ? mobileContent({ close }) : mobileContent}
          </div>
          <PlainButton onClick={close} className="pool-card-dropdown--mobile-link pool-card-dropdown--mobile-cancel">
            Cancel
          </PlainButton>
        </React.Fragment>
      )}
    </ModalTrigger2>
  ) : (
    <Popover2
      placement="bottom-start"
      wrapperClassName="responsive-popover2--container"
      trigger={({ is_open, togglePopover }) => triggerButton({ isOpen: is_open, toggle: togglePopover })}
    >
      {({ closePopover }) => <div className="responsive-popover2--content">{content({ close: closePopover })}</div>}
    </Popover2>
  );
};

ResponsivePopover2.propTypes = {
  alignPopover: PropTypes.string,
  isMobile: PropTypes.bool,
  triggerButton: PropTypes.func,
  mobileContent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  content: PropTypes.func,
};

export default connect(
  ResponsivePopover2,
  [ResponsiveStore],
  mapStateToProps,
);
