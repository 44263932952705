import React from "react";
import { Button } from "@evertrue/et-components";
import { Route } from "react-router-dom";
import Navigator from "utils/navigator";
import MobileUtils from "utils/mobile-utils";
import PropTypes from "prop-types";

const app_store_url = MobileUtils.getAppStoreLink();
const img_url = MobileUtils.getAppStoreIcon();

export default class extends React.Component {
  static displayName = "MobileRedirectController";

  static propTypes = {
    redirectTo: PropTypes.string,
  };

  static defaultProps = { redirectTo: "" };

  handleOpenMobile = () => {
    const [pathname, param_data] = Array.from(this.props.redirectTo.split("?"));
    Navigator.goToMobile(pathname, param_data);
  };

  handleRedirect = history => {
    history.push(this.props.redirectTo);
  };

  render() {
    return (
      <div className="mobile-redirect">
        <div className="mobile-redirect--wrapper">
          <h2 className="mobile-redirect--header">
            Welcome to
            <br />
            Volunteer by EverTrue
          </h2>
          <Route
            render={({ history }) => {
              return (
                <div>
                  <Button className="mobile-redirect--browser-btn" onClick={() => this.handleRedirect(history)}>
                    Continue in browser
                  </Button>
                  <Button onClick={this.handleOpenMobile}>Open in App</Button>
                </div>
              );
            }}
          />
          <div className="mobile-redirect--install">
            {"Don't have our mobile app installed?"}
            <div>
              <a href={`${app_store_url}`} target="_blank" rel="noopener noreferrer">
                Download it now!
              </a>
            </div>
          </div>
          <div className="mobile-redirect--app-btn">
            <a href={app_store_url} target="_blank" rel="noopener noreferrer">
              <img src={img_url} alt="mobile app" />
            </a>
          </div>
        </div>
        <img className="mobile-redirect--image" alt="volunteer email " src="/images/volunteer-email-img_lg@2x.png" />
      </div>
    );
  }
}
