import _ from "underscore";
import React from "react";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import classNames from "classnames";
import Decorator from "@evertrue/client-decorator";
import { Button, Icon, MobileSheet, ModalConfirm, ModalTrigger2, LinkButton } from "@evertrue/et-components";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import RespondTo from "components/respond-to";
import ProfileSectionItem from "apps/profile/components/profile-section-item";
import CDCModal from "apps/profile/components/cdc-modal";
import IsGated from "core/apps/gates/is-gated";

const mapStateToProps = () => {
  return {
    is_mobile: ResponsiveStore.isMobile(),
  };
};

class ProfileContactInfo extends React.Component {
  static propTypes = {
    contact: PropTypes.object,
    onTrack: PropTypes.func,
    is_mobile: PropTypes.bool,
  };

  state = {
    is_cdc_modal_open: false,
  };

  renderPhone = phone => {
    return (
      <span>
        <RespondTo to={["mobile", "tablet"]}>
          <a href={`tel:${phone}`} target="_blank" onClick={() => this.props.onTrack("phone")}>
            {phone}
          </a>
        </RespondTo>
        <RespondTo to={"desktop"}>{phone}</RespondTo>
      </span>
    );
  };

  renderModalContent = close => {
    const contact_info = Decorator.Profile.getContactInfo(this.props.contact);
    // Show primary results first
    const phones = _.sortBy(contact_info.phones, phone => !(phone != null ? phone.primary : undefined));
    const emails = _.sortBy(contact_info.emails, email => !(email != null ? email.primary : undefined));

    const { solicitation_codes } = this.props.contact;

    return (
      <div className="profile-bio-modal fs-exclude-data">
        <div className="profile-bio-modal--content">
          <Icon icon="call" className="profile-bio-modal--icon" />
          <div className="profile-bio-modal--section">
            <div className="profile-bio-modal--section-title">Phone</div>
            {_.isEmpty(phones) ? (
              <span className="profile-bio-modal--empty-value">None provided</span>
            ) : (
              _.map(phones, (phone = {}, i) => {
                return (
                  <div
                    key={phone.phone + "-" + i}
                    className={classNames("profile-bio-modal--item", { "is-last": i + 1 === phones.length })}
                  >
                    <div className="profile-bio-modal--item-type">
                      <span>{phone.type || "Other"}</span>
                      {phone.primary && <span> (Primary)</span>}
                    </div>
                    {this.renderPhone(phone.phone)}
                  </div>
                );
              })
            )}
          </div>
        </div>

        <div className="profile-bio-modal--content">
          <Icon icon="email" className="profile-bio-modal--icon" />
          <div className="profile-bio-modal--section">
            <div className="profile-bio-modal--section-title">Email</div>
            {_.isEmpty(emails) ? (
              <span className="profile-bio-modal--empty-value">None provided</span>
            ) : (
              _.map(emails, (email = {}, i) => {
                return (
                  <div
                    key={email.email + "-" + i}
                    className={classNames("profile-bio-modal--item", { "is-last": i + 1 === emails.length })}
                  >
                    <div className="profile-bio-modal--item-type">
                      <span>{email.type || "Other"}</span>
                      {email.primary && <span> (Primary)</span>}
                    </div>
                    <a href={`mailto:${email.email}`} target="_blank" onClick={() => this.props.onTrack("email")}>
                      {email.email}
                    </a>
                  </div>
                );
              })
            )}
          </div>
        </div>

        <div className="profile-bio-modal--content">
          <Icon icon="report-problem" className="profile-bio-modal--icon" />
          <div className="profile-bio-modal--section is-last">
            <div className="profile-bio-modal--section-title">Solicitation Notes</div>
            {_.isEmpty(solicitation_codes) ? (
              <span className="profile-bio-modal--empty-value">None provided</span>
            ) : (
              _.map(solicitation_codes, (code = {}, i) => {
                const code_value = code.code ? code.code.value : null;
                return (
                  <div key={code_value + "-" + i} className="profile-bio-modal--solicitation-code">
                    {code_value}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <IsGated gate="vms_cdc">
          <Button
            onClick={() => {
              close();
              this.setState({ is_cdc_modal_open: true });
            }}
          >
            <Icon icon="edit" className="profile-bio-modal--cdc-icon" />
            Suggest Contact Update
          </Button>
        </IsGated>
      </div>
    );
  };

  renderViewMoreModal = ({ close }) => {
    if (this.props.is_mobile) {
      return (
        <MobileSheet
          className="profile-info--mobile-sheet"
          label="Contact Information"
          onBack={close}
          backgroundColor="#FFF"
        >
          {this.renderModalContent(close)}
        </MobileSheet>
      );
    } else {
      return (
        <ModalConfirm style={{ width: 450 }} header="Additional Contact Information" onCancel={close}>
          {this.renderModalContent(close)}
        </ModalConfirm>
      );
    }
  };

  render() {
    const primary_contact_info = Decorator.Profile.getPrimaryContactInfo(this.props.contact) || {};
    const phone = primary_contact_info.phone != null ? primary_contact_info.phone.phone : undefined;
    const email = primary_contact_info.email != null ? primary_contact_info.email.email : undefined;

    return (
      <RespondTo className="profile-section--content-wrapper">
        <div className="profile-section--content fs-exclude-data">
          <ProfileSectionItem icon="call">{phone ? this.renderPhone(phone) : null}</ProfileSectionItem>
          <ProfileSectionItem icon="email">
            {email ? (
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => this.props.onTrack("email")}
              >
                {email}
              </a>
            ) : null}
          </ProfileSectionItem>
        </div>
        <div className="profile-section--view-more">
          <ModalTrigger2
            disableDefaultStyles={this.props.is_mobile}
            trigger={({ open }) => (
              <LinkButton title="More information" onClick={open}>
                VIEW MORE
              </LinkButton>
            )}
          >
            {({ close }) => this.renderViewMoreModal({ close })}
          </ModalTrigger2>
        </div>
        <CDCModal
          isMobile={this.props.is_mobile}
          disableDefaultStyles={this.props.is_mobile}
          isOpen={this.state.is_cdc_modal_open}
          contact={this.props.contact}
          onClose={() => this.setState({ is_cdc_modal_open: false })}
        />
      </RespondTo>
    );
  }
}

export default connect(
  ProfileContactInfo,
  [ResponsiveStore],
  mapStateToProps,
);
