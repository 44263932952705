import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import ContactsSource from "./contacts-source";
import Utils from "core/utils/utils";

const ContactsStore = createStore("ContactsStore", {
  getInitialState() {
    return {
      loading: undefined,
      contacts_by_id: {},
      ids_is_fetching: [],
      ids_have_failed: [],
    };
  },

  registerActions() {
    this.on(ContactsSource.actions.loadingAssignments, this.respondToLoading);
    this.on(ContactsSource.actions.fetched, this.respondToFetched);
    this.on(ContactsSource.actions.fetchUnlessCached, this.respondToFetchedUnlessCached);
    this.on(ContactsSource.actions.cache, (contacts) => {
      this.respondToFetched(_.indexBy(contacts, "id"));
    });

    this.on(ContactsSource.actions.idsHasFetched, (ids) => {
      this.setState({
        ids_is_fetching: _.filter(this.state.ids_is_fetching, the_id => _.contains(ids, the_id)),
      });
    });

    this.on(ContactsSource.actions.failedToFetch, (ids) => {
      this.setState({
        ids_have_failed: [].concat(this.state.ids_have_failed, ids),
        loading: false,
      });
    });
  },

  respondToLoading(loading) {
    this.setState({ loading });
  },

  respondToFetched(response_index) {
    const contacts_by_id = this.getState("contacts_by_id");
    this.setState({
      contacts_by_id: _.extend({}, contacts_by_id, response_index),
      loading: false,
    });
  },

  respondToFetchedUnlessCached(pool_id, ids) {
    const old_ids = Object.keys(this.state.contacts_by_id).map(Utils.toNumber);
    const current_ids_not_error_or_in_flight = ids
      .filter(id => !this.state.ids_is_fetching.includes(id))
      .filter(id => !this.state.ids_have_failed.includes(id));

    const new_ids = _.difference(current_ids_not_error_or_in_flight, old_ids);

    if (new_ids.length) {
      this.setState({
        ids_is_fetching: this.getState("ids_is_fetching").concat(ids),
        loading: true,
      });
      ContactsSource.fetch(pool_id, new_ids);
    }
  },

  api: {
    getContactForId(id) {
      if (id) {
        return this.getState("contacts_by_id")[id];
      }
      return {};
    },

    getForIds(ids) {
      const map = {};
      _.each(ids, id => {
        map[id] = this.getState("contacts_by_id")[id];
      });
      return Utils.compactObject(map);
    },

    getLoading() { return this.state.loading; },
  },
});

export default ContactsStore;

