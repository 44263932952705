import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import OrgSource from "org-source";
import PoolSource from "./pool-source";

const _formatPool = (profile = {}) => ({
  solicitor: profile.solicitor,
  has_self_selection: (profile.pool != null ? profile.pool.selection_mode : undefined) === "SOLICITOR_SELECTION",
  ...profile.pool,
});

const PoolStore = createStore("PoolStore", {
  getInitialState() {
    return {
      pools: [],
      loading: false,
    };
  }, // initialize with pools loading

  registerActions() {
    this.on(PoolSource.actions.loading, this.respondToLoading);
    this.on(PoolSource.actions.fetchedPools, this.respondToFetchedPools);

    this.on(OrgSource.actions.setCurrentOrg, () => {
      this.setState({ pools: [] });
    });
  },

  respondToLoading(loading) {
    this.setState({ loading });
  },

  respondToFetchedPools(profiles) {
    const pools = _.map(profiles, _formatPool);
    this.setState({ pools: _.sortBy(pools, "name") });
  },

  api: {
    getLoading() {
      return this.state.loading;
    },

    getPools() {
      return this.state.pools;
    },

    getPoolsIndex() {
      return _.indexBy(this.getPools(), "id");
    },

    getById(id) {
      return _.findWhere(this.getPools(), { id }) || {};
    },

    getSolicitorId(pool_id) {
      const pool_by_id = this.getById(pool_id);
      const solicitor = pool_by_id ? pool_by_id.solicitor : {};
      return solicitor.id;
    },
  },
});

export default PoolStore;
