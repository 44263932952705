import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

const NotificationContent = ({ title, date, org }) => {
  return (
    <div className="notification-item--content">
      <div className="notification-item--title">{title}</div>
      {date && <div className="notification-item--date">{moment(date).format("MMM DD, YYYY")}</div>}
      {org && <div className="notification-item--org">{org}</div>}
    </div>
  );
};

NotificationContent.propTypes = {
  title: PropTypes.string,
  date: PropTypes.number,
};

export default NotificationContent;
