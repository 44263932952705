import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import NotificationItem from "apps/notifications/components/notification-item";
import NotificationStore from "apps/notifications/stores/notification-store";
import NotificationSource from "apps/notifications/sources/notification-source";
import NotificationSettingsModal from "apps/notifications/components/notification-settings-modal";
import RespondTo from "components/respond-to";
import { Button, Loading, EmptyStateMessage, ModalTriggerDeprecated } from "@evertrue/et-components";
import { connect } from "@evertrue/et-flux";

const mapStateToProps = () => {
  return {
    notifications: NotificationStore.getNotifications(),
    loading: NotificationStore.getLoading(),
    error: NotificationStore.getError(),
  };
};

class NotificationInboxController extends React.Component {
  static propTypes = {
    notifications: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    onClose: PropTypes.func,
  };

  handleReadAll = () => {
    NotificationSource.markAsRead("all");
  };

  renderList() {
    const notifications = _.map(this.props.notifications, (notification) => {
      return <NotificationItem key={notification.id} notification={notification} onClick={this.props.onClose} />;
    });

    if (this.props.loading) {
      return <Loading />;
    } else if (this.props.error) {
      return (
        <EmptyStateMessage icon="warn" text="Error Loading Notifications">
          {this.props.error}
        </EmptyStateMessage>
      );
    } else if (_.isEmpty(this.props.notifications)) {
      return (
        <EmptyStateMessage icon="notifications" text="No Notifications">
          You will receive notifications when your assignments have given a gift.
        </EmptyStateMessage>
      );
    }

    return <RespondTo className="notification-inbox--body">{notifications}</RespondTo>;
  }

  render() {
    return (
      <RespondTo className="notification-inbox">
        <div className="notification-inbox--header">
          Notifications
          <div className="notification-inbox--options">
            <Button type="simple" onClick={this.handleReadAll} className="notification-inbox--readall">
              Mark all read
            </Button>
            <ModalTriggerDeprecated modal={<NotificationSettingsModal />}>Settings</ModalTriggerDeprecated>
          </div>
        </div>
        {this.renderList()}
      </RespondTo>
    );
  }
}

export default connect(NotificationInboxController, [NotificationStore], mapStateToProps);
