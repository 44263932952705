import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "base/api";
import ErrorLogger from "utils/error-logger";

const PoolSource = createSource("PoolSource", {
  actions: {
    loading(is_loading) {
      this.require(_.isBoolean(is_loading), "is_loading should be boolean");
    },
    fetchedPools(pools) {
      this.require(_.isArray(pools), "pools should be an array");
    },
    fetchProspects(pool_id) {
      this.require(_.isNumber(pool_id), "pool_id should be a number");
    },
  },

  api: {
    fetch() {
      this.actions.loading(true);
      Api.VOLUNTEERS.SOLICITOR.get({
        success: resp => {
          this.actions.fetchedPools(resp || []);
          this.actions.loading(false);
        },
        error: error => {
          ErrorLogger.captureRequest("fetch solicitors error", error);
          this.actions.fetchedPools([]);
          this.actions.loading(false);
        },
      });
    },

    fetchProspects(pool_id) {
      if (pool_id) {
        this.actions.fetchProspects(pool_id);
      }
    },
  },
});

export default PoolSource;
