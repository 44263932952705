const MixpanelEvents = {
  app_entered: {
    name: "App Entered",
  },
  auth_started: {
    name: "Auth Started",
    type: {
      register: "register",
      new_session: "new magic link session",
      new_sso_session: "new sso session",
    },
  },
  auth_success: {
    name: "Auth Succeeded",
    type: {
      register: "register",
      new_session: "new magic link session",
    },
  },
  auth_failed: {
    name: "Auth Failed",
    type: {
      register: "register",
      new_session: "new_session",
    },
  },
  auth_logout: {
    name: "Logout",
  },
  token_refresh: {
    name: "Token Refresh",
  },
  user_auth: {
    name: "User Authenticated",
  },
  profile_viewed: {
    name: "Profile Viewed",
    props: ["contact_id", "is_secondary", "is_assignment"],
  },
  assignment_action: {
    name: "Assignment Action",
    type: {
      unassigned_to_me: "remove assignment",
      assigned_to_me: "assign to me",
    },
    props: ["contact_id", "pool_id"],
  },
  outreach_action: {
    name: "Outreach Action",
    type: {
      contact_link: "profile contact link",
    },
    props: ["outreach_type", "contact_id", "pool_id"],
  },
  interaction_action: {
    name: "Interaction Action",
    type: {
      create_interaction: "create interaction",
      update_interaction: "update interaction",
      delete_interaction: "delete interaction",
    },
    props: ["contact_id", "interaction_type"],
  },
  pool_report_action: { name: "Action on a Pool Report" },
};

export default MixpanelEvents;
