import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "base/api";
import ErrorLogger from "utils/error-logger";

const SocialProfileSource = createSource("SocialProfileSource", {
  actions: {
    loading(loading) {
      this.require(_.isBoolean(loading), "loading should be a boolean");
    },

    fetchedProfile(contact_id, profile) {
      this.require(_.isNumber(contact_id), "contact_id should be a number");
      this.require(_.isObject(profile), "profile should be an object");
    },
  },

  api: {
    fetch(contact_id) {
      this.actions.loading(true);
      Api.LIDS.SOCIAL_PROFILE.get({
        urlExtend: `/${contact_id}`,
        success: resp => {
          if (!_.isEmpty(resp)) {
            this.actions.fetchedProfile(contact_id, resp);
          }
          this.actions.loading(false);
        },
        error: error => {
          ErrorLogger.captureRequest("fetch social profile", error);
          this.actions.loading(false);
        },
      });
    },
  },
});

export default SocialProfileSource;
