import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import { NavLink } from "react-router-dom";
import { connect } from "@evertrue/et-flux";
import { MyProspectsStore, ProspectsStore } from "core/apps/prospects/prospects-store";
import { ProspectsSource, MyProspectsSource } from "core/apps/prospects/prospects-source";
import RespondTo from "components/respond-to";

const mapStateToProps = ({ poolId }) => {
  return {
    myPool: MyProspectsStore.getForPool(poolId),
    pool: ProspectsStore.getForPool(poolId),
    originalMyProspectTotal: MyProspectsStore.getPoolTotal(poolId),
    originalProspectTotal: ProspectsStore.getPoolTotal(poolId),
  };
};

class ProspectNav extends React.Component {
  static propTypes = {
    poolId: PropTypes.number,
    myPool: PropTypes.object,
    pool: PropTypes.object,
    originalProspectTotal: PropTypes.number,
    originalMyProspectTotal: PropTypes.number,
    clearSearchInput: PropTypes.func.isRequired,
  };

  static defaultProps = { pool: {}, myPool: {} };

  resetProspectSearch = () => {
    this.props.clearSearchInput();
    if (this.props.originalProspectTotal !== this.props.pool.total) {
      ProspectsSource.filter(this.props.poolId, {});
    }
  };

  resetAssignmentSearch = () => {
    this.props.clearSearchInput();
    if (this.props.originalMyProspectTotal !== this.props.myPool.total) {
      MyProspectsSource.filter(this.props.poolId, {});
    }
  };

  render() {
    const { myPool, pool, poolId } = this.props;
    const total_assignments = myPool.total || 0;
    const total_prospects = pool.total || 0;

    return (
      <RespondTo className="pool-contacts--nav">
        <span className="pool-contacts--nav--tab">
          <NavLink onClick={this.resetProspectSearch} to={`/pools/${poolId}/assignments/`} activeClassName="selected">
            Assignments
            {total_assignments != null && ` (${total_assignments})`}
          </NavLink>
        </span>
        <span className="pool-contacts--nav--tab" onClick={this.resetAssignmentSearch}>
          <NavLink to={`/pools/${poolId}/prospects/`} activeClassName="selected">
            Prospects
            {total_prospects != null && ` (${total_prospects})`}
          </NavLink>
        </span>
      </RespondTo>
    );
  }
}

export default connect(ProspectNav, [ProspectsStore, MyProspectsStore], mapStateToProps);
