import React from "react";
import Datalist from "components/datalist";
import { AdvancedCombobox, OverlayActions } from "@evertrue/et-components";
import { AlertActions, alertTimeout } from "core/utils/alert-actions";
import ResponsiveModal from "components/responsive-modal";
import Api from "core/base/api";
import { NOTIFICATION_KEY } from "apps/notifications/constants";

const LABELS = {
  gifts: {
    label: "Gifts",
    description: "Get notifications when your assignments give a gift.",
  },
};

const OPTIONS = [
  { label: "Email & Push", value: "all" },
  { label: "Push Only", value: "push" },
  { label: "Email Only", value: "email" },
  { label: "None", value: "off" },
];

class NotificationSettingsModal extends React.Component {
  state = { gifts: "all" };

  async componentDidMount() {
    const prefs = await Api.EMS.PREFS.get({ urlArgs: { key: NOTIFICATION_KEY }, disableAlerts: true });

    if (prefs) {
      let gifts = "off";
      if (prefs.push_enabled && prefs.email_enabled) {
        gifts = "all";
      } else if (prefs.push_enabled) {
        gifts = "push";
      } else if (prefs.email_enabled) {
        gifts = "email";
      }
      this.setState({ gifts });
    }
  }

  handleSave = async () => {
    const gifts = this.state.gifts;
    await Api.EMS.PREFS.put({
      urlArgs: { key: NOTIFICATION_KEY },
      data: {
        push_enabled: gifts === "all" || gifts === "push",
        email_enabled: gifts === "all" || gifts === "email",
      },
    });
    AlertActions.launchToast({ message: "Notification settings saved!", type: "success", timeout: alertTimeout });
    this.handleRemoveSheet();
  };

  handleChange = ({ value }) => {
    this.setState({ gifts: value });
  };

  handleRemoveSheet = () => {
    OverlayActions.removeOverlay();
    this.props.onUnmount();
  };

  renderLabel(data) {
    return (
      <div className="notification-settings--label">
        <strong>{data.label}</strong>
        <div>{data.description}</div>
      </div>
    );
  }

  render() {
    const options = {
      hasMargin: true,
      saveLabel: "Save",
      onCancel: this.handleRemoveSheet,
      onSubmit: this.handleSave,
    };

    return (
      <ResponsiveModal
        className="notification-settings--modal"
        width={575}
        header="Notification Settings"
        desktopOptions={options}
        mobileOptions={{
          ...options,
          actions: [
            { label: "Cancel", onClick: this.handleRemoveSheet },
            { label: "Save", onClick: this.handleSave },
          ],
        }}
      >
        <div className="notification-settings">
          <Datalist className="notification-settings--item" label={this.renderLabel(LABELS.gifts)}>
            <AdvancedCombobox value={{ value: this.state.gifts }} options={OPTIONS} onChange={this.handleChange} />
          </Datalist>
        </div>
      </ResponsiveModal>
    );
  }
}

export default NotificationSettingsModal;
