import React, { Component } from "react";
import PropTypes from "prop-types";
import { EmptyStateMessage } from "@evertrue/et-components";
import ErrorLogger from "utils/error-logger";

class PageErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  state = { has_error: false, error_count: 0 };

  componentDidCatch(error, info) {
    this.setState(state => ({ has_error: true, error_count: state.error_count + 1 }));
    const { error_count } = this.state;
    ErrorLogger.error("Page Render Error", { extra: { info, error: error.message, error_count } });
  }

  componentDidUpdate(prevProps) {
    if (this.state.has_error && prevProps.children !== this.props.children) {
      this.setState({ has_error: false });
    }
  }

  render() {
    if (this.state.has_error || this.state.error_count > 25) {
      return (
        <EmptyStateMessage
          className="page-error-boundary"
          size="page"
          icon="build"
          text="There was a problem displaying this page"
        />
      );
    }
    return this.props.children || null;
  }
}

export default PageErrorBoundary;
