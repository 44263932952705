import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import ErrorLogger from "utils/error-logger";
import Api from "base/api";

const MagicLinkSource = createSource("MagicLinkSource", {
  actions: {
    setMagicToken(token) {
      this.require(_.isString(token) || _.isNull(token), "token should be a string or null");
    },
    setTokenEmailStatus(status) {
      this.require(_.contains(["success", "error"], status), "status is not a valid string");
    },
    sending(sending) {
      this.require(_.isBoolean(sending), "sending should be a boolean");
    },
  },

  api: {
    setMagicToken(token) {
      this.actions.setMagicToken(token);
    },

    sendTokenFromEmail(email) {
      this.actions.sending(true);
      return Api.VOLUNTEERS.AUTH.post({
        data: { email },
        headers: {
          "Authorization-Provider": null,
          Authorization: null,
        },
        success: () => {
          this.actions.setTokenEmailStatus("success");
          this.actions.sending(false);
        },
        error: response => {
          this.actions.setTokenEmailStatus("error");
          this.actions.sending(false);
          ErrorLogger.captureRequest("Auth Error: /auth/lost", response);
        },
      });
    },
  }, // end api
});

export default MagicLinkSource;
