/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";
import Config from "config/env";

const { Raven } = window;

const SENTRY_PATH = "https://e94bae6966e849b8bce9a123122ffd9d@sentry.io/126670";
if (!Config.isDevelopment) {
  __guardMethod__(
    Raven != null
      ? Raven.config(SENTRY_PATH, {
          environment: Config.urlEnv,
          tags: { data_environment: Config.dataEnv },
          autoBreadcrumbs: { xhr: true, console: true, dom: true, location: true },
        })
      : undefined,
    "install",
    o => o.install(),
  );
}

export default {
  send(message, data) {
    if (data == null) {
      data = {};
    }
    if (!(data.tags || data.extra) && !_.isEmpty(data)) {
      data = { extra: data };
    }

    if (!Config.isDevelopment) {
      if (__guardMethod__(Raven, "isSetup", o1 => o1.isSetup())) {
        Raven.captureMessage(message, data);
      } else {
        return console.warn(`(Sentry Error) - ${message}`, data);
      }
    }
  },

  error(message, data) {
    if (data == null) {
      data = {};
    }
    this.send(message, data);
  },

  warn(message, data) {
    if (data == null) {
      data = {};
    }
    if (!(data.tags || data.extra)) {
      data = { extra: data };
    }
    this.send(message, _.extend({ level: "warning" }, data));
  },

  captureRequest(message, xhr = {}) {
    // if xhr is a type error, which could happen if its just
    // a js error thats getting caught, then add the js error message to default message
    const sentry_message = xhr.message ? `${message} - ${xhr.message}` : message;

    this.warn(`${sentry_message}`, {
      tags: {
        status_code: xhr.status,
      },
      extra: { response: xhr.stack ? xhr.stack : xhr },
    });
  },
};

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== "undefined" && obj !== null && typeof obj[methodName] === "function") {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
