/* eslint-disable */ // decaf BS
import _ from "underscore";
import React from "react";
import classNames from "classnames";
import { connect, useFluxStore } from "@evertrue/et-flux";
import ResponsiveStore from "apps/layout/stores/responsive-store";

const TYPES = ["mobile", "tablet", "desktop"];

const mapStateToProps = () => ({
  is_mobile: ResponsiveStore.isMobile(),
  is_tablet: ResponsiveStore.isTablet(),
});

export const useIsMobile = () => {
  const { is_mobile } = useFluxStore(ResponsiveStore, mapStateToProps);
  return is_mobile;
};

class RespondTo extends React.Component {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.oneOf(TYPES), PropTypes.array]),
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  render() {
    let show_all, show_desktop, show_mobile, show_tablet;
    const is_desktop = !this.props.is_mobile && !this.props.is_tablet;

    const classes = {
      "is-mobile": this.props.is_mobile,
      "is-tablet": this.props.is_tablet,
      "is-desktop": is_desktop,
    };

    if (this.props.to) {
      const respond_to = _.isArray(this.props.to) ? this.props.to : [this.props.to];
      show_mobile = this.props.is_mobile && _.contains(respond_to, "mobile");
      show_tablet = this.props.is_tablet && _.contains(respond_to, "tablet");
      show_desktop = is_desktop && _.contains(respond_to, "desktop");
    } else {
      show_all = true;
    }

    return (
      <div className={classNames("respond-to", this.props.className, classes)} style={this.props.style}>
        {(() => {
          if (show_all) {
            return this.props.children;
          } else if (show_mobile || show_tablet || show_desktop) {
            return this.props.children;
          }
          return null;
        })()}
      </div>
    );
  }
}

export default connect(
  RespondTo,
  [ResponsiveStore],
  mapStateToProps,
);
