import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Utils from "core/utils/utils";
import ErrorLogger from "utils/error-logger";
import Api from "base/api";
import Decorator from "@evertrue/client-decorator";

const fetchContactsWithSearch = (query, params) =>
  Api.VOLUNTEERS.SEARCH.post({
    params,
    data: query,
  })
    .then(resp => {
      return Decorator.Contacts.parseList(resp);
    })
    .catch(() => ({ items: [] }));

const ProspectsSourceConfig = {
  actions: {
    loading: true,
    loadingInfinite: true,
    startFetch: true,
    fetchedProspects(pool_id, prospects) {
      this.require(_.isNumber(pool_id), "pool_id should be a number");
      this.require(_.isObject(prospects), "prospects should be a paged object");
    },
    fetchedInfiniteProspects(pool_id, prospects) {
      this.require(_.isNumber(pool_id), "pool_id should be a number");
      this.require(_.isObject(prospects), "prospects should be a paged object");
    },
    filter(pool_id, filters, givingCategoryLabel) {
      this.require(_.isNumber(pool_id), "pool_id should be a number");
      this.require(_.isObject(filters), "filters should be an object");
    },
    paginate(pool_id, page, givingCategoryLabel) {
      this.require(_.isNumber(page), "page should be a number");
      this.require(_.isNumber(pool_id), "pool_id should be a number");
    },
    infiniteLoad(pool_id, givingCategoryLabel) {
      this.require(_.isNumber(pool_id), "pool_id should be a number");
    },
  },

  api: {
    // Triggers a round-trip call to pull pagination and filters from store
    fetch(pool_id) {
      this.actions.startFetch(pool_id);
    },

    fetchInfiniteWithQuery(pool_id, query, params = { limit: 10, offset: 0 }) {
      if (!Utils.isNumber(pool_id)) return;
      this.actions.loadingInfinite(true);
      fetchContactsWithSearch(query, _.extend({}, params, { pool_id }))
        .then(response => {
          const prospects = Utils.cloneData(response);
          this.actions.fetchedInfiniteProspects(pool_id, prospects);
          this.actions.loadingInfinite(false);
        })
        .catch(error => {
          ErrorLogger.captureRequest("fetch infinite w/query error", error);
          this.actions.loadingInfinite(false);
        });
    },

    fetchWithQuery(pool_id, query, params = { limit: 10, offset: 0 }) {
      if (!Utils.isNumber(pool_id)) return;
      this.actions.loading(true);
      fetchContactsWithSearch(query, _.extend({}, params, { pool_id }))
        .then(response => {
          const prospects = Utils.cloneData(response);
          prospects.items = !_.isEmpty(prospects) ? _.first(prospects.items, 10) : [];

          this.actions.fetchedProspects(pool_id, prospects);
          this.actions.loading(false);
        })
        .catch(error => {
          ErrorLogger.captureRequest("fetch w/query error", error);
          this.actions.loading(false);
        });
    },

    filter(pool_id, filters = {}, givingCategoryLabel) {
      this.actions.filter(Utils.toNumber(pool_id), Utils.compactObject(filters), givingCategoryLabel);
    },

    paginate(pool_id, page, givingCategoryLabel) {
      this.actions.paginate(pool_id, page, givingCategoryLabel);
    },

    infiniteLoad(pool_id, givingCategoryLabel) {
      this.actions.infiniteLoad(pool_id, givingCategoryLabel);
    },
  },
};

const ProspectsSource = createSource("NewProspectsSource", ProspectsSourceConfig);
const MyProspectsSource = createSource("MyProspectsSource", { ...ProspectsSourceConfig, assigned_to_me: true });
export { ProspectsSource, MyProspectsSource };
