import queryString from "query-string";
import Config from "config/env";
import Tracker from "./tracker";
import MagicLinkSource from "core/apps/auth/magic-link-source";

export default {
  goToInitialRoute() {
    return (window.location.pathname = "/");
  },

  goToMobile(path, params) {
    let pathname, query_params;
    if (path == null) {
      path = "";
    }
    if (params && !(params != null ? params.match(/\?/) : undefined)) {
      query_params = `?${params}`;
    } else {
      query_params = params || (window.location != null ? window.location.search : undefined) || "";
    }

    if (path.match(/^\//)) {
      pathname = path.slice(1);
    } else {
      pathname = path;
    }

    return (window.location = `etvolunteers://${pathname}${query_params}`);
  },

  getQueryParams(router_params) {
    if (router_params) {
      return queryString.parse(router_params.location != null ? router_params.location.search : undefined);
    } else {
      return queryString.parse(window.location.search);
    }
  },

  getRouteParams(router_params) {
    if (router_params == null) {
      router_params = {};
    }
    return router_params.match != null ? router_params.match.params : undefined;
  },

  getAccountsUrl() {
    let new_path;
    if (Config.isLocal) {
      new_path = __guard__(
        __guard__(window.location.origin.replace("volunteer", "accounts"), x1 => x1.split(/\:\d{4}$/)),
        x => x[0],
      );
      new_path += `:${3016}`;
    } else if (Config.isStageData) {
      new_path = "https://stage-accounts.evertrue.com";
    } else {
      new_path = "https://accounts.evertrue.com";
    }
    return new_path;
  },

  loginWithMagicToken(oid, magic_token) {
    if (magic_token) {
      Tracker.send("auth_started", { type: "new_session" });
      MagicLinkSource.setMagicToken(magic_token);

      let new_path = this.getAccountsUrl();
      new_path += `/volunteers/login/magic/${magic_token}`;
      if (oid && Config.isDevelopment) {
        new_path += `?oid=${oid}&redirect=${window.location.origin}`;
      } else if (oid) {
        new_path += `?oid=${oid}`;
      } else if (Config.isDevelopment) {
        new_path += `?redirect=${window.location.origin}`;
      }
      return (window.location = new_path);
    }
  },
};

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
