import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import UtilMethods from "utils/util-methods";
import RespondTo from "components/respond-to";
import useGate from "core/apps/gates/use-gate";

const PROPTYPES = {
  giving: PropTypes.object,
};

const DEFAULT_PROPS = {
  giving: {},
};

const renderKpi = (label, data, key) => {
  const amount = data[`${key}_amount`];
  const date = data[`${key}_date`];

  // Make customer data prettier
  let designation = data[`${key}_label`];
  designation = designation ? designation.split(",").join(", ") : null;

  return (
    <div className="profile-donor-kpis--item">
      <div>
        <div>{label} </div>
        <strong>{amount ? UtilMethods.currencyFormatter(amount) : "N/A"}</strong>
        <div className="profile-donor-kpis--meta">
          {date ? moment(new Date(date)).format("MMM DD, YYYY") : <div className="text-placeholder">No Date</div>}
        </div>
        <div className="profile-donor-kpis--designation">{designation}</div>
      </div>
    </div>
  );
};

const ProfileDonorKpis = ({ giving }) => {
  const has_imported_pledges = useGate({ gate: "pledge_balance_field" });
  let pattern = giving.lybunt ? "LYBUNT" : undefined;
  if (giving.sybunt && !pattern) {
    pattern = "SYBUNT";
  }

  return (
    <RespondTo className="profile-donor-kpis">
      {renderKpi("Last Gift", giving, "last_gift")}
      {renderKpi("Largest Gift", giving, "largest_gift")}

      <div className="profile-donor-kpis--item">
        <div>
          <div>Lifetime Giving</div>
          <strong>{UtilMethods.currencyFormatter(giving.lifetime_amount)}</strong>
          <div className="profile-donor-kpis--meta">{pattern}</div>
        </div>
      </div>

      {has_imported_pledges && (
        <div className="profile-donor-kpis--item">
          <div>
            <div>Pledge Balance</div>
            <strong>{UtilMethods.currencyFormatter(giving.total_pledge_balance)}</strong>
            <div className="profile-donor-kpis--meta">In unpaid pledges</div>
          </div>
        </div>
      )}
    </RespondTo>
  );
};

ProfileDonorKpis.propTypes = PROPTYPES;
ProfileDonorKpis.defaultProps = DEFAULT_PROPS;
export default ProfileDonorKpis;
