/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";

import Api from "base/api";
import Cookies from "js-cookie";
import { createSource } from "@evertrue/et-flux";
import ErrorLogger from "utils/error-logger";
import Tracker from "utils/tracker";
import Config from "config/env";
import Navigator from "utils/navigator";
import Storage from "utils/storage";
import { wipeSession } from "core/base/session-utils";

// ACCOUNTS_TOKEN is a Cookie from accounts.evertrue.com
// Should be used once on login or 2fa re-up and then removed.
// SESSION_KEY is stored in localStorage for this application.

const SESSION_KEY = "token";
const ACCOUNTS_TOKEN = `${Config.dataEnv.toLowerCase()}_etauth`;

const _trackError = function() {
  if (Cookies.get(ACCOUNTS_TOKEN)) {
    Tracker.send("auth_failed", { type: "new_session" });
  }
};

const _trackSuccess = function() {
  if (Cookies.get(ACCOUNTS_TOKEN)) {
    Tracker.send("auth_success", { type: "new_session" });
  } else {
    Tracker.send("user_auth");
  }
};

export default createSource("SessionSource", {
  actions: {
    loading: true,
    logout: true,
    setSession(session, is_registration_session) {
      this.require(_.isObject(session) || _.isEmpty(session), "session should be object or undefined");
      this.require(
        _.isBoolean(is_registration_session) || _.isUndefined(is_registration_session),
        "is_registration_session should be a boolean or undefined",
      );
    },
    setImpersonatedUser(user) {
      this.require(_.isObject(user), "user should be an object");
    },
  },

  setSession(session, is_registration_session) {
    if (session == null) {
      session = {};
    }
    Api.pushTokenToRefreshPromise(session.token);
    if (session.token) {
      Storage.set(SESSION_KEY, session.token);
    } else {
      Storage.remove(SESSION_KEY);
    }
    this.actions.setSession(session, is_registration_session);
  },

  createSessionFromCookie() {
    Api.AUTH.SKIFF.post({
      headers: {
        Authorization: null,
        "Authorization-Provider": null,
      },
      credentials: "include",
      bypassDefaultResponse: true,
    });
  },

  api: {
    mfaRequired() {
      let new_path = Navigator.getAccountsUrl();
      new_path += "/volunteers/auth/multifactor";
      return (window.location = new_path);
    },

    setApiSessionFromStorage() {
      const token = Storage.get(SESSION_KEY);
      Api.pushTokenToRefreshPromise(token);
    },

    setRegistrationSession(session) {
      this.setSession(session, true);
    },

    setRefreshedSession(session) {
      this.setSession(session);
    },

    fetchSessionFromCookie() {
      const token = Cookies.get(ACCOUNTS_TOKEN) || Storage.get(SESSION_KEY);
      Api.pushTokenToRefreshPromise(token);
      this.actions.loading(true);
      Api.AUTH.SESSION.get({
        bypassDefaultResponse: true,
        headers: {
          "Authorization-Provider": "EvertrueAuthToken",
        },
        success: session => {
          this.setSession(session);
          this.actions.loading(false);
          Cookies.remove(ACCOUNTS_TOKEN);
          _trackSuccess();

          // after the session has loaded, check if anyone is being impersonated
          // (allows impersonation to persist if the page is refreshed)
          return this.fetchImpersonationFromCache();
        },
        error: response => {
          // Making this more explicit instead of relying on the api error handling
          if ((response != null ? response.status : undefined) === 401) {
            this.createSessionFromCookie()
              .then(session => {
                this.setSession(session);
                this.actions.loading(false);
                _trackSuccess();
              })
              .catch(() => {
                this.actions.loading(false);
                ErrorLogger.captureRequest("Auth Error: createSessionFromCookie", response);
                _trackError();
              });
          } else {
            this.actions.loading(false);
            ErrorLogger.captureRequest("Auth Error: fetchSessionFromCookie", response);
            _trackError();
          }
        },
      });
    },

    logout() {
      wipeSession();
      Api.AUTH.SKIFF.delete({
        credentials: "include",
        bypassDefaultResponse: true,
        success: () => {
          this.setSession({});
          this.actions.logout();
        },
        error: xhr => {
          this.setSession({});
          this.actions.logout();
          ErrorLogger.captureRequest("Auth Error: logout", xhr);
        },
      });
    },

    startImpersonation(user_being_impersonated) {
      Api.set({ impersonate_user_id: user_being_impersonated.id });
      this.actions.setImpersonatedUser(user_being_impersonated);
    },

    endImpersonation() {
      Api.set({ impersonate_user_id: undefined });
      this.actions.setImpersonatedUser({});
    },

    fetchImpersonationFromCache() {
      const user = JSON.parse(Storage.get("impersonated_user"));
      if (!_.isEmpty(user)) {
        this.actions.setImpersonatedUser(user);
        Api.set({ impersonate_user_id: user.id });
      }
    },
  },
});
