import React, { Component } from "react";
import PropTypes from "prop-types";
import Api from "base/api";
import ErrorLogger from "utils/error-logger";

export const DNAContext = React.createContext();

class DNAProvider extends Component {
  static propTypes = {
    initialKeys: PropTypes.arrayOf(PropTypes.string),

    children: PropTypes.any,
  };

  static defaultProps = {
    children: () => null,
  };

  state = {
    keys: {},
  };

  is_mounted = null;

  componentDidMount() {
    this.is_mounted = true;
    this.props.initialKeys.forEach(this.fetchDNAValue);
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  clearGateState = () => {
    this.setState({ gates: {} });
  };

  fetchDNAValue = key => {
    if (!this.state.keys[key]) {
      Api.DNA.SETTING.get({
        urlArgs: { setting_key: key },
        success: (resp = {}) => {
          if (!this.is_mounted) return;
          const setting_value = resp.settings[key].value;
          this.setState(({ keys }) => ({ keys: { ...keys, [key]: setting_value } }));
        },
      });
    }
  };

  render() {
    return <DNAContext.Provider value={{ keys: this.state.keys }}>{this.props.children}</DNAContext.Provider>;
  }
}

export default DNAProvider;
