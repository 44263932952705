/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { createComponent } from "@evertrue/et-flux";
import { div } from "react-dom-factories";

export default createComponent("TagFilters", {
  propTypes: {
    value: PropTypes.array,
    multiple: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
  },

  getDefaultProps() {
    return { multiple: false };
  },

  handleChange(option) {
    let current_value = this.props.value || [];
    if (_.contains(current_value, option.value)) {
      current_value = _.without(current_value, option.value);
    } else {
      if (this.props.multiple) {
        current_value.push(option.value);
      } else {
        current_value = [option.value];
      }
    }
    return typeof this.props.onChange === "function" ? this.props.onChange(current_value) : undefined;
  },

  render() {
    return div(
      { className: "tag-filters" },
      _.map(this.props.options, option => {
        const is_selected = _.contains(this.props.value, option.value);
        return div(
          {
            key: option.value,
            className: classNames("tag-filters--item", { "is-selected": is_selected }),
            onClick: () => this.handleChange(option),
          },
          option.label,
        );
      }),
    );
  },
});
