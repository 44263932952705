import _ from "underscore";
import React from "react";
import Decorator from "@evertrue/client-decorator";
import classNames from "classnames";
import { createComponent } from "@evertrue/et-flux";
import { div, a } from "react-dom-factories";
import OrgStore from "apps/auth/stores/org-store";
import SessionSource from "apps/auth/sources/session-source";
import SessionStore from "apps/auth/stores/session-store";
import DnaStore from "core/apps/dna/dna-store";
import DnaSource from "core/apps/dna/dna-source";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import UserDetails from "apps/auth/components/user-details";
import Tracker from "utils/tracker";
import Config from "config/env";
import { Avatar, Icon, OutsideClick, ModalTriggerDeprecated } from "components/et-factories";
import { Link } from "components/router";
import PropTypes from "prop-types";

const Datalist = React.createFactory(require("components/datalist").default);
const ImpersonationModal = React.createFactory(require("./impersonation-modal").default);

export default createComponent("UserHeaderDropdown", {
  propTypes: {
    user: PropTypes.object,
  },

  getInitialState() {
    return { open: false };
  },

  componentDidMount() {
    DnaSource.fetch("SUPPORT_EMAIL");
    return DnaSource.fetch("RESOURCES");
  },

  registerStores() {
    this.on(OrgStore, () => ({
      org: OrgStore.getCurrentOrg(),
    }));

    this.on(SessionStore, () => ({
      user_avatar: SessionStore.getUserAvatar(),
      is_impersonating: SessionStore.getIsImpersonating(),
    }));

    this.on(DnaStore, () => ({
      support_email: DnaStore.getDnaKey("SUPPORT_EMAIL"),
      resource_url: DnaStore.getDnaKey("RESOURCES"),
    }));

    return this.on(ResponsiveStore, () => ({ is_mobile: ResponsiveStore.isMobile() }));
  },

  handleToggle() {
    return this.setState({ open: !this.state.open });
  },

  handleOutsideClick(e) {
    e.stopPropagation();
    e.preventDefault();
    return this.setState({ open: false });
  },

  handleLogout() {
    Tracker.send("auth_logout");
    SessionSource.logout();
  },

  renderAction(label, icon, onClick) {
    return a(
      {
        href: "#",
        className: "user-header-dropdown--action",
        onClick: () => {
          this.handleToggle();
          return onClick();
        },
      },
      Icon({ icon }),
      label,
    );
  },

  render() {
    let affil;
    const org = this.state.org || {};
    const user = this.props.user || {};
    const avatar = this.state.user_avatar;
    const has_many_orgs = user.super_user || user.affiliations.length > 1;

    return div(
      { className: "user-header-dropdown" },
      div(
        {
          className: "user-header-dropdown--header",
          onClick: this.handleToggle,
        },

        this.props.isMobile
          ? Icon({ icon: "more-vert", className: "user-header-dropdown--mobile" })
          : div(
              { className: "fs-exclude-data", style: { whiteSpace: "nowrap" } },
              Avatar({ url: avatar, width: 40, height: 40, name: user.name }),
              this.props.user.name,
              Icon({
                isAfterText: true,
                icon: this.state.open ? "chevron-up" : "chevron-down",
              }),
            ),
      ),

      this.state.open
        ? OutsideClick(
            {
              className: classNames("user-header-dropdown--body", { "is-mobile": this.props.isMobile }),
              onOutsideClick: this.handleOutsideClick,
            },

            div(null),
            UserDetails({ user, avatar }),

            Decorator.User.isInternal(user)
              ? div(
                  { className: "user-header-dropdown--dev-help" },
                  Datalist({ label: "User ID: " }, user != null ? user.id : undefined),

                  org.id ? Datalist({ label: "Organization: " }, `${org.id} - ${org.slug}`) : undefined,

                  org.id && (affil = Decorator.User.getAffiliationFor(user, org != null ? org.id : undefined))
                    ? div(
                        null,
                        Datalist(
                          { label: "Roles: " },
                          user.super_user ? "Super User, " : undefined,
                          __guard__(_.pluck(Decorator.User.getRoles(user, org.id), "name"), (x) => x.join(", ")),
                        ),

                        Datalist(
                          { label: "Contact ID: " },
                          a(
                            {
                              href: `${Config.evertrueUrl}/contact/${affil.contact_id}?oid=${org.id}`,
                              target: "_blank",
                            },
                            affil.contact_id,
                          ),
                        ),
                      )
                    : undefined,
                )
              : undefined,

            div(
              { className: "user-header-dropdown--actions" },
              has_many_orgs && !this.state.is_impersonating
                ? Link(
                    { to: "/auth/org", className: "user-header-dropdown--action" },
                    Icon({ icon: "switch" }),
                    "Change Organization",
                  )
                : undefined,

              this.state.support_email
                ? a(
                    {
                      href: `mailto:${this.state.support_email}`,
                      className: "user-header-dropdown--action",
                      onClick: () => this.handleToggle(),
                    },
                    Icon({ icon: "email" }),
                    "Contact",
                  )
                : undefined,

              a(
                {
                  href: "https://evertrue.forumbee.com/category/volunteer-by-evertrue",
                  target: "_blank",
                  className: "user-header-dropdown--action",
                  onClick: () => this.handleToggle(),
                },
                Icon({ icon: "help" }),
                "Help",
              ),

              this.state.resource_url
                ? a(
                    {
                      href: this.state.resource_url,
                      target: "_blank",
                      className: "user-header-dropdown--action",
                      onClick: () => this.handleToggle(),
                    },
                    Icon({ icon: "other-interaction" }),
                    "Volunteer Resources",
                  )
                : undefined,

              user.super_user && !this.state.is_impersonating
                ? ModalTriggerDeprecated(
                    {
                      modal: ImpersonationModal({ org, user, is_mobile: this.state.is_mobile }),
                      onClick: () => this.handleToggle(),
                    },
                    div(
                      { className: "user-header-dropdown--action" },
                      Icon({ icon: "account-switch" }),
                      "Impersonate User",
                    ),
                  )
                : undefined,

              this.renderAction("Sign Out", "logout", this.handleLogout),
            ),
          )
        : undefined,
    );
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
