import _ from "underscore";
import Api from "base/api";
import { createSource } from "@evertrue/et-flux";
import ErrorLogger from "utils/error-logger";

const DnaSource = createSource("DnaSource", {
  actions: {
    fetchFromAlias: true,
    fetchedFromServer(key, value) {
      this.require(_.isString(key), "key should be a string");
    },
  },

  api: {
    fetch(alias) {
      this.actions.fetchFromAlias(alias);
    },

    fetchFromServer(key) {
      Api.DNA.SETTING.get({
        urlArgs: { setting_key: key },
        success: (resp = {}) => {
          const setting_value = resp.settings[key].value;
          this.actions.fetchedFromServer(key, setting_value);
        },
        error: error => {
          ErrorLogger.captureRequest("fetch dna settings", error);
        },
      });
    },
  },
});

export default DnaSource;
