import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import PropertySource from "./property-source";
import FilterSetup from "core/apps/filters/filter-setup";

const _formatCustomValues = (key, custom_field_list) => {
  const values = [];
  _.each(custom_field_list, obj => values.push(obj[key] != null ? obj[key].value : undefined));
  return values;
};

const PropertyStore = createStore("PropertyStore", {
  getInitialState() {
    return { custom_fields: [] };
  },

  registerActions() {
    this.on(PropertySource.actions.fetchedCustomFields, custom_fields => {
      this.setState({ custom_fields });
      // set the custom fields for filters
      FilterSetup.setCustomFilters(custom_fields);
    });
  },

  getDataByName(name) {
    return _.findWhere(this.getState("custom_fields"), { name });
  },

  api: {
    getCustomDataForContact(contact) {
      const custom_field_keys = _.pluck(this.getState("custom_fields"), "list_name");
      const filtered_contact_data = _.pick(contact, custom_field_keys);
      return _.compact(
        _.map(filtered_contact_data, arr => {
          if (arr.length) {
            const keys = _.keys(arr[0]);
            const key = !_.isEmpty(keys) ? keys[0] : null;
            const field_data = this.getDataByName(key);
            if (!_.isEmpty(field_data)) {
              const values = _formatCustomValues(key, arr);
              return { key, label: field_data.description, type: field_data.data_type, values };
            }
          }
        }),
      );
    },
  },
});

export default PropertyStore;
