import _ from "underscore";
import moment from "moment";
import SessionStore from "session-store";
import OrgStore from "org-store";
import Decorator from "@evertrue/client-decorator";
import Utils from "../../utils/utils";

const { Query, Parameter, Property, ChildDoc } = Decorator.Search;

const _getCustomQueryType = (type) => {
  switch (type) {
    case "string":
      return "contains";
    case ("number", "currency"):
      return "coerce";
    case ("datetime", "date_string"):
      return "object";
    case "boolean":
      return "equals";
    default:
      return "equals";
  }
};

const _getComponentType = (type) => {
  switch (type) {
    case "string":
      return "string";
    case "number":
      return "number_range";
    case "currency":
      return "currency_range";
    case ("datetime", "date_string"):
      return "date_range";
    case "boolean":
      return "boolean";
    default:
      return null;
  }
};

let filter_config = {
  full_name: {
    query(text) {
      return Decorator.Filters.getQuery({ full_name: text });
    },
  },

  year: {
    key: "year",
    label: "Class Year",
    component_type: "year_range",
    query(val) {
      return Decorator.Filters.getQuery({ year: val });
    },
    sort: 2,
    show_tab: "both",
  },

  last_gift_date: {
    key: "last_gift_date",
    label: "Last Gift Date",
    component_type: "date_range",
    query({ gte, lte }, givingCategoryLabel) {
      const formatted_gte = gte ? moment(gte, "MM/DD/YYYY").format("YYYY-MM-DD") : "";
      const formatted_lte = lte ? moment(lte, "MM/DD/YYYY").format("YYYY-MM-DD") : "now";

      const date_range_obj = {
        ...(formatted_gte && { gte: formatted_gte }),
        ...(formatted_lte && { lte: formatted_lte }),
      };

      if (!givingCategoryLabel) {
        return Query([Parameter("must", [Property("giving.last_gift_date", date_range_obj, { type: "object" })])]);
      }

      return Query([
        Parameter(
          "must",
          [
            Property("label.untouched", givingCategoryLabel, {
              type: "equals",
              parent: "giving_categories",
              parent_type: "instance",
            }),
            !_.isEmpty(date_range_obj) &&
              Property("last_gift_date", date_range_obj, {
                type: "object",
                parent: "giving_categories",
                parent_type: "instance",
              }),
          ].filter(Boolean),
        ),
      ]);
    },
    sort: 3,
    show_tab: "both",
  },

  assigned: {
    key: "assigned",
    label: "Prospect Status",
    component_type: "tags",
    query(is_assigned) {
      return Query([
        ChildDoc(
          "assignment",
          Query([
            Parameter("must", [Property("solicitor_contact_id", Utils.jsonParse(is_assigned), { type: "exists" })]),
          ]),
        ),
      ]);
    },
    sort: 1,
    show_tab: "prospects",
    options: [
      { value: "true", label: "Assigned" },
      { value: "false", label: "Unassigned" },
    ],
  },

  stages: {
    key: "stages",
    label: "Stages",
    component_type: "string",
    query(values) {
      return Query([
        ChildDoc(
          "assignment",
          Query([
            Parameter("must", [Property("assignment_stage.untouched", _.pluck(values, "value"), { type: "contains" })]),
          ]),
        ),
      ]);
    },
    sort: 8,
    show_tab: "both",
  },

  industry: {
    key: "industry",
    label: "Industry",
    component_type: "string",
    facets: {
      fields: ["employments.industry.untouched"],
    },
    query(values) {
      return Decorator.Filters.getQuery({
        industry: {
          customer: _.pluck(values, "value"),
        },
      });
    },
    sort: 5,
    show_tab: "both",
  },

  activities: {
    key: "activities",
    label: "Activities",
    component_type: "string",
    query(values) {
      return Decorator.Filters.getQuery({ activities: _.pluck(values, "value") });
    },
    sort: 6,
    show_tab: "both",
  },

  athletics: {
    key: "athletics",
    label: "Athletics",
    component_type: "string",
    query(values) {
      return Decorator.Filters.getQuery({ athletics: _.pluck(values, "value") });
    },
    sort: 7,
    show_tab: "both",
  },

  // This doesn't actually show up in the filters, but is used to make
  // the assignments tab
  assigned_to_me: {
    key: "assigned_to_me",
    label: "Assigned to me",
    query(value) {
      const param = value ? "must" : "must_not";
      const contact_id = SessionStore.getUserContactId(OrgStore.getCurrentOrg().id);
      return Query([
        ChildDoc(
          "assignment",
          Query([Parameter(param, [Property("solicitor_contact_id", contact_id, { type: "equals" })])]),
        ),
      ]);
    },
  },

  pledge_balance_range: {
    key: "pledge_balance_range",
    label: "Pledge Balance",
    component_type: "number_range",
    query(value, givingCategoryLabel) {
      if (!givingCategoryLabel) {
        return Query([Parameter("must", [Property("giving.total_pledge_balance", value, { type: "coerce" })])]);
      }
      return Query([
        Parameter(
          "must",
          [
            Property("label.untouched", givingCategoryLabel, {
              type: "equals",
              parent: "giving_categories",
              parent_type: "instance",
            }),
            Property("total_pledge_balance", value, {
              type: "coerce",
              parent: "giving_categories",
              parent_type: "instance",
            }),
          ].filter(Boolean),
        ),
      ]);
    },
    show_tab: "assignments",
    show_gate: "pledge_balance_field",
    sort: 8,
  },

  pledge_balance: {
    key: "pledge_balance",
    label: "Has Pledge Balance?",
    component_type: "boolean",
    query(value, givingCategoryLabel) {
      const param = value[0] ? "must" : "must_not";
      if (!givingCategoryLabel) {
        return Query([Parameter(param, [Property("giving.total_pledge_balance", { gt: 0 }, { type: "coerce" })])]);
      }
      return Query([
        Parameter(
          param,
          [
            Property("label.untouched", givingCategoryLabel, {
              type: "equals",
              parent: "giving_categories",
              parent_type: "instance",
            }),
            Property(
              "total_pledge_balance",
              { gt: 0 },
              { type: "coerce", parent: "giving_categories", parent_type: "instance" },
            ),
          ].filter(Boolean),
        ),
      ]);
    },
    show_tab: "assignments",
    show_gate: "pledge_balance_field",
    sort: 9,
  },

  recurring_gift: {
    key: "recurring_gift",
    label: "Has Recurring Gift?",
    component_type: "boolean",
    query(value, givingCategoryLabel) {
      const param = value[0] ? "must" : "must_not";
      if (!givingCategoryLabel) {
        return Query([Parameter(param, [Property("giving.has_recurring_payments", true, { type: "equals" })])]);
      }
      return Query([
        Parameter(
          param,
          [
            Property("label.untouched", givingCategoryLabel, {
              type: "equals",
              parent: "giving_categories",
              parent_type: "instance",
            }),
            Property("has_recurring_payments", true, {
              type: "equals",
              parent: "giving_categories",
              parent_type: "instance",
            }),
          ].filter(Boolean),
        ),
      ]);
    },
    show_tab: "assignments",
    show_gate: "recurring_payment_field",
    sort: 10,
  },

  key_prospect: {
    key: "key_prospect",
    label: "Is Key Prospect?",
    component_type: "boolean",
    query(value) {
      const param = value[0] ? "must" : "must_not";
      return Query([
        ChildDoc("assignment", Query([Parameter(param, [Property("key_prospect", true, { type: "equals" })])])),
      ]);
    },
    show_tab: "both",
    sort: 11,
  },
};

const FilterSetup = {
  // move to client dec
  formatPropName(prop) {
    let name = `${prop.list_name}.${prop.name}`;
    if (prop.data_type === "string") {
      name += ".untouched";
    }
    return name;
  },

  setCustomFilters(custom_props) {
    const custom_config = {};
    _.each(custom_props, (prop) => {
      const key_name = this.formatPropName(prop);
      custom_config[key_name] = {
        key: key_name,
        label: prop.description,
        component_type: _getComponentType(prop.data_type),
        query_type: _getCustomQueryType(prop.data_type),
        is_custom: true,
        ...prop,
      };
    });

    filter_config = _.extend({}, filter_config, custom_config);
  },

  getConfig() {
    return filter_config;
  },
};

export default FilterSetup;
