import PropTypes from "prop-types";
import React from "react";
import Decorator from "@evertrue/client-decorator";
import { createComponent } from "@evertrue/et-flux";
import ProfileSection from "apps/profile/components/profile-section";
import ProfileSectionItem from "apps/profile/components/profile-section-item";
import ProfileDonorHistory from "apps/profile/components/profile-donor-history";
import ProfileDonorKpis from "apps/profile/components/profile-donor-kpis";
import ProfileAmount from "apps/profile/components/profile-ask-amount";
import AssignmentsResolver from "apps/prospects/resolvers/assignments-resolver";
import DnaStore from "core/apps/dna/dna-store";
import { ModalTrigger2, Icon, Button } from "@evertrue/et-components";
import CommittedAmountsModal from "apps/profile/components/committed-amount-modal";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import IsGated from "core/apps/gates/is-gated";

export default createComponent("ProfileGivingController", {
  propTypes: {
    contact: PropTypes.object,
    pool: PropTypes.object,
    canUpdateStagesAndAmounts: PropTypes.bool,
  },

  getDefaultProp() {
    return {
      contact: {},
      pool: {},
      canUpdateStagesAndAmounts: false,
    };
  },

  registerStores() {
    this.on(DnaStore, () => {
      return { fiscal_year: DnaStore.getFiscalYear() };
    });
    this.on(ResponsiveStore, () => {
      return { isMobile: ResponsiveStore.isMobile() };
    });
  },

  render() {
    const { pool, contact, canUpdateStagesAndAmounts } = this.props;
    const giving = Decorator.Giving.getVolunteerProfileGiving(pool, contact, this.state.fiscal_year) || {};

    return (
      <div className="profile-giving">
        <AssignmentsResolver
          contactIds={[this.props.contact.id]}
          pool={this.props.pool}
          render={({ assignments }) => {
            const assignment = assignments[this.props.contact.id] || {};

            return (
              <div className="profile-giving--amounts-row">
                <ProfileSection label="Suggested Ask Amount" className="profile-giving--ask-amount-section">
                  <ProfileSectionItem icon="volunteer-ask-amount" className="profile-giving--ask-amount">
                    <ProfileAmount amount={assignment.ask_amount_in_cents} />
                  </ProfileSectionItem>
                </ProfileSection>
                <IsGated gate="vms_committed_amounts">
                  <ProfileSection label="Committed Amount" className="profile-giving--committed-amount-section">
                    <ProfileSectionItem icon="committed" className="profile-giving--committed-amount">
                      <ProfileAmount
                        amount={
                          assignment.committed_amount_in_dollars
                            ? assignment.committed_amount_in_dollars * 100
                            : assignment.committed_amount_in_dollars
                        }
                      />
                      {canUpdateStagesAndAmounts ? (
                        <div>
                          <ModalTrigger2
                            disableAnimation={this.state.isMobile}
                            contentClassName={this.state.isMobile ? " profile-giving--committed-modal-mobile" : ""}
                            trigger={({ open }) => (
                              <Button type="simple" onClick={open}>
                                Update Amount
                              </Button>
                            )}
                          >
                            {({ close }) => (
                              <CommittedAmountsModal
                                isMobile={this.state.isMobile}
                                pool={this.props.pool}
                                contact={this.props.contact}
                                assignment={assignment}
                                close={close}
                              />
                            )}
                          </ModalTrigger2>
                        </div>
                      ) : null}
                    </ProfileSectionItem>
                  </ProfileSection>
                </IsGated>
              </div>
            );
          }}
        />

        <div className="profile-section">
          <div className="profile-section--subheader giving-subheader">
            Giving History
            <IsGated gate="recurring_payment_field">
              {giving.has_recurring_payments && (
                <span className="giving-subheader--wrap">
                  <Icon icon="recurring" className="giving-subheader--icon" />
                  <span>Recurring Payments Set</span>
                </span>
              )}
            </IsGated>
          </div>

          <ProfileSectionItem icon="capacity">
            <ProfileDonorKpis giving={giving} />
          </ProfileSectionItem>
        </div>

        <ProfileSection label="Last Five Years of Giving">
          <ProfileSectionItem>
            <ProfileDonorHistory history={giving.history} />
          </ProfileSectionItem>
        </ProfileSection>
      </div>
    );
  },
});
