import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import { OverlayActions, Button } from "@evertrue/et-components";
import MiniProspectCard from "apps/profile/components/mini-prospect-card";
import ResponsiveModal from "components/responsive-modal";
import RespondTo from "components/respond-to";

class ViewAssignment extends React.Component {
  static propTypes = {
    contact: PropTypes.object,
    secondary: PropTypes.object,
    solicitor: PropTypes.object,
    onUnassign: PropTypes.func,
    onUnmount: PropTypes.func,
  };

  static defaultProps = {
    onUnassign: _.noop,
    onUnmount: _.noop,
  };

  state = {
    is_managing: false,
  };

  handleManage = (e) => {
    e.preventDefault();
    this.setState({ is_managing: true });
  };

  handleRemoveSheet = () => {
    OverlayActions.removeOverlay();
    this.props.onUnmount();
  };

  handleUnassign = () => {
    OverlayActions.removeOverlay();
    this.props.onUnassign();
  };

  renderAssignee = () => {
    const { solicitor } = this.props;

    return (
      <RespondTo className="manage-assignment-modal">
        <MiniProspectCard contact={solicitor} label="Volunteer Solicitor">
          <Button type="simple" onClick={this.handleManage}>
            Manage Assignment
          </Button>
        </MiniProspectCard>
      </RespondTo>
    );
  };

  renderManage = () => {
    const { solicitor, contact, secondary } = this.props;

    return (
      <RespondTo className="manage-assignment-modal" to={["desktop", "tablet", "mobile"]}>
        <MiniProspectCard contact={contact} secondary={secondary} label="Prospect" />
        <div className="manage-assignment-modal--assignment-section">
          <div className="manage-assignment-modal--arrow-section">
            {_.map(_.range(0, 6), (n) => {
              return <div className="manage-assignment-modal--arrows" key={n} />;
            })}
          </div>
          <div className="manage-assignment-modal--assignment-text">Assigned To</div>
        </div>
        <MiniProspectCard contact={solicitor} label="Volunteer Solicitor" />
      </RespondTo>
    );
  };

  render() {
    const desktop = { hasMargin: true, onCancel: this.handleRemoveSheet };
    const mobile = { backgroundColor: "#FFFFFF" };
    let header = "Assigned To: ";

    if (this.state.is_managing) {
      header = "Manage Assignment";
      desktop.saveLabel = "Remove Assignment";
      desktop.onSubmit = this.handleUnassign;
      mobile.actions = [
        { label: "Cancel", onClick: this.handleRemoveSheet, className: "profile-assignment--sheet-cancel" },
        { label: "Remove Assignment", onClick: this.handleUnassign, className: "profile-assignment--sheet-remove" },
      ];
    }

    return (
      <ResponsiveModal width={660} header={header} mobileOptions={mobile} desktopOptions={desktop}>
        {this.state.is_managing ? this.renderManage() : this.renderAssignee()}
      </ResponsiveModal>
    );
  }
}

export default ViewAssignment;
