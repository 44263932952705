import _ from "underscore";
import React from "react";
import { createComponent } from "@evertrue/et-flux";
import { AdvancedCombobox, AdvancedComboboxContent, Loading } from "@evertrue/et-components";
import OrgStore from "apps/auth/stores/org-store";
import OrgSource from "apps/auth/sources/org-source";
import SessionStore from "apps/auth/stores/session-store";
import Module from "components/module";
import RespondTo from "components/respond-to";
import { Redirect, Link, withRouter } from "react-router-dom";
import SessionSource from "apps/auth/sources/session-source";
import { createScopedSession } from "core/base/session-utils";

const OrgSelectController = createComponent("OrgSelectController", {
  getInitialState() {
    return { selected: undefined };
  },

  registerStores() {
    this.on(OrgStore, () => ({
      orgs: OrgStore.getOrgs(),
      loading: OrgStore.getLoading(),
    }));

    this.on(SessionStore, () => ({ user: SessionStore.getUser() }));
  },
  componentDidMount() {
    if (this.state.user.super_user) {
      OrgSource.fetch();
    } else {
      this.state.user.affiliations.forEach(affil => {
        OrgSource.fetchById(affil.organization.id);
      });
    }
  },

  handleSet(oid) {
    OrgSource.setCurrentOrg(oid);

    createScopedSession({ oid }).then(({ organization, ...session }) => {
      OrgSource.setCurrentOrgObject(organization);
      SessionSource.setRefreshedSession(session);
    });
  },

  handleOrgChange(val) {
    this.setState({ selected: val });
  },

  render() {
    const org_options = _.map(this.state.orgs, org => ({ label: `${org.name}`, value: org.id }));

    return (
      <div className="org-selector-controller">
        {this.state.loading ? (
          <Loading />
        ) : (
          <Module className="org-selector">
            <div className="org-selector--header">Select an Organization</div>
            <div className="org-selector--text">
              {"Looks like you belong to multiple organizations! Please select which to view. "}
            </div>
            {
              <RespondTo to="mobile" className="org-selector--mobile-picker">
                {this.state.selected ? <Redirect to="/" /> : undefined}
                {
                  <AdvancedComboboxContent
                    options={org_options}
                    searchable={true}
                    mobile={true}
                    onChange={val => {
                      this.handleSet(val.value);
                      this.handleOrgChange(val);
                    }}
                  />
                }
              </RespondTo>
            }
            <RespondTo to={["tablet", "desktop"]}>
              <AdvancedCombobox
                className="org-selector--picker advanced-combobox-secondary"
                value={this.state.selected}
                options={org_options}
                searchable={true}
                onChange={this.handleOrgChange}
              />
              <Link
                to="/"
                className="org-selector--button et--button"
                onClick={() => {
                  this.handleSet(this.state.selected != null ? this.state.selected.value : undefined);
                }}
              >
                Start Prospecting
              </Link>
            </RespondTo>
          </Module>
        )}
      </div>
    );
  },
});
export default withRouter(OrgSelectController);
