import Decorator from "@evertrue/client-decorator";
import { queries } from "@evertrue/vms-insights";
import Api from "core/base/api";

export const fetchPoolById = (id, _, assigned = "all") =>
  Api.VOLUNTEERS.POOLS.get({
    urlExtend: `/${id}`,
    ...(assigned !== "all" && { params: { prospect_assigned: assigned } }),
  });

export const prospectsTotalQuery = ({ poolId, assigned }) => {
  const query = queries.prospectsTotalQuery({ poolId, assigned });
  return Api.SEARCH.ASSIGNMENTS.post({
    params: { limit: 1 },
    data: query,
  });
};

export const stageStatsQuery = ({ poolId, stages, assigned, solicitorContactId }) => {
  const query = queries.stageStatsQuery({ poolId, stages, assigned, solicitorContactId });
  return Api.SEARCH.ASSIGNMENTS.post({
    params: { limit: 1 },
    data: query,
  });
};

export const numberOfInteractions = ({ poolId, assigned, activityDateParameters }) => {
  const query = queries.numberOfInteractions({ poolId, assigned, activityDateParameters });
  return Api.SEARCH.CONTACT_NOTE.post({
    data: query,
    params: { limit: 1 },
  });
};

export const numberOfProspectsWithInteractions = ({ poolId, assigned, activityDateParameters }) => {
  const query = queries.numberOfProspectsWithInteractions({ poolId, assigned, activityDateParameters });
  return Api.VOLUNTEERS.SEARCH.post({
    data: query,
    params: { limit: 1, pool_id: poolId },
  });
};

export const activityTypeQuery = ({ poolId, assigned, activityDateParameters, solicitorUserId }) => {
  const query = queries.activityTypeQuery({ poolId, assigned, activityDateParameters, solicitorUserId });
  return Api.SEARCH.CONTACT_NOTE.post({
    data: query,
    params: { limit: 1 },
  });
};

export const fetchAnalytics = ({ poolId, name, offset, sortOrder, sortBy, limit }) => {
  const query = queries.getAnalyticsQuery({ poolId, name: name, sortOrder, sortBy });
  return Api.VOLUNTEERS.ANALYTICS.post({
    data: query,
    params: { limit, offset },
  });
};

export const fetchContactsById = poolId => ({ contactIds }) => {
  const query = queries.contactByIdsQuery({ contactIds });
  return Api.VOLUNTEERS.SEARCH.post({
    data: query,
    params: { pool_id: poolId },
  });
};

export const warmAnalyticsData = ({ poolId }) =>
  Api.VOLUNTEERS.WARM_ANALYTICS.get({
    params: { pool_id: poolId },
  });

export const fetchSessionsByContactId = ({ contactId }) => {
  const query = queries.getVolunteerSessionsQuery({ contactId });
  return Api.SEARCH.SESSIONS.post({
    data: query,
    params: { limit: 1 },
  });
};

export const fetchSolicitorsByContactAndPoolId = ({ contactIds, poolId }) =>
  Api.VOLUNTEERS.SOLICITORS_BULK.get({
    params: {
      contact_id: contactIds,
      ...(poolId ? { pool_id: poolId } : {}),
    },
  });
