import PropTypes from "prop-types";
import _ from "underscore";
import React from "react";
import Decorator from "@evertrue/client-decorator";
import { createComponent } from "@evertrue/et-flux";
import { AdvancedCombobox, ModalDeprecated, ModalConfirm, MobileSheet } from "@evertrue/et-components";
import { OverlayActions } from "@evertrue/et-components";
import ProfileInteractionStore from "core/apps/interactions/interaction-store";
import ProfileInteractionSource from "core/apps/interactions/interaction-source";
import StageSource from "core/apps/prospects/stage-source";
import ProfileUtils from "core/utils/profile-utils";

import SessionStore from "apps/auth/stores/session-store";
import OrgStore from "apps/auth/stores/org-store";
import { formatDate } from "react-day-picker/moment";
import moment from "moment";
import classnames from "classnames";
import Tracker from "utils/tracker";
import MiniProspectCardWithStageSelector from "apps/profile/components/mini-prospect-card-with-stage-selector";

import StageSelector from "apps/profile/components/stage-selector";
import RespondTo from "components/respond-to";
import DatePicker from "components/date-picker";

const _getDefaultInteraction = () => ({
  interaction_type: undefined,
  summary: "",
});

export default createComponent("InteractionForm", {
  propTypes: {
    contact: PropTypes.object,
    pool: PropTypes.object,
    interaction: PropTypes.object,
    showTypeAsTabs: PropTypes.bool,
    isMobile: PropTypes.bool,
    header: PropTypes.string,
    assignment: PropTypes.object,
    tab: PropTypes.string,
  },

  getInitialState() {
    return {
      interaction: this.props.interaction || _getDefaultInteraction(),
      has_content: false,
      date: formatDate(this.props.interaction.date_occurred),
      is_valid_date: true,
      stage: this.props.assignment.assignment_stage,
      isSubmitting: false,
    };
  },

  getDefaultProps() {
    return {
      interaction: {},
      contact: {},
      assignment: {},
      pool: {},
    };
  },

  registerStores() {
    this.on(ProfileInteractionStore, "interaction", (props) => ({
      types: ProfileInteractionStore.getInteractionTypes(),
    }));

    this.on(SessionStore, () => ({
      user: SessionStore.getUser(),
    }));

    this.on(OrgStore, () => ({
      currentOrg: OrgStore.getCurrentOrg(),
    }));
  },

  componentDidMount() {
    if (this.props.interaction != null ? this.props.interaction.text : undefined) {
      _.defer(() => this.focusTextArea());
    }
  },

  getInteractionType() {
    const type = _.findWhere(this.state.types, { value: this.state.interaction.interaction_type });
    return type || {};
  },

  hasBlankSummaryValue() {
    const prefix = __guard__(this.getInteractionType(), (x) => x.prefix) || "";
    return _.isEmpty(
      __guard__(this.state.interaction != null ? this.state.interaction.summary : undefined, (x1) =>
        x1.replace(prefix, "").trim(),
      ),
    );
  },

  handleTrack(interaction) {
    const action = interaction.id ? "update" : "create";
    Tracker.send("interaction_action", {
      type: `${action}_interaction`,
      contact_id: this.props.contact.id,
      interaction_type: interaction.interaction_type,
    });
  },

  handleChangeType(new_type) {
    const helper_text = ProfileUtils.getInteractionHelperText(new_type.value, this.props.contact);

    this.setState(function ({ interaction }) {
      return {
        interaction: _.extend(
          {},
          interaction,
          {
            interaction_type: new_type.value,
            summary: new_type.prefix ? `${new_type.prefix} ${helper_text || ""}`.trim() : undefined,
          },
          () => this.focusTextArea(),
        ),
      };
    });
  },

  handleCancel(e) {
    __guardMethod__(e, "preventDefault", (o) => o.preventDefault());
    this.textarea.value = "";
    return typeof this.props.onCancel === "function" ? this.props.onCancel() : undefined;
  },

  handleSave() {
    this.setState({ isSubmitting: true });
    const { interaction, date, stage } = this.state;
    const { pool, contact, assignment } = this.props;
    const date_ms = date ? moment(date, "MM/DD/YYYY").valueOf() : moment().valueOf();
    const interaction_to_save = _.extend({}, interaction, {
      text: this.textarea.value,
      summary: this.hasBlankSummaryValue() || !interaction.summary ? "" : interaction.summary,
      date_occurred: date_ms,
    });
    if (_.isEmpty(interaction_to_save.solicitor)) {
      const affiliation = Decorator.User.getAffiliationFor(this.state.user, this.state.currentOrg.id) || {};
      interaction_to_save.solicitor = [
        {
          user_id: this.state.user.id,
          name: this.state.user.name,
          remote_user_id: affiliation.remote_user_id,
        },
      ];
    }
    ProfileInteractionSource.save(pool.id, contact.id, interaction_to_save);
    if (pool.stage_group_id && assignment.assignment_stage !== stage) {
      StageSource.updateStage(assignment.assignment_id, stage);
    }
    this.handleTrack(interaction);
    this.handleCancel();
  },

  focusTextArea() {
    // pops up keyboard on mobile and is confusing
    if (!this.props.isMobile && this.textarea) {
      this.textarea.focus();
    }
  },

  handleRemoveSheet() {
    OverlayActions.removeOverlay();
  },

  handleMobileSheetSave() {
    this.handleSave();
    this.handleRemoveSheet();
  },

  renderModalContent() {
    const { interaction } = this.state;
    const type = this.getInteractionType();

    return (
      <RespondTo className="interaction-form">
        <MiniProspectCardWithStageSelector
          stage={this.state.stage}
          contact={this.props.contact}
          tab={this.props.tab}
          pool={this.props.pool}
          hideStageSelector={!(this.props.tab === "assignments" && this.props.pool.stage_group_id)}
          handleStageChange={(stage) => {
            this.setState({ stage });
          }}
        />

        <div className="interaction-form--container">
          <RespondTo className="interaction-form--top-row">
            <RespondTo className="interaction-form--row">
              <label className="interaction-form--label">Date:</label>
              <DatePicker
                value={this.state.date}
                onDayChange={(date) => {
                  return this.setState({ date });
                }}
                // don't allow to type
                readOnly={true}
                className={this.props.isMobile ? "is-mobile" : "s"}
                withPortal={this.props.isMobile}
              />
            </RespondTo>
            <RespondTo className="interaction-form--row">
              <label className="interaction-form--label">Activity Type:</label>
              <AdvancedCombobox
                className="et--advanced-combobox-secondary"
                value={type}
                placeholder="Select Type:"
                options={this.state.types}
                onChange={this.handleChangeType}
              />
            </RespondTo>
            <RespondTo to={["mobile"]} className="interaction-form--row">
              <label className="interaction-form--stage-label">Stage:</label>
              <StageSelector
                className="et--advanced-combobox-secondary"
                assignmentId={this.props.assignment.assignment_id}
                selectedStage={this.state.stage}
                stageGroupId={this.props.pool.stage_group_id}
                onChange={(stage) => this.setState({ stage })}
              />
            </RespondTo>
          </RespondTo>
          <label className="interaction-form--label">Notes:</label>
          {type.prefix ? (
            <div className="interaction-form--helper-input">
              <label>{`${type.prefix}:`}</label>
              <input
                type="text"
                value={(
                  (interaction.summary != null ? interaction.summary.replace(type.prefix, "") : undefined) || ""
                ).trim()}
                placeholder="(optional)"
                onChange={(e) => {
                  this.setState({
                    interaction: _.extend({}, interaction, { summary: `${type.prefix} ${e.target.value}` }),
                  });
                }}
              />
            </div>
          ) : undefined}
          <textarea
            ref={(e) => {
              this.textarea = e;
            }}
            className="interaction-form--textarea fs-exclude-data"
            defaultValue={interaction.text}
            onChange={(e) => {
              const has_value = !_.isEmpty(e.currentTarget.value);
              if (has_value !== this.state.has_content) {
                this.setState({ has_content: has_value });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.metaKey) {
                this.handleSave(e);
                this.props.onClose();
              }
            }}
          />
        </div>
      </RespondTo>
    );
  },

  render() {
    const { value = null } = this.getInteractionType();
    const {
      has_content,
      isSubmitting,
      interaction: { text: interactionText } = {},
    } = this.state;
    const {
      header,
      isMobile,
      onClose,
      onUnmount,
    } = this.props;
    const can_save = Boolean(
      value && (has_content || !!interactionText || !this.hasBlankSummaryValue()),
    );
    const button_class = classnames("interaction-form--mobile-sheet--save-button", { disabled: !can_save || isSubmitting });
    if (isMobile) {
      return (
        <MobileSheet
          className="interaction-form--mobile-sheet"
          label={header}
          onBack={this.handleRemoveSheet}
          actions={[
            {
              label: "Cancel",
              onClick: this.handleRemoveSheet,
              className: "interaction-form--mobile-sheet--cancel-button",
            },
            { label: "Save", onClick: this.handleMobileSheetSave, className: button_class, disabled: !can_save || isSubmitting },
          ]}
          backgroundColor="#FFFFFF"
        >
          {this.renderModalContent()}
        </MobileSheet>
      );
    } else {
      return (
        <ModalDeprecated onClose={onClose} onUnmount={onUnmount} width={560}>
          <ModalConfirm
            header={header}
            hasMargin={true}
            saveLabel="Save"
            disableSave={!can_save || isSubmitting}
            onSubmit={this.handleSave}
            onCancel={_.noop}
          >
            {this.renderModalContent()}
          </ModalConfirm>
        </ModalDeprecated>
      );
    }
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== "undefined" && obj !== null && typeof obj[methodName] === "function") {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
