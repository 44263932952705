import React from "react";
import classNames from "classnames";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import HeaderController from "apps/layout/controllers/header-controller";
import ImpersonationHeader from "apps/layout/components/impersonation-header";
import { OverlayController, AlertController } from "components/et-factories";
import RespondTo from "components/respond-to";
import SessionStore from "apps/auth/stores/session-store";
import OrgStore from "apps/auth/stores/org-store";
import GateProvider from "core/apps/gates/gate-context";
import PoolDataProvider from "core/apps/pool-data/pool-data-provider";

const mapStateToProps = () => ({
  is_impersonating: SessionStore.getIsImpersonating(),
  has_session: SessionStore.hasSession(),
  current_org: OrgStore.getCurrentOrg(),
});

const LayoutController = ({ is_impersonating, className, children, has_session, current_org }) => {
  const content_classnames = classNames("layout-controller--content", { "is-impersonating": is_impersonating });
  return (
    <div className={classNames("layout-controller", className)}>
      <GateProvider hasSession={has_session} currentOrg={current_org}>
        <PoolDataProvider>
          <ImpersonationHeader />
          <HeaderController />

          <RespondTo className={content_classnames}>{children}</RespondTo>

          <OverlayController />
          <AlertController />
        </PoolDataProvider>
      </GateProvider>
    </div>
  );
};

LayoutController.propTypes = {
  is_impersonating: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
};

LayoutController.defaultProps = {
  is_impersonating: false,
  children: () => null,
  className: null,
};

export default connect(LayoutController, [SessionStore, OrgStore], mapStateToProps);
