import React from "react";
import PropTypes from "prop-types";

const currentYear = new Date().getFullYear();
const toMonth = new Date(currentYear, 11);
const current_month = new Date().getMonth();

const YearMonthForm = ({ date, localeUtils, onChange }) => {
  const all_months = localeUtils.getMonths();

  const months_for_current_year = [...all_months].slice(0, current_month + 1);

  const years = [];
  for (let i = 2010; i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = e => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };
  const months = date.getFullYear() === currentYear ? months_for_current_year : all_months;
  return (
    <form className="DayPicker-Caption">
      <select className="date-range--month" name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>

      <select className="date-range--month" name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};

YearMonthForm.propTypes = {
  date: PropTypes.object,
  localeUtils: PropTypes.object,
  onChange: PropTypes.func,
};

export default YearMonthForm;
