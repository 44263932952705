import Api from "base/api";
import { createSource } from "@evertrue/et-flux";
import _ from "underscore";
import { AlertActions, alertTimeout } from "core/utils/alert-actions";
import ErrorLogger from "utils/error-logger";
import Utils from "core/utils/utils";

const ES_DELAY = 1000;

const InteractionSource = createSource("InteractionSource", {
  actions: {
    loading: true,
    paginate: true,
    filter: true,
    fetch: true,
    fetchedInteractions(contact_id, resp) {
      this.require(_.isNumber(contact_id), "contact_id should be an number");
      this.require(_.isObject(resp), "resp should be an object");
    },
    interactionUpdated(contact_id, resp) {
      this.require(_.isObject(resp), "resp should be an object");
      this.require(_.isNumber(contact_id), "contact_id should be an number");
    },
    interactionDeleted(contact_id, obj_id) {
      this.require(_.isNumber(obj_id), "obj_id should be an number");
      this.require(_.isNumber(contact_id), "contact_id should be an number");
    },
  },

  api: {
    fetch(contact_id) {
      this.actions.fetch(contact_id);
    },

    fetchWithQuery(contact_id, query, params = {}) {
      if (!contact_id) {
        return;
      }
      this.actions.loading(true);
      Api.VOLUNTEERS.INTERACTIONS_SEARCH.post({
        urlArgs: { id: contact_id },
        params,
        data: query,
        success: (resp) => {
          this.actions.fetchedInteractions(contact_id, resp);
          this.actions.loading(false);
        },
        error: (error) => {
          this.actions.loading(false);
          ErrorLogger.captureRequest("search interactions", error);
        },
      });
    },

    paginate(contact_id) {
      this.actions.paginate(contact_id);
    },

    filter(contact_id, filters) {
      this.actions.filter(contact_id, filters);
    },

    save(pool_id, contact_id, data) {
      if (data.id) {
        this.update(pool_id, contact_id, data);
      } else {
        this.create(pool_id, contact_id, data);
      }
    },

    create(pool_id, contact_id, data = {}) {
      this.actions.loading(true);
      Api.VOLUNTEERS.INTERACTIONS.post({
        urlArgs: { id: contact_id },
        params: { pool_id },
        data,
        success: (resp) => {
          AlertActions.launchToast({
            message: "Successfully saved interaction.",
            type: "success",
            timeout: alertTimeout,
          });
          Utils.wait(ES_DELAY, () => {
            this.actions.interactionUpdated(contact_id, resp);
            this.actions.loading(false);
          });
        },
        error: (xhr) => {
          ErrorLogger.captureRequest("Create Interaction Error", xhr);
          AlertActions.launchToast({
            message: "There was an error saving your interaction. Please try again",
            type: "error",
            timeout: alertTimeout,
          });
          this.actions.loading(false);
        },
      });
    },

    update(pool_id, contact_id, data) {
      this.actions.loading(true);
      Api.VOLUNTEERS.INTERACTIONS.put({
        urlExtend: `/${data.id}`,
        urlArgs: { id: contact_id },
        params: { pool_id },
        data: data,
        success: (resp) => {
          AlertActions.launchToast({
            message: "Successfully updated interaction.",
            type: "success",
            timeout: alertTimeout,
          });
          Utils.wait(ES_DELAY, () => {
            this.actions.interactionUpdated(contact_id, resp);
            this.actions.loading(false);
          });
        },
        error: (xhr) => {
          ErrorLogger.captureRequest("Update Interaction Error", xhr);
          AlertActions.launchToast({
            message: "There was an error saving your interaction. Please try again",
            type: "error",
            timeout: alertTimeout,
          });
          this.actions.loading(false);
        },
      });
    },

    delete(contact_id, interaction_id) {
      this.actions.loading(true);
      Api.VOLUNTEERS.INTERACTIONS.delete({
        urlExtend: `/${interaction_id}`,
        urlArgs: { id: contact_id },
        success: () => {
          AlertActions.launchToast({
            message: "Successfully deleted interaction.",
            type: "success",
            timeout: alertTimeout,
          });
          Utils.wait(ES_DELAY, () => {
            this.actions.interactionDeleted(contact_id, interaction_id);
            this.actions.loading(true);
          });
        },
        error(xhr) {
          ErrorLogger.captureRequest("Update Interaction Error", xhr);
          AlertActions.launchToast({
            message: "There was an error saving your interaction. Please try again",
            type: "error",
            timeout: alertTimeout,
          });
        },
      });
    },
  }, // end api
});

export default InteractionSource;
