import _ from "underscore";
// Get URL prefix to determine environment
const url_parts = window.location.host
  .split(".")
  .reverse()[2]
  .split("-");
const url_environment = _.size(url_parts) > 1 ? url_parts[0] : "production";

const sandbox = "env_sandbox";
const env = localStorage ? localStorage.getItem(sandbox) || url_environment : url_environment;

// Set the api url prefix based on environment
const api_prefix = env !== "production" ? "stage-api" : "api";

const config = {
  appKey: "7cb9b8d4451567b725e81b998cdf46cfc58426f9936916c714acddbc9f082546",
  apiBase: `https://${api_prefix}.evertrue.com/`,
  urlEnv: url_environment,
  dataEnv: env === "production" ? "Production" : "Stage",
  evertrueUrl: env !== "production" ? "http://stage-app.evertrue.com" : "https://app.evertrue.com",
  accountsUrl: env !== "production" ? "http://stage-accounts.evertrue.com" : "https://accounts.evertrue.com",
  isStageData: env !== "production",
  isStageUrl: env === "stage",
  isDevelopment: url_environment !== "production",
  isLocal: url_environment === "local",

  switchEnvironment() {
    try {
      if (env !== "production") {
        if (typeof localStorage !== "undefined" && localStorage !== null) {
          localStorage.setItem(sandbox, "production");
        }
      } else if (typeof localStorage !== "undefined" && localStorage !== null) {
        localStorage.removeItem(sandbox);
      }
      if (typeof localStorage !== "undefined" && localStorage !== null) {
        localStorage.removeItem("oid");
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  },
};

export default config;
