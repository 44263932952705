/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import Decorator from "@evertrue/client-decorator";
import { createComponent } from "@evertrue/et-flux";
import Tracker from "utils/tracker";
import UtilMethods from "utils/util-methods";
import ProfileLocation from "apps/profile/components/profile-location";
import PoolStore from "apps/pools/pool-store";
import PropertyStore from "core/apps/property/property-store";
import { ModalTriggerDeprecated } from "@evertrue/et-components";

import ProfileContactInfo from "apps/profile/components/profile-contact-info";
import ProfileSection from "apps/profile/components/profile-section";
import ProfileSectionItem from "apps/profile/components/profile-section-item";
import UpdateStageMobileSheet from "apps/profile/components/update-stage-mobile-sheet";
import RespondTo from "components/respond-to";

export default createComponent("ProfileBio", {
  propTypes: {
    poolId: PropTypes.number,
    contact: PropTypes.object,
    showContactSection: PropTypes.bool,
    assignment: PropTypes.object,
  },

  getDefaultProps() {
    return { contact: {} };
  },

  registerStores() {
    this.on(PoolStore, "poolId", (props) => ({ pool: PoolStore.getById(props.poolId) }));

    this.on(PropertyStore, "contact", ({ contact }) => ({
      custom_data: PropertyStore.getCustomDataForContact(contact),
    }));
  },

  handleTrack(link_type) {
    Tracker.send("outreach_action", {
      type: "contact_link",
      outreach_type: link_type,
      contact_id: this.props.contact.id,
      pool_id: this.props.poolId,
    });
  },

  // make sure not getting a custom field called sports
  getSports(contact) {
    const { sports } = contact;
    const first_value = _.first(sports);
    if (first_value && first_value.name ? first_value.name.default : undefined) {
      return sports;
    } else {
      return [];
    }
  },

  // make sure not getting a custom field called extracurriculars
  getExtracurriculars(contact) {
    const extracurriculars = contact.extracurricular_activities;
    const first_value = _.first(extracurriculars);
    if (first_value && first_value.name ? first_value.name.default : undefined) {
      return extracurriculars;
    } else {
      return [];
    }
  },

  renderCustomValue(value, type) {
    switch (type) {
      case "boolean":
        if (value) {
          return "Yes";
        } else {
          return "No";
        }
      case "currency":
        return UtilMethods.currencyFormatter(value);
      default:
        if (_.isUndefined(value) || _.isNull(value)) {
          return <span className="text-placeholder"> Not Provided</span>;
        } else {
          return value;
        }
    }
  },

  render() {
    const { contact } = this.props;
    const { stage_group_id } = this.state.pool;
    const educations = _.sortBy(Decorator.Profile.getParsedEducation(contact), "year").reverse();
    const positions = Decorator.Work.getAllPositions(contact);
    const sports = this.getSports(contact);
    const extracurriculars = this.getExtracurriculars(contact);
    const custom_data = PropertyStore.getCustomDataForContact(contact);

    return (
      <div className="profile-bio fs-exclude-data">
        {this.props.showContactSection && (
          <ProfileSection label="Contact">
            {<ProfileContactInfo contact={contact} onTrack={this.handleTrack} />}
          </ProfileSection>
        )}

        <ProfileSection label="Location">
          {<ProfileLocation contact={contact} onTrack={this.handleTrack} />}
        </ProfileSection>

        {this.state.pool.stage_group_id !== 0 && (
          <RespondTo to="mobile">
            <ProfileSection label="Stage">
              <ProfileSectionItem icon="layers">
                <span>{this.props.assignment.assignment_stage || "None Provided"}</span>
                {this.props.tab === "assignments" && (
                  <ModalTriggerDeprecated
                    modal={
                      <UpdateStageMobileSheet
                        stageGroupId={stage_group_id}
                        selectedStage={this.props.assignment.assignment_stage}
                        assignmentId={this.props.assignment.assignment_id}
                      />
                    }
                  >
                    <div className="profile-section--view-more">EDIT</div>
                  </ModalTriggerDeprecated>
                )}
              </ProfileSectionItem>
            </ProfileSection>
          </RespondTo>
        )}

        <ProfileSection label="Career">
          {_.isEmpty(positions) ? (
            <ProfileSectionItem icon="career" />
          ) : (
            _.map(positions, (position, i) => (
              <ProfileSectionItem key={i} icon="career">
                <div>
                  <strong>{position.name}</strong>
                  {position.title ? <div>{position.title}</div> : undefined}
                  {position.function ? <div>{position.function}</div> : undefined}
                  {position.industry ? <div>{position.industry}</div> : undefined}
                </div>
              </ProfileSectionItem>
            ))
          )}
        </ProfileSection>

        <ProfileSection label="Education">
          {_.isEmpty(educations) ? (
            <ProfileSectionItem icon="education" />
          ) : (
            _.map(educations, (education, i) => (
              <ProfileSectionItem icon="education" key={i}>
                {!_.isEmpty(education) ? (
                  <div>
                    <strong>{education != null ? education.school_name : undefined}</strong>
                    <div>{education != null ? education.degree_in_major : undefined}</div>
                    <div>{education != null ? education.year : undefined}</div>
                  </div>
                ) : undefined}
              </ProfileSectionItem>
            ))
          )}
        </ProfileSection>

        <ProfileSection label="Relationships">
          {_.isEmpty(contact.relationships) ? (
            <ProfileSectionItem icon="link" />
          ) : (
            _.map(Decorator.Relationships.getSortedVMSRelationships(contact), function (relationship, i) {
              if (
                (relationship && relationship.name && relationship.name.value) ||
                (relationship && relationship.type && relationship.type.value)
              ) {
                return (
                  <ProfileSectionItem icon="link" key={i}>
                    <div>
                      <div>{relationship && relationship.name && relationship.name.value}</div>
                      <div>{relationship && relationship.type && relationship.type.value}</div>
                    </div>
                  </ProfileSectionItem>
                );
              }
            })
          )}
        </ProfileSection>

        <ProfileSection label="Sports">
          {_.isEmpty(sports) ? (
            <ProfileSectionItem icon="directions-run" />
          ) : (
            _.map(sports, (sport, i) => (
              <ProfileSectionItem icon="directions-run" key={i}>
                <div>{sport && sport.name && sport.name.value}</div>
              </ProfileSectionItem>
            ))
          )}
        </ProfileSection>

        <ProfileSection label="Extracurricular Activities">
          {_.isEmpty(extracurriculars) ? (
            <ProfileSectionItem icon="activity-icon" />
          ) : (
            _.map(extracurriculars, (extracurricular, i) => (
              <ProfileSectionItem icon="activity-icon" key={i}>
                <div>{extracurricular && extracurricular.name && extracurricular.name.value}</div>
              </ProfileSectionItem>
            ))
          )}
        </ProfileSection>

        {!_.isEmpty(custom_data) &&
          _.map(custom_data, (custom_section, index) => {
            return (
              <ProfileSection label={custom_section.label} key={index}>
                {_.map(custom_section.values, (value, i) => {
                  return (
                    <ProfileSectionItem icon="star" key={i}>
                      <div>{this.renderCustomValue(value, custom_section.type)}</div>
                    </ProfileSectionItem>
                  );
                })}
              </ProfileSection>
            );
          })}
      </div>
    );
  },
});
