import { createStore } from "@evertrue/et-flux";
import DnaSource from "./dna-source";

const DNA_ALIASES = {
  SUPPORT_EMAIL: "ET.Email.VolunteerSupport.Recipients",
  RESOURCES: "ET.Volunteer.ResourceLink",
  FISCAL_YEAR: "ET.Org.FiscalYear",
};

const getKey = alias => {
  const key = DNA_ALIASES[alias];
  if (key) {
    return key;
  }
  console.error(`${alias} is not defined in dna-store.js`);
};

const DnaStore = createStore("DnaStore", {
  getInitialState() {
    return { settings: {} };
  },

  registerActions() {
    this.on(DnaSource.actions.fetchFromAlias, alias => {
      const key = getKey(alias);
      if (key) {
        DnaSource.fetchFromServer(key);
      }
    });

    this.on(DnaSource.actions.fetchedFromServer, (key, value) => {
      const updated_settings = this.getState("settings") || {};
      updated_settings[key] = value;
      this.setState({ settings: updated_settings });
    });
  },

  api: {
    getDnaKey(alias) {
      const key = getKey(alias);
      return this.getState("settings")[key];
    },

    getFiscalYear() {
      return this.getDnaKey("FISCAL_YEAR") || "07-01";
    },
  },
});

export default DnaStore;

