import PropTypes from "prop-types";
import React from "react";
import { Link, Route } from "react-router-dom";

const propTypes = {
  to: PropTypes.string,
  pool: PropTypes.number,
  replace: PropTypes.bool,
  prospectType: PropTypes.string,
  tab: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const PoolLink = props => {
  return (
    <Route
      path="/pools/:pool_id?/:tab?/:prospect_id?"
      render={router_props => {
        const { tab } = router_props.match.params;
        let { pool_id } = router_props.match.params;

        pool_id = props.pool || parseInt(pool_id, 10);

        let to = `/pools/${pool_id}/${props.tab || tab || "assignments"}`;
        if (props.to) {
          to += props.to;
        }

        return (
          <Link to={to} className={props.className} replace={props.replace} onClick={props.onClick}>
            {props.children}
          </Link>
        );
      }}
    />
  );
};

PoolLink.propTypes = propTypes;
export default PoolLink;
