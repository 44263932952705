import _ from "underscore";
import numeral from "numeral";
import Config from "config/env";

const _cloneData = item => {
  // Functions and arrays return true for _.isObject(...)
  if (!_.isObject(item) || _.isFunction(item)) {
    return item;
  } else if (_.isArray(item)) {
    return _.map(item, _cloneData);
  } else if (_.isObject(item)) {
    return _.mapObject(item, _cloneData);
  }
  return item;
};

const Utils = {
  cloneData: _cloneData,

  compMap: _.compose(
    _.compact,
    _.map,
  ),

  wait(time, func) {
    return _.delay(func, time);
  },

  pluralize(string) {
    if (string) {
      const lastChar = string.charAt(string.length - 1);
      let plural_str = "";
      if (lastChar === "y") {
        // If the y has a vowel before it (i.e. toys), then you just add the s.
        // Otherwise a this ends in y with a consonant before it (fly), you drop the y and
        // add -ies to make it plural.
        if (["a", "e", "i", "o", "u"].includes(string.charAt(string.length - 2))) {
          plural_str = `${string}s`;
        }
        plural_str = `${string.slice(0, -1)}ies`;
      } else if (string.substring(string.length - 1) === "us") {
        // // ends in us -> i, needs to preceed the generic "s" rule
        plural_str = `${string.slice(0, -2)}i`;
      } else if (
        ["ch", "sh"].indexOf(string.substring(string.length - 2)) !== -1 ||
        ["x", "s"].indexOf(lastChar) !== -1
      ) {
        // // If a this ends in ch, sh, x, s, you add -es to make it plural.
        plural_str = `${string}es`;
      } else {
        plural_str = `${string}s`;
      }
      return plural_str;
    }
    return "";
  },

  toNumber(number) {
    return parseInt(number, 10);
  },

  compactObject(object) {
    const obj = {};
    _.each(object, (value, key) => {
      if (!_.isUndefined(value) && !_.isNull(value)) {
        obj[key] = value;
      }
    });
    return obj;
  },

  makeArray(item) {
    if (_.isArray(item)) {
      return item;
    } else {
      return [item];
    }
  },

  toObject(key, value, obj) {
    const new_obj = obj || {};
    if (key != null) {
      new_obj[key] = value;
    }
    return new_obj;
  },

  jsonParse(data, fail_default) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return fail_default;
    }
  },

  currencyFormatter(numString) {
    const num = numeral(numString).value();
    if (_.isNull(numString)) {
      return "N/A";
    } else if (num >= 1000000) {
      return numeral(num).format("$0.00a");
    }
    return numeral(num).format("$0,0");
  },

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  regexEscape(str) {
    if (str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }
    return null;
  },

  searchCompare(str, query) {
    return (
      !query ||
      (str != null
        ? str.toLowerCase().match(new RegExp(`\\b${this.regexEscape(query != null ? query.toLowerCase() : undefined)}`))
        : undefined)
    );
  },

  getAccountsUrl() {
    const env = Config.dataEnv;
    let new_path = "https://accounts.evertrue.com";
    // TODO - local-acounts isn't playing very nice with sso flow on stage
    // need to investigate more so we can test with local-accounts smoothly
    // if (__DEV__) {
    //   new_path = "http://local-accounts.evertrue.com:3016";
    // } else
    if (env === "Stage") {
      new_path = "https://stage-accounts.evertrue.com";
    }
    return new_path;
  },

  param(obj = {}) {
    const params = Object.keys(obj)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
      .join("&");
    return params;
  },

  addHttp(url) {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    return url;
  },
  isNumber(num) {
    return typeof num === "number" && !isNaN(num);
  },
};

export default Utils;
