import React from "react";
import Config from "config/env";
import { createComponent } from "@evertrue/et-flux";
import { Link } from "react-router-dom";
import SessionStore from "apps/auth/stores/session-store";
import SessionSource from "apps/auth/sources/session-source";

export default createComponent("ImpersonationHeader", {
  registerStores() {
    return this.on(SessionStore, () => ({
      is_impersonating: SessionStore.getIsImpersonating(),
      impersonated_user: SessionStore.getImpersonatedUser(),
    }));
  },

  handleEndImpersonation() {
    SessionSource.endImpersonation();
  },

  render() {
    const { impersonated_user = {}, is_impersonating } = this.state;
    if (is_impersonating) {
      return (
        <div className="impersonation-header">
          <div className="impersonation-header--info">
            <span className="impersonation-header--text">You are currently impersonating</span>
            <div className="impersonation-header--user-arrow" />
            <div className="impersonation-header--user">
              <span>{impersonated_user.name}</span>
            </div>
          </div>
          <Link to="/pools" onClick={this.handleEndImpersonation} className="impersonation-end-button">
            End Impersonation
          </Link>
        </div>
      );
    }
    return null;
  },
});
