import React from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { CDCModalController, CDCDependenciesProvider } from "@evertrue/cdc";
import { Modal2 } from "@evertrue/et-components";
import { AlertActions, alertTimeout } from "core/utils/alert-actions";
import Api from "core/base/api";
import SessionStore from "apps/auth/stores/session-store";
import Tracker from "utils/tracker";
import classNames from "classnames";

const mapStateToProps = () => ({ user: SessionStore.getUser() });

const deps = {
  submitSuggestion: (data) => {
    return Api.SUGGESTIONS.SUBMISSIONS.post(data);
  },
  sendMixPanel(args) {
    Tracker.send(args);
  },
  successToast(message) {
    AlertActions.launchToast({ message, type: "success", timeout: alertTimeout });
  },
  setMixPanelReferrer() {
    // referrer is nooop
  },
  isVMSApp: true,
};

const CDCModal = ({ user = {}, disableDefaultStyles, isMobile, ...props }) => {
  const deps_obj = React.useMemo(() => ({ ...deps, user, isMobile }), [isMobile, user]);
  return (
    <Modal2 isOpen={props.isOpen} contentClassName={classNames("cdc-modal--modal2", { "is-mobile": isMobile })}>
      <CDCDependenciesProvider value={deps_obj}>
        <CDCModalController {...props} />
      </CDCDependenciesProvider>
    </Modal2>
  );
};

CDCModal.propTypes = {
  user: PropTypes.object.isRequired,
  disableDefaultStyles: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default connect(CDCModal, [SessionStore], mapStateToProps);
