import Decorator from "@evertrue/client-decorator";

const ProfileUtils = {

  getInteractionHelperText(interaction_type, contact) {
    const contact_info = Decorator.Profile.getPrimaryContactInfo(contact) || {};
    const email = contact_info.email != null ? contact_info.email.email : null;
    const phone = contact_info.phone != null ? contact_info.phone.phone : null;

    switch (interaction_type) {
    case "Volunteer Email": return email || "";
    case "Volunteer Phonecall":
    case "Volunteer Text Message":
      return phone || "";
    case "Volunteer Social": return "LinkedIn";
    default: return "";
    }
  },

  getAddressString(orig_address) {
    const address = {
      address_1: {},
      address_2: {},
      address_3: {},
      ...orig_address,
    };
    let address_string = "";
    if (address.address_1.value) { address_string += ` ${address.address_1.value}`; }
    if (address.address_2.value) { address_string += ` ${address.address_2.value}`; }
    if (address.address_3.value) { address_string += ` ${address.address_3.value}`; }
    address_string += ` ${Decorator.Address.getLastLineFormat(address)}`;
    return address_string;
  },
};

export default ProfileUtils;
