import _ from "underscore";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import ProspectAssignmentsStore from "core/apps/prospects/prospect-assignments-store";
import ProspectAssignmentsSource from "core/apps/prospects/prospect-assignments-source";

const AssignmentsResolver = createComponent("AssignmentsResolver", {
  propTypes: {
    contactIds: PropTypes.array,
    pool: PropTypes.object.isRequired,
    render: PropTypes.func,
  },

  getDefaultProps() {
    return {
      contactIds: [],
      pool: {},
      render: _.noop,
    };
  },

  registerStores() {
    this.on(ProspectAssignmentsStore, ["pool"], ({ pool }) => {
      return {
        data: ProspectAssignmentsStore.getAssignmentIndex(pool.id),
        loading: ProspectAssignmentsStore.getLoading(),
      };
    });
  },

  componentDidMount() {
    const { contactIds, pool } = this.props;

    if (!_.isEmpty(_.compact(contactIds))) {
      ProspectAssignmentsSource.fetchUnlessCached(pool.id, contactIds);
    }
  },

  componentWillReceiveProps(newProps) {
    const { contactIds, pool } = newProps;
    const new_ids = _.compact(contactIds);
    const old_ids = _.compact(this.props.contactIds);

    const id_change = !_.isEmpty(_.difference(new_ids, old_ids));
    const pool_change = !_.isEqual(pool, this.props.pool);

    if (id_change || pool_change) {
      ProspectAssignmentsSource.fetchUnlessCached(pool.id, new_ids);
    }
  },

  render() {
    const { pool, contactIds } = this.props;

    const requested_data = {};
    _.each(contactIds, id => {
      requested_data[id] = ProspectAssignmentsStore.getByPrimaryOrSecondary(pool.id, id);
    });

    return this.props.render({ loading: this.state.loading, assignments: requested_data });
  },
});

export default AssignmentsResolver;

