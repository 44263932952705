import Api from "core/base/api";
import { createSource } from "@evertrue/et-flux";
import ErrorLogger from "utils/error-logger";

const NotificationSource = createSource("NotificationSource", {
  actions: {
    loading: true,
    error: true,
    fetchedNotifications: true,
    updatedCount: true,
    updatedNotification: true,
  },

  api: {
    fetch() {
      this.actions.loading(true);
      Api.EMS.NOTIFICATIONS.get({
        disableAlerts: true,
        success: notifications => {
          this.actions.fetchedNotifications(notifications);
          this.actions.loading(false);
        },
        error: xhr => {
          this.actions.error(xhr.error);
          ErrorLogger.captureRequest("error fetching notifications", xhr);
        },
      });
    },

    markAsRead(id) {
      Api.EMS.NOTIFICATIONS.put({
        urlExtend: `/${id || "all"}/read`,
        success: () => {
          this.fetch();
        },
        error: xhr => {
          ErrorLogger.captureRequest("error marking read", xhr);
        },
      });
    },
  },
});

export default NotificationSource;
