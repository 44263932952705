import React from "react";
import PropTypes from "prop-types";
import useGate from "core/apps/gates/use-gate";
import { LinkButton } from "@evertrue/et-components";
import classNames from "classnames";
import RespondTo from "components/respond-to";

const TABS = ["KPI Report", "Volunteers Table"];

const ReportingContainer = props => {
  const report_gate = useGate({ gate: "vms_head_agent_reporting_summary" });
  const table_gate = useGate({ gate: "vms_head_agent_table" });
  const [currentTabIndex, setTab] = React.useState(0);

  const filtered_tabs = TABS.filter(tab => {
    if (tab === TABS[0] && !report_gate) return false;
    if (tab === TABS[1] && !table_gate) return false;
    return true;
  });

  const currentTab = filtered_tabs.length === 1 ? TABS.indexOf(filtered_tabs[0]) : currentTabIndex;

  if (!props.isMobile) return props.children;

  return (
    <>
      <RespondTo className="reporting-container--tabs">
        {filtered_tabs.map((tab, idx) => (
          <LinkButton
            className={classNames("reporting-container--tab", { "is-active": currentTabIndex === idx })}
            onClick={() => setTab(idx)}
            key={tab}
            title={`Link to ${tab}`}
          >
            {tab}
          </LinkButton>
        ))}
      </RespondTo>
      {props.children[currentTab]}
    </>
  );
};

ReportingContainer.propTypes = {};

export default ReportingContainer;
