import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import ResponsiveSource from "apps/layout/sources/responsive-source";
import Storage from "utils/storage";

// Trigger mobile early to keep min-width of profile
const MOBILE_WIDTH = 900;
const TABLET_WIDTH = 1280;

let _getCollapsedFromStorage = () => Storage.get("pool_nav_collapsed") || false;

const _getDimensions = () => ({
  width: window.outerWidth,
  height: window.outerHeight,
});

const _setDimensions = () => {
  ResponsiveSource.resize(_getDimensions());
};

const _debouncedSetDimensions = _.debounce(_setDimensions, 50);
window.onresize = _debouncedSetDimensions;

_getCollapsedFromStorage = () => Storage.get("pool_nav_collapsed") || false;

// TODO: remove this unnecessary abstraction
export default createStore("ResponsiveStore", {
  getInitialState() {
    return _.extend({}, _getDimensions(), { is_collapsed: _getCollapsedFromStorage() });
  },

  firstListenerDidMount() {
    ResponsiveSource.breakpoint();
  },

  registerActions() {
    this.on(ResponsiveSource.actions.poolExpand, () => this.respondToUiChange(false));
    this.on(ResponsiveSource.actions.poolCollapse, () => this.respondToUiChange(true));
    this.on(ResponsiveSource.actions.resize, this.respondToBreakpoint);
  },

  respondToBreakpoint(dimensions) {
    const was_mobile = this.isMobile();
    const was_tablet = this.isTablet();

    this.setState(dimensions);

    // Trigger a change in state
    if (was_tablet !== this.isTablet() || was_mobile !== this.isMobile()) {
      ResponsiveSource.breakpoint();
    }

    // Update pool collapse/expand
    if (this.isTablet()) {
      this.setState({ is_pool_collapsed: true });
    } else if (this.isDesktop()) {
      this.setState({ is_pool_collapsed: _getCollapsedFromStorage() });
    }
  },

  respondToUiChange(is_collapsed) {
    this.setState({ is_pool_collapsed: is_collapsed });
    if (this.isDesktop()) {
      if (is_collapsed) {
        Storage.set("pool_nav_collapsed", true);
      } else {
        Storage.remove("pool_nav_collapsed");
      }
    }
  },

  api: {
    isMobile() {
      return this.getState("width") <= MOBILE_WIDTH;
    },

    isTablet() {
      return !this.isMobile() && this.getState("width") <= TABLET_WIDTH;
    },

    isDesktop() {
      return !this.isMobile() && !this.isTablet();
    },

    getHeight() {
      return this.getState("height");
    },

    isPoolCollapsed() {
      return this.state.is_pool_collapsed;
    },
  },
});
