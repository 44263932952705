import PropTypes from "prop-types";
import React, { Component } from "react";
import { AdvancedComboboxContent } from "@evertrue/et-components";
import StageResolver from "core/apps/prospects/resolvers/stage-resolver";

class StageFilterDropdown extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    onChange: PropTypes.func.isRequired,
    stageGroupId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    stageGroupId: undefined,
  };

  render() {
    const { stageGroupId } = this.props;
    return (
      <StageResolver
        includeInactive={true}
        stageGroupId={stageGroupId}
        render={({ stagesForGroup, loading }) => {
          const options = stagesForGroup.map(opt => ({ label: opt.stage, value: opt.stage }));

          return (
            <div className="filter-dropdown">
              <AdvancedComboboxContent
                value={this.props.value}
                options={options}
                loading={loading}
                searchable
                multiple
                allowChangeAll
                allowUnselectAll
                onChange={this.props.onChange}
              />
            </div>
          );
        }}
      />
    );
  }
}

export default StageFilterDropdown;
