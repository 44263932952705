import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Config from "config/env";
import Pusher from "./pusher";

const KEYS = {
  stage: "639a83692f3cfd752407",
  production: "ca252307eefecb2f069a",
};

const PUSHER = new Pusher(KEYS[Config.dataEnv.toLowerCase()], {
  encrypted: true,
  activityTimeout: 6000,
});

const USER = { channel: undefined, callbacks: {} };
const ORG = { channel: undefined, callbacks: {} };

const SocketSource = createSource("SocketSource", {
  actions: {
    prospect_change: true,
    assignment_change: true,
    joint_assignment_change: true,
    ask_change: true,
    refresh_inbox: true,
    committed_amount_change: true,
  },

  unsubscribeUser() {
    if (USER.channel && USER.channel.name) {
      PUSHER.unsubscribe(USER.channel.name);
    }
  },

  unsubscribeOrg() {
    if (ORG.channel && ORG.channel.name) {
      PUSHER.unsubscribe(ORG.channel.name);
    }
  },

  bind(object, evnt) {
    if (!this.actions[evnt]) {
      console.log(`${evnt} not defined as action`);
    } else {
      object.callbacks[evnt] = data => {
        this.actions[evnt](data);
      };
      // TODO: investigate why this guard is now needed
      if (object.channel && typeof object.channel.bind === "function") {
        object.channel.bind(evnt, object.callbacks[evnt]);
      }
    }
  },

  unbind(object, evnt) {
    object.channel.unbind(evnt, object.callbacks[evnt]);
    object.callbacks = _.omit(object.callbacks, evnt);
  },

  api: {
    reset() {
      this.unsubscribeUser();
      USER.channel = undefined;
      USER.callbacks = {};

      this.unsubscribeOrg();
      ORG.channel = undefined;
      ORG.callbacks = {};
    },

    subscribeUser(id) {
      this.unsubscribeUser();
      USER.channel = PUSHER.subscribe(`user_${id}`);
      _.each(USER.channel, (cb, evnt) => {
        USER.channel.bind(evnt, cb);
      });
    },

    bindUser(evnt) {
      this.bind(USER, evnt);
    },

    unbindUser(evnt) {
      this.unbind(USER, evnt);
    },

    subscribeOrg(id) {
      this.unsubscribeOrg();
      ORG.channel = PUSHER.subscribe(`oid_${id}`);
      _.each(ORG.callbacks, (cb, evnt) => {
        ORG.channel.bind(evnt, cb);
      });
    },

    bindOrg(evnt) {
      this.bind(ORG, evnt);
    },

    unbindOrg(evnt) {
      this.unbind(ORG, evnt);
    },

    unsubscribeUserFromPusher() {
      this.unsubscribeUser();
    },
  },
});

export default SocketSource;
