import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  fetchPoolById,
  prospectsTotalQuery,
  numberOfInteractions,
  stageStatsQuery,
  activityTypeQuery,
  numberOfProspectsWithInteractions,
  fetchAnalytics,
  fetchContactsById,
  warmAnalyticsData,
  fetchSessionsByContactId,
  fetchSolicitorsByContactAndPoolId,
} from "apps/pools/pool-reporting-utils";
import {
  PoolReportController as PoolReport,
  ApiContext,
  VolunteersStatsTable,
  MixPanelContext,
  VolunteerSolicitorProvider,
} from "@evertrue/vms-insights";
import StageResolver from "core/apps/prospects/resolvers/stage-resolver";
import classNames from "classnames";
import Tracker from "utils/tracker";
import DnaConnector from "core/apps/dna/dna-connector";
import IsGated from "core/apps/gates/is-gated";
import ReportingContainer from "../components/reporting-container";
import useGate from "core/apps/gates/use-gate";

const apiCalls = {
  fetchPoolById,
  prospectsTotalQuery: prospectsTotalQuery,
  fetchNumberOfInteractions: numberOfInteractions,
  stageStatsQuery,
  fetchActivityType: activityTypeQuery,
  fetchNumberOfProspectsWithInteractions: numberOfProspectsWithInteractions,
  fetchAnalytics,
  warmAnalyticsData,
  fetchSessionsByContactId,
};

const statusDict = {
  true: "assigned",
  false: "unassigned",
  all: "all",
};

const mixPanelEvents = {
  toggleAssignmentStatus: ({ status }) => {
    Tracker.send("pool_report_action", { type: "filtered", filter: statusDict[status] });
  },
  sortByColumns: ({ sortField }) => {
    Tracker.send("pool_report_action", { type: "sorted", filter: sortField });
  },
};
const propTypes = {
  pool: PropTypes.object,
  isMobile: PropTypes.bool,
};

const defaultProps = {
  pool: {},
};
const PoolReportController = props => {
  const hasStagesFeature = useGate({ gate: "volunteers_stages" });

  return (
    <MixPanelContext.Provider value={mixPanelEvents}>
      <ApiContext.Provider value={{ ...apiCalls, fetchContactsById: fetchContactsById(props.pool.id) }}>
        <VolunteerSolicitorProvider fetchSolicitorsByContactAndPoolId={fetchSolicitorsByContactAndPoolId}>
          <DnaConnector>
            {({ fiscalYearEnd, fiscalYear }) => {
              return (
                <React.Fragment>
                  <ReportingContainer isMobile={props.isMobile}>
                    <IsGated gate="vms_head_agent_reporting_summary">
                      <StageResolver
                        stageGroupId={props.pool.stage_group_id}
                        children={({ stagesForGroup }) => (
                          <PoolReport
                            isMobile={props.isMobile}
                            hasStagesFeature={true}
                            stagesForGroup={stagesForGroup}
                            orgFiscalYearEnd={fiscalYearEnd}
                            {...props}
                          />
                        )}
                      />
                    </IsGated>
                    <IsGated gate="vms_head_agent_table">
                      <div
                        className={classNames("pool-report-controller--table", {
                          "is-mobile": props.isMobile,
                        })}
                      >
                        <StageResolver stageGroupId={props.pool.stage_group_id}>
                          {({ stagesForGroup }) => (
                            <VolunteersStatsTable
                              showResponsive={props.isMobile}
                              isMobile={props.isMobile}
                              pool={props.pool}
                              autoHeightMax={400}
                              isVMSApp={true}
                              orgFiscalYear={fiscalYear}
                              isHeadAgentEnabled={true}
                              limit={props.isMobile ? 10 : 50}
                              stagesForGroup={stagesForGroup}
                              hasStagesFeature={hasStagesFeature}
                              orgFiscalYearEnd={fiscalYearEnd}
                            />
                          )}
                        </StageResolver>
                      </div>
                    </IsGated>
                  </ReportingContainer>
                </React.Fragment>
              );
            }}
          </DnaConnector>
        </VolunteerSolicitorProvider>
      </ApiContext.Provider>
    </MixPanelContext.Provider>
  );
};
PoolReportController.propTypes = propTypes;
PoolReportController.defaultProps = defaultProps;

export default PoolReportController;
