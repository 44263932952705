import _ from "underscore";
import SocketSource from "base/socket-source";
import { createStore } from "@evertrue/et-flux";
import Utils from "core/utils/utils";
import ProspectAssignmentsSource from "./prospect-assignments-source";
import StageSource from "./stage-source";

const ProspectAssignmentsStore = createStore("ProspectAssignmentsStore", {
  getInitialState() {
    return {
      loading_all: false,
      loading_prospects: [],
      prospects_by_id: {},
    };
  },

  firstListenerDidMount() {
    SocketSource.bindOrg("assignment_change");
    SocketSource.bindOrg("joint_assignment_change");
    SocketSource.bindOrg("ask_change");
    SocketSource.bindOrg("committed_amount_change");
  },

  registerActions() {
    this.on(ProspectAssignmentsSource.actions.loading, this.respondToLoading);
    this.on(ProspectAssignmentsSource.actions.loadingAll, this.respondToLoadAll);
    this.on(ProspectAssignmentsSource.actions.fetchedAssignments, this.respondToFetched);
    this.on(ProspectAssignmentsSource.actions.fetchUnlessCached, this.respondToFetchUnlessCached);
    this.on(ProspectAssignmentsSource.actions.optimisticUpdateAssignment, (id, updated) => {
      this.setState({
        prospects_by_id: { ...this.state.prospects_by_id, [id]: updated },
      });
    });

    this.on(SocketSource.actions.assignment_change, this.respondToWebSocket);
    this.on(SocketSource.actions.joint_assignment_change, this.respondToWebSocket);
    this.on(SocketSource.actions.ask_change, this.respondToWebSocket);
    this.on(SocketSource.actions.committed_amount_change, this.respondToWebSocket);

    this.on(StageSource.actions.stageUpdated, (assignment_id, res) => {
      const target = this.state.prospects_by_id[assignment_id] || {};
      const updated = { ...target, assignment_stage: res.stage };
      this.setState({
        prospects_by_id: { ...this.state.prospects_by_id, [assignment_id]: updated },
      });
    });
  },

  respondToLoading(prospect_ids, is_loading) {
    let loading_prospects = this.state.loading_prospects;
    const ids = Utils.makeArray(prospect_ids);

    if (is_loading) {
      loading_prospects = loading_prospects.concat(ids);
    } else {
      loading_prospects = _.difference(loading_prospects, ids);
    }
    this.setState({ loading_prospects });
  },

  respondToLoadAll(is_loading) {
    this.setState({ loading: is_loading });
  },

  respondToFetchUnlessCached(pool_id, new_ids) {
    const old_ids = _.pluck(this.getState("prospects_by_id"), "prospect_contact_id");
    const ids_to_fetch = _.difference(new_ids, old_ids);
    if (!_.isEmpty(ids_to_fetch)) {
      ProspectAssignmentsSource.fetchFromContacts(pool_id, ids_to_fetch);
    }
  },

  respondToFetched(prospects) {
    const cached = Utils.cloneData(this.state.prospects_by_id) || {};
    const prospects_by_id =
      prospects && !_.isEmpty(prospects.items) ? _.extend(cached, _.indexBy(prospects.items, "assignment_id")) : cached;
    this.setState({ prospects_by_id });
  },

  respondToWebSocket(data = {}) {
    const id = data.assignment_id || data.id;
    Utils.wait(1000, () => {
      ProspectAssignmentsSource.fetch(id);
    });
  },

  api: {
    getLoading(prospect_id) {
      return _.contains(this.getState("loading_prospects"), prospect_id);
    },

    getLoadingAll() {
      return this.getState("loadingAll");
    },

    getForPool(pool_id) {
      return _.filter(this.getState("prospects_by_id"), p => {
        return p.pool_id === pool_id;
      });
    },

    getByContact(pool_id, contact_id) {
      return _.findWhere(this.getForPool(pool_id), { prospect_contact_id: contact_id });
    },

    getByPrimaryOrSecondary(pool_id, contact_id) {
      return _.find(this.getForPool(pool_id), prospect => {
        const is_secondary = _.contains(prospect.secondary_prospect_contact_ids, contact_id);
        return prospect.prospect_contact_id === contact_id || is_secondary;
      });
    },

    getAssignmentIndex(pool_id) {
      return _.indexBy(this.getForPool(pool_id), "prospect_contact_id");
    },
  },
});

export default ProspectAssignmentsStore;
