import Config from "config/env";

// this is for refreshing the session with just the skiff cookie
// (web only)
function refreshSession(oid) {
  const fetch_options = {
    headers: {
      Accept: "application/json, text/plain",
      "Application-Key": Config.appKey,
      "Authorization-Auto-Send": 0,
      "Authorization-Multifactor": 1,
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization-Provider": "EvertruePrimeToken",
    },
    method: "POST",
    credentials: "include",

    body: JSON.stringify({ type: "SCOPED", app_key: Config.appKey, oid }),
  };
  return fetch(`${Config.apiBase}skiff`, fetch_options);
}

export default refreshSession;
