import { createSource } from "@evertrue/et-flux";
import Api from "base/api";
import _ from "underscore";
import { AlertActions, alertTimeout } from "core/utils/alert-actions";
import ErrorLogger from "utils/error-logger";

const StageSource = createSource("StageSource", {
  actions: {
    fetchedStageGroups: res_should_be_object,
    fetchStageGroupsUnlessCached: true,
    fetchGroupUnlessCached: true,
    fetchedStageGroup: res_should_be_object,
    stageUpdated(assignment_id, res) {
      this.require(_.isNumber(assignment_id), "assignment_id should be number");
      res_should_be_object.call(this, res);
    },
    fetchStageGroupsError: true,
    fetchedStageGroupError: true,
  },
  api: {
    fetchStageGroupsUnlessCached() {
      this.actions.fetchStageGroupsUnlessCached();
    },
    fetchGroupUnlessCached() {
      this.actions.fetchGroupUnlessCached();
    },
    fetchStageGroups() {
      Api.VOLUNTEERS.STAGES.get({
        success: (res) => {
          this.actions.fetchedStageGroups(_.indexBy(res, "id"));
        },
        error: (error) => {
          ErrorLogger.captureRequest("fetch stages error", error);
          this.actions.fetchStageGroupsError();
        },
      });
    },
    fetchStageGroupById(id) {
      Api.VOLUNTEERS.STAGES.get({
        urlExtend: `/${id}`,
        success: (res) => {
          this.actions.fetchedStageGroup(res);
        },
        error: (error) => {
          ErrorLogger.captureRequest("fetch stage group by id error", error);
          this.actions.fetchedStageGroupError(id);
        },
      });
    },
    updateStage(assignment_id, stage) {
      Api.VOLUNTEERS.UPDATE_STAGE.put({
        urlArgs: { assignment_id },
        data: { stage },
        success: (res) => {
          this.actions.stageUpdated(assignment_id, res);
          AlertActions.launchToast({ type: "success", message: "Successfully Updated Stage", timeout: alertTimeout });
        },
        error: (err) => {
          AlertActions.launchToast({ message: "Error Updating Stage", type: "error", timeout: alertTimeout });
          ErrorLogger.captureRequest("Update Stage failed", err);
        },
      });
    },
  },
});

function res_should_be_object(res) {
  this.require(_.isObject(res), "res should be object");
}

export default StageSource;
