/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from "react";

import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import { Loading, EmptyStateMessage } from "components/et-factories";
import { Redirect } from "components/router";
import { div } from "react-dom-factories";
import Api from "base/api";
import ErrorLogger from "utils/error-logger";
import Tracker from "utils/tracker";
import SessionSource from "apps/auth/sources/session-source";
import MagicLinkStore from "apps/auth/magic-link-store";
import MagicLinkSource from "apps/auth/magic-link-source";
import EmailInput from "apps/auth/components/email-input";
import ModuleClass from "components/module";
import { createScopedSession } from "core/base/session-utils";
import OrgSource from "apps/auth/sources/org-source";

const Module = React.createFactory(ModuleClass);

const createSessionFromCookie = () =>
  Api.AUTH.SKIFF.post({
    headers: {
      Authorization: null,
      "Authorization-Provider": null,
    },
    credentials: "include",
    bypassDefaultResponse: true,
  });

const register = function(invite_token, email) {
  Tracker.send("auth_started", { type: "register" });
  return (
    Api.AUTH.REGISTER.post({
      headers: {
        Authorization: null,
        "Authorization-Provider": null,
      },
      credentials: "include",
      data: {
        user: { invite_token, email },
      },
    })
      // register gives us an unscoped session, but an oid
      .then(({ user, session: unscoped_session }) => {
        // let's use that oid to create a scoped session
        // first_oid should be the oid in the invite
        createScopedSession({ oid: user.first_oid })
          .then(({ organization, ...session }) => {
            if (organization) {
              OrgSource.setCurrentOrgObject(organization);
            }
            SessionSource.setRegistrationSession(session);
          })
          .catch(() => {
            SessionSource.setRegistrationSession(unscoped_session);
          });
      })
      .catch(function(xhr) {
        ErrorLogger.captureRequest("Auth Error: register", xhr);
        Tracker.send("auth_failed", { type: "register" });
        throw xhr;
      })
  );
};

export default createComponent("InviteConnectController", {
  propTypes: {
    email: PropTypes.string,
    invite_token: PropTypes.string,
  },

  getInitialState() {
    return {
      registered: false,
      error: undefined,
      errorStatus: undefined,
    };
  },

  registerStores() {
    this.on(MagicLinkStore, () => ({
      email_was_sent: MagicLinkStore.getAuthEmailSent(),
      email_send_failed: MagicLinkStore.getAuthEmailFailed(),
    }));
  },

  componentDidMount() {
    return register(this.props.invite_token, this.props.email)
      .then(() => this.setState({ registered: true }))
      .catch(error => {
        if (error.message === "Invitation has already been used.") {
          createSessionFromCookie()
            .then(() => this.setState({ registered: true }))
            .catch(() => this.setState({ error: error.message }));
        } else {
          this.setState({ error: error.message });
        }
      });
  },

  handleSendEmail(email) {
    MagicLinkSource.sendTokenFromEmail(email);
  },

  render() {
    return Module(
      { className: "invite-connect-controller" },
      (() => {
        if (this.state.registered) {
          return Redirect({ to: "/" });
        } else if (this.state.error) {
          switch (this.state.error) {
            case "Invitation has already been used.":
              if (this.state.email_was_sent) {
                return div(
                  { className: "magic-link-request" },
                  div({ className: "magic-link-request--header" }, "Your Access Link Is on Its Way!"),
                  div({ className: "magic-link-request--image" }),
                  "Please check your email.",
                );
              } else {
                return div(
                  { className: "magic-link-request is-used-invitation" },
                  EmptyStateMessage(
                    {
                      icon: "report-problem",
                      text: this.state.error,
                    },
                    `This invitation has already been used to create an account. To login, \
please enter your email address and receive a new access link.`,
                  ),

                  React.createElement(EmailInput, {
                    onSendEmail: this.handleSendEmail,
                    emailSendFailed: this.state.email_send_failed,
                    buttonText: "Request Link",
                  }),
                );
              }

            case "Invalid or expired invitation.":
              return EmptyStateMessage(
                {
                  icon: "report-problem",
                  text: this.state.error,
                },
                "This invitation is no longer valid. Please contact your program administrator to get a new invite.",
              );
            default:
              return EmptyStateMessage(
                {
                  icon: "report-problem",
                  text: "User Registration Error",
                },
                `${this.state.error}. Please contact your program administrator.`,
              );
          }
        } else {
          return Loading({
            text: "Please wait while we register your account...",
            position: "top",
          });
        }
      })(),
    );
  },
});
