import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Decorator from "@evertrue/client-decorator";
import { connect } from "@evertrue/et-flux";
import {
  OverlayActions,
  Icon,
  MobileSheet,
  ModalDeprecated,
  ModalConfirm,
  ModalTriggerDeprecated,
} from "@evertrue/et-components";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import ProfileUtils from "core/utils/profile-utils";
import ProfileSectionItem from "apps/profile/components/profile-section-item";
import RespondTo from "components/respond-to";

const mapStateToProps = () => ({ is_mobile: ResponsiveStore.isMobile() });

class ProfileLocation extends React.Component {
  static propTypes = {
    contact: PropTypes.object,
    onTrack: PropTypes.func,
  };

  formatFullAddress = (address) => {
    return (
      <div className="profile-address">
        <div>{address.address_1 != null ? address.address_1.value : undefined}</div>
        <div>{address.address_2 != null ? address.address_2.value : undefined}</div>
        <div>{address.address_3 != null ? address.address_3.value : undefined}</div>
        <div>{Decorator.Address.getLastLineFormat(address)}</div>
      </div>
    );
  };

  renderModalContent = () => {
    const { solicitation_codes } = this.props.contact;
    let addresses = Decorator.Address.getAddresses(this.props.contact);
    // Show primary result first
    addresses = _.sortBy(addresses, (address) => {
      return address && address.primary && address.primary.value;
    });

    return (
      <div className="profile-bio-modal fs-exclude-data">
        <div className="profile-bio-modal--content">
          <Icon icon="location" className="profile-bio-modal--icon" />
          <div className="profile-bio-modal--section">
            <div className="profile-bio-modal--section-title">Institutional Data</div>
            {addresses && addresses.length ? (
              _.map(addresses, (address = {}, i) => {
                const address_string = ProfileUtils.getAddressString(address);
                return (
                  <div
                    key={address_string}
                    className={classNames("profile-bio-modal--item", { "is-last": i + 1 === addresses.length })}
                  >
                    <div className="profile-bio-modal--item-type">
                      <span>{(address.type && address.type.value) || "Other"}</span>
                      {address.primary && address.primary.value && " (Primary) "}
                    </div>
                    <a
                      href={`https://www.google.com/maps/search/${address_string}`}
                      target="_blank"
                      onClick={() => this.props.onTrack("address")}
                    >
                      {this.formatFullAddress(address)}
                    </a>
                  </div>
                );
              })
            ) : (
              <span className="profile-bio-modal--empty-value">None provided</span>
            )}
          </div>
        </div>
        <div className="profile-bio-modal--content">
          <Icon icon="report-problem" className="profile-bio-modal--icon" />
          <div className="profile-bio-modal--section  is-last">
            <div className="profile-bio-modal--section-title">Solicitation Notes</div>
            {solicitation_codes && solicitation_codes.length ? (
              _.map(solicitation_codes, (code, i) => {
                const code_value = code && code.code && code.code.value;
                return (
                  <div key={code_value + "-" + i} className="profile-bio-modal--solicitation-code">
                    {code_value}
                  </div>
                );
              })
            ) : (
              <span className="profile-bio-modal--empty-value">None provided</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderViewMoreModal = () => {
    if (this.props.is_mobile) {
      return (
        <MobileSheet
          className="profile-info--mobile-sheet"
          label="Location Information"
          onBack={function () {
            return OverlayActions.removeOverlay();
          }}
          backgroundColor="#FFFFFF"
        >
          {this.renderModalContent()}
        </MobileSheet>
      );
    } else {
      return (
        <ModalDeprecated width={450}>
          <ModalConfirm header="Additional Location Information" onCancel={_.noop}>
            {this.renderModalContent()}
          </ModalConfirm>
        </ModalDeprecated>
      );
    }
  };

  render() {
    let address_string;
    const address = Decorator.Address.getPrimary(this.props.contact);
    if (address) {
      address_string = ProfileUtils.getAddressString(address);
    }

    return (
      <RespondTo className="profile-section--content-wrapper">
        <div className="profile-section--content fs-exclude-data">
          <ProfileSectionItem icon="location">
            {address ? (
              <a
                href={`https://www.google.com/maps/search/${address_string}`}
                target="_blank"
                onClick={() => this.props.onTrack("address")}
              >
                {this.formatFullAddress(address)}
              </a>
            ) : undefined}
          </ProfileSectionItem>
        </div>

        <div className="profile-section--view-more">
          <ModalTriggerDeprecated modal={this.renderViewMoreModal()}>{<span>VIEW MORE</span>}</ModalTriggerDeprecated>
        </div>
      </RespondTo>
    );
  }
}

export default connect(ProfileLocation, [ResponsiveStore], mapStateToProps);
