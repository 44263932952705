import PropTypes from "prop-types";
import _ from "underscore";
import React from "react";
import classNames from "classnames";
import { CardAnalytics, Tooltip2 } from "@evertrue/et-components";
import PoolLink from "apps/pools/components/pool-link";
import RespondTo from "components/respond-to";
import PoolCardDropdown from "./pool-card-dropdown";
import useIsLeadVol from "hooks/use-is-lead-vol";
import Utils from "core/utils/utils";
import numeral from "numeral";

const PoolCard = ({ pool: { solicitor = {}, name = "" } = {}, pool = {}, className }) => {
  const is_lead_vol = useIsLeadVol(pool);

  const assignment_count = solicitor.assignment_count;
  const formatted_assignment_count = numeral(assignment_count).format("0");
  const prospect_count = pool.total_prospect_count;
  const assignments_label = `Assignment${assignment_count === 1 ? "" : "s"}`;
  const total_dollars_raised = Utils.currencyFormatter(pool.total_dollars_raised);
  const participation = numeral(pool.pool_participation_rate).format("0[.]0%");

  return (
    <RespondTo className="pool-card--outer">
      <PoolCardDropdown poolName={name} pool_id={pool.id} isUserLeadVol={is_lead_vol} />
      <PoolLink className="pool-card--link" pool={pool.id} tab="assignments">
        <RespondTo className={classNames("pool-card", className)}>
          <div className="pool-card--body">
            <div className="pool-card--name">{name}</div>
          </div>
          <RespondTo className="pool-card--analytics">
            {pool.giving_category_label === "EverTrueNoData" ? (
              <CardAnalytics
                className={!pool.has_self_selection ? "is-only-assignments" : undefined}
                data={_.compact([
                  {
                    label: assignments_label,
                    value: formatted_assignment_count,
                  },
                  pool.has_self_selection
                    ? {
                        label: "Prospects",
                        value: prospect_count,
                      }
                    : undefined,
                ])}
              />
            ) : (
              <CardAnalytics
                data={[
                  {
                    label: "In Total Gifts",
                    value: total_dollars_raised,
                  },
                  {
                    label: "Participation",
                    value: participation,
                  },
                ]}
              />
            )}
          </RespondTo>
        </RespondTo>
      </PoolLink>
    </RespondTo>
  );
};
PoolCard.propTypes = {
  pool: PropTypes.object,
  className: PropTypes.string,
};

export default PoolCard;
