import React from "react";
import PropTypes from "prop-types";
import { PoolDataProvider as Provider } from "@evertrue/vms-insights";
import Api from "core/base/api";

const fetchPoolData = poolId =>
  Api.VOLUNTEERS.POOLS.get({
    urlExtend: `/${poolId}`,
    disableAlerts: true,
  }).catch(() => Promise.reject("not found"));

const PoolDataProvider = props => {
  return (
    <Provider fetchPoolData={fetchPoolData} >
      {props.children}
    </Provider>
  );
};
PoolDataProvider.propTypes = {
  children: PropTypes.any,
};
export default PoolDataProvider;
