import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import AssignmentsResolver from "./assignments-resolver";
import ContactsResolver from "core/apps/contacts/resolvers/contacts-resolver";

class ProspectResolver extends React.Component {
  static propTypes = {
    contacts: PropTypes.array,
    pool: PropTypes.object,
    render: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contacts: [],
    pool: {},
  };

  render() {
    const { contacts, pool, render } = this.props;

    return (
      <AssignmentsResolver
        contactIds={_.pluck(contacts, "id")}
        pool={pool}
        render={({ assignments, loading }) => {
          const solicitor_contact_ids = _.pluck(assignments, "solicitor_contact_id");
          const secondary_contact_ids = _.pluck(assignments, "secondary_prospect_contact_ids");

          return (
            <ContactsResolver
              ids={_.uniq(_.flatten([solicitor_contact_ids, secondary_contact_ids]))}
              poolId={pool.id}
              render={resolved_contacts => {
                const assignment_contacts = resolved_contacts.contacts;
                const is_loading = loading || resolved_contacts.loading;

                const data = _.compact(
                  _.map(contacts, contact => {
                    const assignment = assignments[contact.id];
                    if (!assignment) {
                      return null;
                    }
                    const secondary_id = _.first(assignment.secondary_prospect_contact_ids);
                    return {
                      pool,
                      assignment,
                      loading: is_loading,
                      primary: contact,
                      secondary: assignment_contacts[secondary_id],
                      assignedTo: assignment_contacts[assignment.solicitor_contact_id],
                    };
                  }),
                );

                return render({ prospects: data, loading: is_loading });
              }}
            />
          );
        }}
      />
    );
  }
}

export default ProspectResolver;
