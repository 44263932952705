import _ from "underscore";
import React from "react";
import Tracker from "utils/tracker";
import { createComponent } from "@evertrue/et-flux";
import { div } from "react-dom-factories";
import PoolStore from "core/apps/pools/pool-store";
import PoolSource from "core/apps/pools/pool-source";
import PropertySource from "core/apps/property/property-source";
import DnaSource from "core/apps/dna/dna-source";
import PoolContactsController from "apps/prospects/controllers/pool-contacts-controller";
import PoolsDesktopLayoutController from "apps/pools/controllers/pools-desktop-layout-controller";
import ProfileController from "apps/profile/controllers/profile-controller";
import MobileBanner from "apps/layout/components/mobile-banner";
import PoolsList from "apps/pools/components/pool-list";
import { Route, Switch } from "react-router-dom";
import { Loading } from "@evertrue/et-components";
import PoolReportController from "./controllers/pool-reporting-controller";

const RespondTo = require("components/respond-to").default;
const DNAProvider = require("core/apps/dna/dna-context").default;

export default createComponent("PoolRouteController", {
  registerStores() {
    return this.on(PoolStore, () => ({
      pools: PoolStore.getPools(),
      poolsIndex: PoolStore.getPoolsIndex(),
      loading: PoolStore.getLoading(),
    }));
  },

  componentDidMount() {
    PoolSource.fetch();
    PropertySource.fetchCustomFields();
    DnaSource.fetch("FISCAL_YEAR");
    Tracker.send("app_entered");
  },

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <DNAProvider initialKeys={["ET.Org.FiscalYear"]} currentOrg={{ id: this.props.currentOrgId }}>
          {
            // Mobile-Only Routes
            <div style={{ width: "100%" }}>
              {
                // Desktop and Table Route
                <RespondTo to="mobile" style={{ height: "100%" }}>
                  <Switch>
                    <Route
                      path="/pools/:pool_id/reporting"
                      render={({ match: { params } }) => {
                        const { pool_id } = params;
                        const pool = this.state.pools.find(i => i.id === _.toNumber(pool_id));
                        return <PoolReportController isMobile pool={pool} />;
                      }}
                    />
                    <Route
                      path="/pools/:pool_id/:tab/:prospect_id/:section?"
                      render={({ match: { params } }) => {
                        const { pool_id, tab, prospect_id, section } = params;

                        return ProfileController({
                          id: _.toNumber(prospect_id),
                          tab,
                          pool: PoolStore.getById(_.toNumber(pool_id)),
                          section,
                        });
                      }}
                    />
                    <Route
                      path="/pools/:pool_id/:tab?"
                      render={({ match: { params } }) => {
                        const { pool_id, tab } = params;
                        return PoolContactsController({
                          pool_id: _.toNumber(pool_id),
                          pool: PoolStore.getById(_.toNumber(pool_id)),
                          tab,
                        });
                      }}
                    />
                    <Route
                      path="/pools"
                      render={() => {
                        return div(null, MobileBanner(), PoolsList({ pools: this.state.pools }));
                      }}
                    />
                  </Switch>
                </RespondTo>
              }
              <RespondTo to={["desktop", "tablet"]}>
                <Route
                  path="/pools/:pool_id?/:tab?/:prospect_id?/:section?"
                  render={function({ match: { params } }) {
                    return PoolsDesktopLayoutController({
                      pool_id: _.toNumber(params.pool_id),
                      tab: params.tab,
                      prospect_id: _.toNumber(params.prospect_id),
                      profile_section: params.section,
                    });
                  }}
                />
              </RespondTo>
            </div>
          }
        </DNAProvider>
      );
    }
  },
});
