import React from "react";
import PropTypes from "prop-types";

const Datalist = ({ label, className, children }) => {
  return (
    <dl className={className}>
      <dt>{label}</dt>
      <dd>{children}</dd>
    </dl>
  );
};

Datalist.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

Datalist.defaultProps = {
  label: "",
  className: "",
};

export default Datalist;
