import React, { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import get from "lodash.get";
import { Icon } from "@evertrue/et-components";
import YearRange from "apps/prospects/components/filters/year-range";
import NumberRange from "apps/prospects/components/filters/number-range";
import DateRange from "apps/prospects/components/filters/date-range";
import CurrencyRange from "apps/prospects/components/filters/currency-range";
import TagFilters from "apps/prospects/components/filters/tag-filters";

const FilterPart = props => {
  return (
    <div className="prospect-filters--group">
      <div className="prospect-filters--header">{props.label}</div>
      <div className="prospect-filters--inline-control">{props.children}</div>
    </div>
  );
};

class FilterControl extends Component {
  static propTypes = {
    filter: PropTypes.object,
    onChange: PropTypes.func,
    onClickList: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    onChange: _.noop,
    onClickList: _.noop,
  };

  render() {
    const { filter, onChange, onClickList } = this.props;
    const key = filter.key;
    const val = this.props.current_val;

    switch (filter.component_type) {
      case "tags":
        return (
          <FilterPart label={filter.label}>
            <TagFilters value={val} options={filter.options} onChange={tag_val => onChange(key, tag_val)} />
          </FilterPart>
        );
      case "number_range":
        return (
          <FilterPart label={filter.label}>
            <NumberRange value={val} onChange={input_val => onChange(key, input_val)} />
          </FilterPart>
        );
      case "year_range":
        return (
          <FilterPart label={filter.label}>
            <YearRange value={val} onChange={input_val => onChange(key, input_val)} />
          </FilterPart>
        );
      case "date_range":
        return (
          <FilterPart label={filter.label}>
            <DateRange value={val} onChange={input_val => onChange(key, input_val)} />
          </FilterPart>
        );
      case "currency_range":
        return (
          <FilterPart label={filter.label}>
            <CurrencyRange value={val} onChange={input_val => onChange(key, input_val)} />
          </FilterPart>
        );
      case "boolean":
        return (
          <FilterPart label={filter.label}>
            <TagFilters
              value={val}
              options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
              onChange={tag_val => onChange(key, tag_val)}
            />
          </FilterPart>
        );
      case "string":
        return (
          <div
            className="prospect-filters--group is-list"
            role="button"
            tabIndex={0}
            onClick={() => onClickList(filter)}
            key={key}
          >
            <div className="prospect-filters--group-content">
              <div className="prospect-filters--header">{filter.label}</div>
              {val ? (
                <span className="prospect-filters--value">
                  {val.length > 1 ? `${val.length} selected` : get(val, "[0].label")}
                </span>
              ) : (
                undefined
              )}
            </div>
            <Icon icon="chevron-right" size={1} className="prospect-filters--icon" />
          </div>
        );
      default:
        return null;
    }
  }
}
export default FilterControl;
