import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "app-route-controller";
import Config from "config/env";
import Tracker from "utils/tracker";
import "./styles/index.scss";
import "whatwg-fetch"; // polyfill so fetch works in IE
import "url-search-params-polyfill";
import "focus-visible";

if (Config.isDevelopment) {
  window.ET = {
    config: Config,
    track: Tracker,
  };
}

const element = document.getElementById("region-page");

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  element,
);
