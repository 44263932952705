import _ from "underscore";

import Config from "config/env";
import MixpanelEvents from "core/utils/mixpanel-events";
import Decorator from "@evertrue/client-decorator";

const TOKENS = {
  internal: "7609501b9e0b7735203a0285cc3ff14c",
  external: "8c3a521a29a41cd5b9fc9054665ecdb2",
};

const _getValidParams = function(evnt, params) {
  if (params) {
    const valid_params = {};
    if (evnt.props) {
      _.extend(valid_params, _.pick(params, evnt.props));
    }
    if (evnt.type) {
      valid_params.type = evnt.type[params.type];
    }
    return valid_params;
  }
};

let _log_events = false;
let _initalized = false;

const stuff = {
  _watch() {
    return (_log_events = true);
  },

  _stop() {
    return (_log_events = false);
  },

  setup(user, oid) {
    if (_.isEmpty(user) || _.isUndefined(mixpanel) || (Config.isLocal && !_log_events)) {
      return;
    }
    const is_internal_user = Decorator.User.isInternal(user) || user.super_user;

    if (is_internal_user || Config.isDevelopment) {
      mixpanel.init(TOKENS.internal);
    } else {
      mixpanel.init(TOKENS.external);
    }

    mixpanel.identify(user.id);
    mixpanel.people.set({
      $email: user.email,
      $name: user.name,
      oid,
    });

    if (typeof mixpanel.register === "function") {
      mixpanel.register({
        name: user.name,
        user_id: user.id,
        platform: "web",
        is_internal_user,
        oid,
      });
    }

    return (_initalized = true);
  },

  trackIsImpersonating(is_impersonating) {
    if (Config.isLocal && !_log_events) {
      return;
    }
    return mixpanel.register({ is_impersonating });
  },

  send(event_key, params) {
    if (!_initalized) {
      return;
    }
    const evnt = MixpanelEvents[event_key];
    const evnt_name = evnt != null ? evnt.name : undefined;
    if (!evnt) {
      return console.warn(`Event not found. ${event_key}`);
    } else {
      const valid_params = _getValidParams(evnt, params);
      if (_log_events) {
        console.debug(evnt_name, valid_params);
      }
      if (!Config.isLocal) {
        if (mixpanel.people != null) {
          mixpanel.people.increment(evnt_name);
        }
        return typeof mixpanel.track === "function" ? mixpanel.track(evnt_name, valid_params) : undefined;
      }
    }
  },
};

function handleMixpanelUndefined(fn) {
  if (typeof mixpanel === "undefined") {
    console.error("mixpanel is undefined");
    return;
  }
  return fn;
}
function handle(obj) {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    acc[key] = handleMixpanelUndefined(val);
    return acc;
  }, {});
}

let wrapped = handle(stuff);
window.wrapped = wrapped;
export default wrapped;
