import _ from "underscore";
import Api from "base/api";
import { createSource } from "@evertrue/et-flux";
import { AlertActions, alertTimeout } from "core/utils/alert-actions";
import Utils from "core/utils/utils";
import ErrorLogger from "utils/error-logger";

const ProspectAssignmentsSource = createSource("ProspectAssignmentsSource", {
  actions: {
    change: true,
    loading: true,
    loadingAll: true,
    fetchedAssignments: true,
    fetchUnlessCached(pool_id, contact_ids) {
      this.require(_.isNumber(pool_id), "pool_id should be a number");
      this.require(_.isArray(contact_ids), "contact ids should be an array");
    },
    optimisticUpdateAssignment: true,
  },

  api: {
    fetchUnlessCached(pool_id, contact_ids) {
      this.actions.fetchUnlessCached(pool_id, Utils.makeArray(contact_ids));
    },

    fetch(prospect_ids) {
      const ids = Utils.makeArray(prospect_ids);
      this.actions.loading(prospect_ids, true);

      Api.SEARCH.ASSIGNMENTS.post({
        data: {
          must: [{ assignment_id: { in: ids } }, { parent_role: { match: "prospect" } }],
        },
        success: (resp) => {
          this.actions.fetchedAssignments(resp);
          this.actions.loading(prospect_ids, false);
        },
        error: (error) => {
          ErrorLogger.captureRequest("fetch assignments error", error);
          this.actions.loading(prospect_ids, true);
        },
      });
    },

    fetchFromContacts(pool_id, contact_ids) {
      const compacted_ids = _.compact(contact_ids);
      this.actions.loadingAll(true);
      Api.SEARCH.ASSIGNMENTS.post({
        data: {
          must: [
            { pool_id: { match: pool_id } },
            {
              prospect_contact_id: { in: compacted_ids },
              secondary_prospect_contact_ids: { in: compacted_ids },
            },
            { parent_role: { match: "prospect" } },
          ],
        },
        success: (resp) => {
          this.actions.fetchedAssignments(resp);
          this.actions.loadingAll(false);
        },
        error: (error) => {
          ErrorLogger.captureRequest("fetch assignments from contacts error", error);
          this.actions.loadingAll(false);
        },
      });
    },

    selfAssign(pool_id, prospect_id) {
      this.actions.loading(prospect_id, true);

      Api.VOLUNTEERS.SELF_ASSIGN.post({
        urlArgs: { id: prospect_id },
        params: { pool_id },
        success: () => {
          Utils.wait(1000, () => {
            this.fetch(prospect_id);
            this.actions.change(pool_id);
          });
          AlertActions.launchToast({
            message: "Successfully Assigned Prospect",
            type: "success",
            timeout: alertTimeout,
          });
        },
        error: (error) => {
          ErrorLogger.captureRequest("error self assigning", error);
          AlertActions.launchToast({ message: "Error Assigning Prospect", type: "error", timeout: alertTimeout });
          this.actions.loading(prospect_id, false);
        },
      });
    },

    removeAssign(pool_id, prospect_id) {
      this.actions.loading(prospect_id, true);

      Api.VOLUNTEERS.SELF_ASSIGN.delete({
        urlArgs: { id: prospect_id },
        params: { pool_id },
        success: () => {
          Utils.wait(1000, () => {
            this.fetch(prospect_id);
            this.actions.change(pool_id);
          });
          AlertActions.launchToast({
            message: "Assignment successfully removed.",
            type: "success",
            timeout: alertTimeout,
          });
        },
        error: (error) => {
          ErrorLogger.captureRequest("error removing assignment", error);
          AlertActions.launchToast({
            message: "Error removing assignment, please try again.",
            type: "error",
            timeout: alertTimeout,
          });
        },
      });
    },
    optimisticUpdateAssignment(id, updated) {
      this.actions.optimisticUpdateAssignment(id, updated);
    },
  },
});

export default ProspectAssignmentsSource;
