import { createStore } from "@evertrue/et-flux";
import _ from "underscore";
import StageSource from "./stage-source";

const INITIAL_STATE = () => ({
  stage_groups: {},
  fetch_stage_groups_error: false,
  group_ids_have_errored: new Set(),
  loading: false,
});

const StageStore = createStore("StageStore", {
  getInitialState() {
    return INITIAL_STATE();
  },

  registerActions() {
    this.on(StageSource.actions.fetchedStageGroups, stages => {
      this.setState({ stage_groups: stages, loading: false });
    });

    this.on(StageSource.actions.fetchStageGroupsError, () => {
      this.setState({ fetch_stage_groups_error: true });
    });
    this.on(StageSource.actions.fetchStageGroupsUnlessCached, () => {
      if (_.isEmpty(this.state.stage_groups) && !this.state.fetch_stage_groups_error) {
        this.setState({ loading: true });
        StageSource.fetchStageGroups();
      }
    });
    this.on(StageSource.actions.fetchGroupUnlessCached, id => {
      if (id && !this.state.stage_groups[id] && !this.state.group_ids_have_errored.has(id)) {
        StageSource.fetchStageGroupById(id);
      }
    });
    this.on(StageSource.actions.fetchStageGroupsError, id => {
      const current = new Set(this.state.group_ids_have_errored);
      current.add(id);
      this.setState({ group_ids_have_errored: current, loading: false });
    });
  },

  api: {
    getStageGroups() {
      return this.state.stage_groups;
    },
    getLoading() {
      return this.state.loading;
    },
  },
});

export default StageStore;
