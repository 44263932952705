import React from "react";
import classNames from "classnames";
import RespondTo from "components/respond-to";
import PropTypes from "prop-types";

class Module extends React.Component {
  static propTypes = {
    respondTo: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    return (
      <RespondTo to={this.props.respondTo} className={classNames("module", this.props.className)}>
        {this.props.children}
      </RespondTo>
    );
  }
}
export default Module;
