const _ = require("underscore");
const React = require("react");
const Components = require("@evertrue/et-components");

/* 
* DEPRACATED Do Not Use. just use import {Modal} from "@evertrue/et-components"
*/

const ComponentFactories = _.mapObject(Components, component => React.createFactory(component));
module.exports = ComponentFactories;
