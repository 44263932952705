import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import Navigator from "utils/navigator";
import OrgSource from "apps/auth/sources/org-source";
import MagicLinkSource from "core/apps/auth/magic-link-source";

// Sets the token or oid from the URL when they are changed
// Is handled in a component to avoid being called in render
// which causes issues with setState().
class SetGlobalParams extends React.Component {
  static propTypes = {
    params: PropTypes.object,
  };

  componentDidMount() {
    this.handleSetGlobalParams(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(newProps.params, this.props.params)) {
      this.handleSetGlobalParams(newProps);
    }
  }

  handleSetGlobalParams = props => {
    const { oid, token, email } = props.params;
    // if token and email were passed in as the very first params,
    // try logging into volunteers with the magic link token and allow the password for this email to be reset
    if (token && email) {
      MagicLinkSource.setMagicToken(token);
      let new_path = Navigator.getAccountsUrl();
      const encoded_email = encodeURIComponent(email);
      new_path += `/volunteers/account/reset_email_password/?magic_token=${token}&email=${encoded_email}`;
      window.location = new_path;
      // else if just the token, go right to magic link login
    } else if (token) {
      Navigator.loginWithMagicToken(oid, token);
    }
    if (oid) {
      OrgSource.setCurrentOrg(oid);
    }
  };

  render() {
    return null;
  }
}

export default SetGlobalParams;
