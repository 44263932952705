import Decorator from "@evertrue/client-decorator";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import numeral from "numeral";
import _ from "underscore";
import Tracker from "utils/tracker";
import classNames from "classnames";
import ProspectAvatar from "apps/prospects/components/prospect-avatar";
import PoolLink from "apps/pools/components/pool-link";
import ProfileAmount from "apps/profile/components/profile-ask-amount";
import { DNAContext } from "core/apps/dna/dna-context";
import { Route } from "react-router-dom";
import get from "lodash.get";
import { Icon } from "@evertrue/et-components";
import useGate from "core/apps/gates/use-gate";

const { getFullName } = Decorator.Contacts;

const ProspectCard = ({
  pool = {},
  primary = {},
  secondary = undefined,
  assignedTo = {},
  assignment = {},
  ...props
}) => {
  const { assignment_stage } = assignment;
  const primary_year = Decorator.Contacts.getClassYear(primary);

  let year_display = primary_year;

  if (secondary) {
    if (year_display) {
      const secondary_year = get(secondary, "properties.year.value");
      if (secondary_year) {
        year_display += ` and ${secondary_year}`;
      }
    } else {
      year_display = Decorator.Contacts.getClassYear(secondary);
    }
  }

  const is_deceased = get(primary, "properties.deceased.value");
  const is_secondary_deceased = get(secondary, "contact.properties.deceased.value");
  const show_deceased_tag = is_deceased && !get(secondary, "display_name");
  const is_recurring_feature_enabled = useGate({ gate: "recurring_payment_field" });

  const handleTrack = (contact_id, tab) => {
    if (contact_id) {
      Tracker.send("profile_viewed", {
        contact_id,
        is_assignment: tab !== "prospects",
      });
    }
  };

  const renderGiving = fiscal_year => {
    const giving = Decorator.Giving.getVolunteerProfileGiving(pool, primary, fiscal_year) || {};
    const { last_gift_amount, last_gift_date } = giving;
    const formatted_last_date = last_gift_amount ? moment(new Date(last_gift_date)).format("MMM, DD YYYY") : null;
    const formatted_last_gift = last_gift_date ? numeral(last_gift_amount).format("$0a") : null;

    let last_gift;
    if (formatted_last_gift) {
      last_gift = (
        <div>
          <div className="prospect-card--gift">
            <span className="prospect-card--label">Last Gift: </span>
            <span className="prospect-card--value">
              {formatted_last_gift}
              {formatted_last_date ? ` on ${formatted_last_date}` : null}
              {is_recurring_feature_enabled && giving.has_recurring_payments && (
                <Icon icon="recurring" className="prospect-card--recurring" />
              )}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div>
        {last_gift || null}
        <div className="prospect-card--ask">
          <span className="prospect-card--label">Suggested Ask: </span>
          <ProfileAmount amount={assignment.ask_amount_in_cents} show_icon />
        </div>
      </div>
    );
  };

  const renderAssignee = () => {
    const solicitor = assignedTo;
    let name = "Unassigned";
    if (assignment.solicitor_contact_id) {
      name = solicitor.properties ? Decorator.Contacts.getFullName(solicitor) : "Volunteer";
    }

    return (
      <div className="prospect-card--assignment">
        <span className="prospect-card--label">Assigned to: </span>
        <span className="prospect-card--value">{name || "Unassigned"}</span>
      </div>
    );
  };

  return (
    <DNAContext.Consumer>
      {({ keys }) => {
        const fiscal_year = keys["ET.Org.FiscalYear"];
        const has_given_last_fy = Decorator.Giving.getVolunteerHasGivenLastFY(pool, primary, fiscal_year);

        return (
          <Route
            path="/pools/:pool_id/:tab"
            render={({ match: { params } }) => {
              const { tab } = params;

              return (
                <PoolLink
                  key={primary.id}
                  to={`/${primary.id}`}
                  className={classNames("prospect-card", props.className)}
                  onClick={() => {
                    handleTrack(primary.id, tab);
                  }}
                >
                  <div>
                    <ProspectAvatar prospect={primary} secondary={secondary} />

                    <div className="prospect-card--content">
                      <div className="prospect-card--name">
                        <span className={is_deceased ? "is-deceased" : ""}>{getFullName(primary)}</span>

                        {secondary && (
                          <span className={is_secondary_deceased ? "is-deceased" : ""}>| {getFullName(secondary)}</span>
                        )}
                        {show_deceased_tag && <span className="deceased-tag">Deceased</span>}
                      </div>
                      {year_display ? <div className="prospect-card--class">{year_display}</div> : null}
                      <div className="prospect-card--data">
                        {tab !== "prospects" ? renderGiving(fiscal_year) : renderAssignee()}
                      </div>
                      {!!pool.stage_group_id && (
                        <div className="prospect-card--stage">
                          <span className="prospect-card--label">Stage: </span>
                          {assignment_stage ? (
                            <span className="prospect-card--stage-name">{assignment_stage}</span>
                          ) : (
                            <span className="text-placeholder">None Provided</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="prospect-card--icons">
                    {!!has_given_last_fy && <Icon className="prospect-card--given-tag" size={2} icon="given-tag" />}
                    {!!assignment.key_prospect && (
                      <Icon icon="key-relationship" size={2} className="prospect-card--key-tag" />
                    )}
                  </div>
                </PoolLink>
              );
            }}
          />
        );
      }}
    </DNAContext.Consumer>
  );
};

ProspectCard.propTypes = {
  pool: PropTypes.object,
  primary: PropTypes.object,
  secondary: PropTypes.object,
  assignedTo: PropTypes.object,
  assignment: PropTypes.object,
};

export default ProspectCard;
