import React from "react";
import { Loading } from "@evertrue/et-components";
import PropTypes from "prop-types";
import OrgSource from "apps/auth/sources/org-source";
import Navigator from "utils/navigator";

class AuthTokenSsoController extends React.Component {
  static propTypes = {
    magic_token: PropTypes.string,
    oid: PropTypes.string,
  };

  componentDidMount() {
    const { magic_token, oid } = this.props;
    if (magic_token) {
      Navigator.loginWithMagicToken(oid, magic_token);
    } else if (oid) {
      OrgSource.getSlugAndStartAuth(oid, magic_token);
    }
  }

  render() {
    return <Loading />;
  }
}

export default AuthTokenSsoController;
