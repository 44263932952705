import React from "react";
import { Icon } from "@evertrue/et-components";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

export default class extends React.Component {
  static displayName = "MobileHeader";

  static propTypes = {
    icon: PropTypes.string,
    label: PropTypes.node,
    iconLink: PropTypes.string,
    onIconClick: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = { icon: "arrow-left" };

  render() {
    return (
      <div className={classNames("mobile-header", this.props.className)}>
        {this.props.iconLink ? (
          <Link to={this.props.iconLink} className="mobile-header--icon">
            <Icon icon={this.props.icon} />
          </Link>
        ) : (
          <Icon icon={this.props.icon} className="mobile-header--icon" onClick={this.props.onIconClick} />
        )}
        {this.props.children}
      </div>
    );
  }
}
