import _ from "underscore";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import ContactsStore from "core/apps/contacts/contacts-store";
import ContactsSource from "core/apps/contacts/contacts-source";

const ContactsResolver = createComponent("ContactsResolver", {
  propTypes: {
    ids: PropTypes.array,
    poolId: PropTypes.number,
    render: PropTypes.func,
  },

  getDefaultProps() {
    return {
      ids: [],
      render: _.noop,
    };
  },

  registerStores() {
    this.on(ContactsStore, "ids", props => {
      return {
        contact_store_contacts: ContactsStore.getForIds(_.compact(props.ids)),
        contact_store_loading: ContactsStore.getLoading(),
      };
    });
  },

  componentDidMount() {
    const ids = _.compact(this.props.ids);
    if (!_.isEmpty(ids)) {
      ContactsSource.fetchUnlessCached(this.props.poolId, ids);
    }
  },

  componentWillReceiveProps(newProps) {
    const old_ids = _.compact(this.props.ids);
    const new_ids = _.compact(newProps.ids);

    if (!_.isEmpty(_.difference(new_ids, old_ids))) {
      ContactsSource.fetchUnlessCached(newProps.poolId, new_ids);
    }
  },

  render() {
    return this.props.render({
      loading: this.state.contact_store_loading,
      contacts: this.state.contact_store_contacts,
    });
  },
});

export default ContactsResolver;

