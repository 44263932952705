import _ from "underscore";
import Decorator from "@evertrue/client-decorator";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { Avatar } from "@evertrue/et-components";

const ProspectPropTypes = {
  prospect: PropTypes.object,
  secondary: PropTypes.object,
  size: PropTypes.number,
};

const ContactAvatar = ({ contact, size, className }) => {
  if (!contact) {
    return null;
  }

  const [first_name = "", last_name = ""] = Decorator.Contacts.getFullName(contact).split(" ");
  const first_initial = first_name.slice(0, 1).toUpperCase();
  const last_initial = last_name.slice(0, 1).toUpperCase();
  const initials = `${_.first(first_initial) || ""}${_.last(last_initial) || ""}`;

  return <Avatar className={className} url={contact.avatar} emptyState={initials} width={size} />;
};

const ProspectAvatar = ({ prospect, secondary, width }) => {
  const size = width || (secondary ? 45 : 54);

  return (
    <div className={classNames("prospect-card--avatars", { "is-joint": !!secondary })}>
      <ContactAvatar contact={prospect} size={size} className="prospect-card--primary-avatar" />
      <ContactAvatar contact={secondary} size={size} className="prospect-card--secondary-avatar" />
    </div>
  );
};

ProspectAvatar.propTypes = ProspectPropTypes;
export default ProspectAvatar;
