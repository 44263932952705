/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";

import React from "react";
import Config from "config/env";
import { createComponent } from "@evertrue/et-flux";
import { div, a } from "react-dom-factories";
import { EmptyStateMessage } from "components/et-factories";
import OrgStore from "apps/auth/stores/org-store";
import SessionStore from "apps/auth/stores/session-store";

const PoolCard = React.createFactory(require("apps/pools/components/pool-card").default);

export default createComponent("PoolList", {
  propTypes: {
    pools: PropTypes.array,
    selected: PropTypes.number,
  },

  registerStores() {
    return this.on(OrgStore, () => ({ org: OrgStore.getCurrentOrg() }));
  },

  render() {
    return div(
      { className: "pool--list" },
      _.isEmpty(this.props.pools)
        ? EmptyStateMessage(
            {
              icon: "group",
              text: "No Pools Found",
            },
            "You have not been assigned any prospects. ",
            SessionStore.isGivingTreeUser(this.state.org != null ? this.state.org.id : undefined)
              ? div(
                  null,
                  "Manage volunteers and assignments in ",
                  a({ href: Config.evertrueUrl }, "EverTrue for Advancement."),
                )
              : "Contact your school for assistance.",
          )
        : div(
            null,
            _.map(this.props.pools, pool => {
              return PoolCard({
                key: pool.id,
                pool,
                className: pool.id === this.props.selected ? "is-selected" : undefined,
              });
            }),
          ),
    );
  },
});
