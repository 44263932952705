import _ from "underscore";
import Api from "base/api";
import Utils from "core/utils/utils";
import { createSource } from "@evertrue/et-flux";
import Decorator from "@evertrue/client-decorator";
import ErrorLogger from "utils/error-logger";

const ContactsSource = createSource("ContactsSource", {
  actions: {
    cache: true,
    loadingAssignments: true,
    fetched(items) {
      this.require(_.isObject(items), "items should be an object");
    },
    idsHasFetched(ids) {
      this.require(_.isArray(ids), "ids should be an array");
    },
    fetchUnlessCached(pool_id, ids) {
      this.require(_.isNumber(pool_id), "pool_id should be a number");
      this.require(_.isArray(ids), "ids should be an array");
    },
    failedToFetch(ids) {
      this.require(_.isArray(ids), "ids should be an array");
    },
  },

  mapIdsToResults(input_ids, results) {
    const obj = {};
    _.each(input_ids, id => {
      obj[id] = _.findWhere(results, { id });
    });
    return obj;
  },

  api: {
    cache(contacts) {
      this.actions.cache(Utils.makeArray(_.compact(contacts)));
    },

    fetch(pool_id, ids) {
      if (!Utils.isNumber(pool_id)) return;
      return Api.VOLUNTEERS.SEARCH.post({
        params: { pool_id },
        data: {
          must: [{ id: { in: _.compact(_.uniq(ids)) } }],
        },
        success: ({ items }) => {
          this.actions.idsHasFetched(ids);
          this.actions.fetched(this.mapIdsToResults(ids, _.map(items, Decorator.Contacts.parse)));
        },
        error: error => {
          this.actions.failedToFetch(ids);
          ErrorLogger.captureRequest("fetch contacts error", error);
        },
      });
    },

    fetchSingle(id) {
      return Api.CONTACTS.SINGLE.get({
        urlArgs: { id },
        success: resp => {
          this.actions.idsHasFetched([id]);
          this.actions.fetched(this.mapIdsToResults([id], _.map([resp], Decorator.Contacts.parse)));
        },
        error: error => {
          this.actions.failedToFetch(id);
          ErrorLogger.captureRequest("fetch single contact error", error);
        },
      });
    },

    fetchUnlessCached(pool_id, id) {
      if (id) {
        const ids_arr = Array.isArray(id) ? id : [id];
        this.actions.fetchUnlessCached(pool_id, _.map(ids_arr, _.toNumber));
      }
    },
  },
});

export default ContactsSource;
