import PropTypes from "prop-types";
import _ from "underscore";
import React from "react";
import { createComponent } from "@evertrue/et-flux";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import PoolStore from "apps/pools/pool-store";
import PoolList from "apps/pools/components/pool-list";
import PoolRedirects from "apps/pools/pool-redirects";
import ProfileController from "apps/profile/controllers/profile-controller";
import PoolContactsController from "apps/prospects/controllers/pool-contacts-controller";
import { Loading } from "@evertrue/et-components";
import { Switch, Route } from "react-router-dom";
import PoolReportController from "./pool-reporting-controller";

const LAYOUT_CLASS = "panel-layout";
const LAYOUT_CLASS_MASTER = "panel-layout--master";
const LAYOUT_CLASS_DETAIL = "panel-layout--detail";

export default createComponent("PoolDesktopLayoutController", {
  propTypes: {
    pool_id: PropTypes.number,
    tab: PropTypes.string,
    prospect_id: PropTypes.number,
    profile_section: PropTypes.string,
  },

  registerStores() {
    this.on(PoolStore, "pool_id", props => ({
      pool: PoolStore.getById(props.pool_id),
      pools: PoolStore.getPools(),
    }));

    return this.on(ResponsiveStore, () => ({
      is_tablet: ResponsiveStore.isTablet(),
      is_collapsed: ResponsiveStore.isPoolCollapsed(),
    }));
  },

  render() {
    const { pool_id, tab, prospect_id, profile_section } = this.props;
    const no_pools = _.isEmpty(this.state.pools);

    let pool_panel_width = this.state.is_collapsed ? 78 : 324;
    if (no_pools) {
      pool_panel_width = "100%";
    }

    return (
      <div className={LAYOUT_CLASS + " pool"}>
        <PoolRedirects pool_id={pool_id} tab={tab} prospect_id={prospect_id} />
        {
          // POOLS
          <div className={LAYOUT_CLASS_MASTER} style={{ width: pool_panel_width }}>
            {!this.state.is_collapsed ? (
              <div onClick={this.handleToggleNav}>
                <PoolList pools={this.state.pools} selected={pool_id} />
              </div>
            ) : (
              undefined
            )}
          </div>
        }
        {!no_pools ? (
          <div className={LAYOUT_CLASS_DETAIL} style={{ left: pool_panel_width }}>
            <Switch>
              <Route
                path="/pools/:pool_id/reporting"
                render={() => {
                  return <PoolReportController pool={this.state.pool} />;
                }}
              />
              <Route
                path="/pools/:pool_id"
                render={() => {
                  return this.state.loading ? (
                    <Loading />
                  ) : (
                    <div className={LAYOUT_CLASS + " prospects"}>
                      <div className={LAYOUT_CLASS_MASTER}>
                        <PoolContactsController
                          selected={prospect_id}
                          tab={tab}
                          pool={this.state.pool}
                          key={this.state.pool.id}
                        />
                      </div>
                      <div className={LAYOUT_CLASS_DETAIL}>
                        <ProfileController
                          id={prospect_id}
                          tab={tab}
                          pool={this.state.pool}
                          section={profile_section}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            </Switch>
          </div>
        ) : (
          undefined
        )}
      </div>
    );
  },
});
