import Config from "config/env";
import Storage from "utils/storage";
import Cookies from "js-cookie";
import { SESSION_KEY, OID_KEY } from "core/apps/auth/constants";

const default_auth_headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Authorization-Auto-Send": 0,
  "Authorization-Multifactor": 1,
  "Application-Key": Config.appKey,
};

const skiff_url = Config.apiBase + "skiff";
const auth_session_url = Config.apiBase + "auth/session";

const baseFetch = ({ method, endpoint, settings, headers }) => {
  return fetch(endpoint, {
    method,
    headers: { ...default_auth_headers, ...headers },
    ...settings,
  })
    .then(res => {
      if (!res.ok) return Promise.reject();
      return res;
    })
    .then(r => r.json());
};

export const createScopedSession = ({ oid }) => {
  return baseFetch({
    method: "POST",
    endpoint: skiff_url,
    headers: { "Authorization-Provider": "EvertruePrimeToken" },
    settings: {
      credentials: "include",
      body: JSON.stringify({ type: "SCOPED", app_key: Config.appKey, oid }),
    },
  });
};

export const createUnscopedSession = () => {
  return baseFetch({
    method: "POST",
    endpoint: skiff_url,
    headers: { "Authorization-Provider": "EvertruePrimeToken" },
    settings: { credentials: "include" },
  });
};

export const refreshSession = token => {
  return baseFetch({
    method: "GET",
    endpoint: auth_session_url,
    headers: { "Authorization-Provider": "EvertrueAuthToken", Authorization: token },
  });
};
export const wipeSession = () => {
  Storage.remove(SESSION_KEY);
  Storage.remove(OID_KEY);
  Cookies.remove("gt_oid");
  Storage.remove("magic_token");
  Storage.remove("user_id");
  Storage.remove("token");
};
