import React from "react";
import { Icon } from "components/et-factories";
import PropTypes from "prop-types";
import RespondTo from "components/respond-to";
import utils from "../../../utils/util-methods";

// This expects an amount in cents which is then converted into dollars.
const ProfileAmount = props => {

  const amount_in_cents = props.amount;

  return (
    <RespondTo className="profile-ask-amount fs-exclude-data">
      {amount_in_cents !== undefined &&
        amount_in_cents !== null ? (
        <span className="profile-ask-amount--section">
          {props.show_icon && <Icon className="profile-ask-amount--ask-icon" icon="volunteer-ask-amount" size={1} />}
          <strong className="profile-ask-amount--ask-value">{utils.formatMoney(amount_in_cents/100)}</strong>
        </span>
      ) : (
        <span className="text-placeholder">none</span>
      )}
    </RespondTo>
  );
};
ProfileAmount.propTypes = {
  show_icon: PropTypes.bool,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ProfileAmount;
