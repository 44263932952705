import React, { Component } from "react";
import _ from "underscore";

class NumberRange extends Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: {},
  };

  handleKeyPress(e) {
    if (/[a-z]/i.test(e.target.value)) {
      return e.preventDefault();
    }
    return null;
  }

  handleChange(e, prop, val) {
    const input_number = _.toNumber(val);
    let val_obj = _.clone(this.props.value) || {};
    if (input_number) {
      val_obj[prop] = input_number;
    } else {
      val_obj = _.omit(val_obj, prop);
    }
    this.props.onChange(val_obj);
  }

  render() {
    return (
      <div className="year-range">
        <input
          type="text"
          value={this.props.value.gte || ""}
          className="year-range--min"
          onKeyPress={this.handleKeyPress}
          onChange={e => this.handleChange(e, "gte", e.target.value)}
        />
        <div className="year-range--dash" />
        <input
          type="text"
          value={this.props.value.lte || ""}
          className="year-range--max"
          onKeyPress={this.handleKeyPress}
          onChange={e => this.handleChange(e, "lte", e.target.value)}
        />
      </div>
    );
  }
}

export default NumberRange;
