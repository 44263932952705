import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Logo = props => (
  <Link to="/" className={classNames("logo", props.className)}>
    {!props.hideIcon ? (
      <img src="/images/logo-icon-volunteers.png" className="logo--icon" alt="Volunteers by EverTrue" />
    ) : (
      undefined
    )}
    {!props.hideText ? (
      <img src="/images/logo-text-volunteers.png" className="logo--text" alt="Volunteers by EverTrue" />
    ) : (
      undefined
    )}
  </Link>
);

Logo.propTypes = {
  className: PropTypes.string,
  hideText: PropTypes.bool,
  hideIcon: PropTypes.bool,
};

export default Logo;
