import React from "react";
import { Icon, IconButton, ModalTrigger2, PlainButton, Popover2 } from "@evertrue/et-components";
import { Link } from "react-router-dom";
import { PoolDataResolver } from "@evertrue/vms-insights";
import { connect } from "@evertrue/et-flux";
import CopyButton from "components/copy-button";
import Utils from "core/utils/utils";
import PropTypes from "prop-types";
import classnames from "classnames";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import colors from "utils/colors";

const { coral500 } = colors;

const mapStateToProps = () => ({ isMobile: ResponsiveStore.isMobile() });
// we aren't blocking rendering based on org fetching for the header,
// so bind to it and block or PoolDataResolver will kick off API call w/ no oid
const propTypes = {
  pool_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isMobile: PropTypes.bool,
  poolName: PropTypes.string,
  isUserLeadVol: PropTypes.bool,
};

// eslint-disable-next-line react/prop-types
const TriggerButton = React.forwardRef(({ onClick = () => {}, isOpen }, ref) => (
  <IconButton
    ref={ref}
    title="Pool Actions"
    className={classnames("pool-links-dropdown--trigger", { "is-active": isOpen })}
    size={1}
    icon="more-horiz"
    onClick={onClick}
  />
));

const PoolCardDropdown = ({ pool_id, isMobile, poolName, isUserLeadVol }) => {
  return (
    <PoolDataResolver
      poolId={Utils.toNumber(pool_id)}
      render={({ poolData = {} }) => {
        const options = [
          poolData.giving_page_url && {
            label: "Giving Link",
            value: Utils.addHttp(poolData.giving_page_url),
            icon: "givingpage",
          },
          poolData.resource_url && {
            label: "Resource Management",
            value: Utils.addHttp(poolData.resource_url),
            icon: "trip-log",
          },
        ].filter(Boolean);
        if (!options.length) return null;

        return isMobile ? (
          <ModalTrigger2
            overlayClassName="pool-card-dropdown--mobile-overlay"
            contentClassName="pool-card-dropdown--mobile-modal"
            trigger={({ open }) => <TriggerButton onClick={open} />}
            disableDefaultStyles
          >
            {({ close }) => (
              <React.Fragment>
                <div className="pool-card-dropdown--mobile-container">
                  <div className="pool-card-dropdown--mobile-pool-name">{poolName}</div>
                  {options.map((opt) => (
                    <React.Fragment key={opt.label}>
                      <a
                        href={opt.value}
                        target="__blank"
                        rel="noopener noreferrer"
                        className="pool-card-dropdown--mobile-link"
                      >
                        Go to {opt.label}
                      </a>
                      <CopyButton
                        className="pool-card-dropdown--mobile-link"
                        style={{ color: coral500 }}
                        textToCopy={Utils.addHttp(opt.value)}
                      >
                        Copy {opt.label}
                      </CopyButton>
                    </React.Fragment>
                  ))}
                  {isUserLeadVol && (
                    <Link className="pool-card-dropdown--mobile-link" to={`/pools/${pool_id}/reporting`}>
                      Go To Reporting
                    </Link>
                  )}
                </div>
                <PlainButton
                  onClick={close}
                  className="pool-card-dropdown--mobile-link pool-card-dropdown--mobile-cancel"
                >
                  Cancel
                </PlainButton>
              </React.Fragment>
            )}
          </ModalTrigger2>
        ) : (
          <Popover2
            placement="bottom-end"
            trigger={({ togglePopover, is_open }) => <TriggerButton isOpen={is_open} onClick={togglePopover} />}
            children={({ closePopover }) => (
              <div className="pool-card-dropdown--content">
                {options.map((item) => {
                  return (
                    <div key={item.label} className="header--link-section">
                      <a
                        className="pool-card-dropdown--link"
                        href={item.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon size={1} className="header--link-icon" icon={item.icon} />
                        {item.label}
                      </a>

                      {
                        <CopyButton style={{ color: coral500 }} textToCopy={Utils.addHttp(item.value)}>
                          Copy
                        </CopyButton>
                      }
                    </div>
                  );
                })}
              </div>
            )}
          />
        );
      }}
    />
  );
};

PoolCardDropdown.propTypes = propTypes;

export default connect(PoolCardDropdown, [ResponsiveStore], mapStateToProps);
