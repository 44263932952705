/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from "react";

import Tracker from "utils/tracker";
import { createComponent } from "@evertrue/et-flux";
import { a, div } from "react-dom-factories";
import MagicLinkStore from "core/apps/auth/magic-link-store";
import Navigator from "utils/navigator";
import Storage from "utils/storage";
import MobileUtils from "utils/mobile-utils";
const RespondTo = React.createFactory(require("components/respond-to").default);
import Logo from "apps/layout/components/logo";
import { Icon, Button } from "components/et-factories";

const app_store_url = MobileUtils.getAppStoreLink();

export default createComponent("MobileBanner", {
  propTypes: {
    onClose: PropTypes.func,
  },

  getInitialState() {
    return { is_open: !Storage.get("hide_mobile_banner") };
  },

  registerStores() {
    return this.on(MagicLinkStore, () => ({ magic_token: MagicLinkStore.getMagicToken() }));
  },

  handleGoToMobile() {
    Tracker.send("mobile_action", { type: "open_app" });
    if (this.state.magic_token) {
      return Navigator.goToMobile("", `?token=${this.state.magic_token}`);
    } else {
      return Navigator.goToMobile("");
    }
  },

  handleCloseBanner() {
    this.setState({ is_open: false });
    return Storage.set("hide_mobile_banner", true);
  },

  render() {
    if ((window.ReactNativeWebView && window.ReactNativeWebView.postMessage) || !this.state.is_open) {
      return null;
    } else {
      return RespondTo(
        { to: "mobile", className: "mobile-banner 2" },
        a({ className: "mobile-banner--close", onClick: this.handleCloseBanner }, Icon({ icon: "close", size: 1 })),

        Logo({ hideText: true }),
        div(
          { className: "mobile-banner--content" },
          "Continue your experience in the browser or try out our mobile app!",

          div(
            { className: "mobile-banner--buttons" },
            Button(
              {
                href: app_store_url,
                className: "mobile-banner--download",
              },
              "Download App",
            ),

            Button(
              {
                className: "mobile-banner--open",
                onClick: this.handleGoToMobile,
              },
              "Open in App",
            ),
          ),
        ),
      );
    }
  },
});
