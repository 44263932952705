import PropTypes from "prop-types";
import React from "react";
import { createComponent } from "@evertrue/et-flux";
import _ from "underscore";
import RespondTo from "components/respond-to";
import SessionStore from "apps/auth/stores/session-store";
import { Loading, Button, ModalTriggerDeprecated } from "@evertrue/et-components";
import ViewAssignmentCTA from "apps/profile/components/view-assignment-cta";
import OrgStore from "apps/auth/stores/org-store";
import Decorator from "@evertrue/client-decorator";
import Tracker from "utils/tracker";
import ProspectResolver from "apps/prospects/resolvers/prospect-resolver";
import ProspectAssignmentsSource from "core/apps/prospects/prospect-assignments-source";
import ProspectAssignmentsStore from "core/apps/prospects/prospect-assignments-store";

export default createComponent("ProfileAssignment", {
  propTypes: {
    contact: PropTypes.object,
    pool: PropTypes.object,
  },

  registerStores() {
    this.on(SessionStore, () => ({
      user: SessionStore.getUser(),
    }));
  },

  handleAssign({ assignment_id }) {
    const { contact, pool } = this.props;
    ProspectAssignmentsSource.selfAssign(pool.id, assignment_id);

    Tracker.send("assignment_action", {
      type: "assigned_to_me",
      pool_id: pool.id,
      contact_id: contact.id,
    });
  },

  handleUnassign({ assignment_id }) {
    const { contact, pool } = this.props;
    ProspectAssignmentsSource.removeAssign(pool.id, assignment_id);

    Tracker.send("assignment_action", {
      type: "unassigned_to_me",
      pool_id: pool.id,
      contact_id: contact.id,
    });
  },

  render() {
    const org_id = OrgStore.getCurrentOrg().id;
    const user_contact_id = Decorator.User.getContactId(this.state.user, org_id);

    return (
      <RespondTo className="profile-assignment">
        <ProspectResolver
          contacts={[this.props.contact]}
          pool={this.props.pool}
          render={({ prospects, loading }) => {
            const { primary, secondary, assignedTo, assignment = {} } = _.first(prospects) || {};
            const is_me = assignedTo && assignedTo.id === user_contact_id;
            const is_not_assigned = !assignment.solicitor_contact_id;

            const onAssign = () => {
              this.handleAssign(assignment);
            };
            const onUnassign = () => {
              this.handleUnassign(assignment);
            };

            const modal = (
              <ViewAssignmentCTA
                solicitor={assignedTo}
                contact={primary}
                secondary={secondary}
                onUnassign={onUnassign}
              />
            );

            if (loading || ProspectAssignmentsStore.getLoading(assignment.assignment_id)) {
              return <Loading />;
            }
            // lead vols can see prospect tab, but can't assign if self selection is off
            if (is_not_assigned && this.props.pool.has_self_selection) {
              return (
                <Button className="profile-assignment--assign-button" onClick={onAssign}>
                  Assign Prospect to me
                </Button>
              );
            }

            return (
              <div className="profile-assignment--assigned">
                <span className="profile-assignment--mobile-row">
                  <span>Assigned Volunteer Solicitor: </span>
                </span>
                <span className="profile-assignment--mobile-row">
                  <span className="profile-assignment--name">
                    {!_.isEmpty(assignedTo) ? Decorator.Contacts.getFullName(assignedTo) : "Volunteer"}
                  </span>
                  {is_me && !!assignment ? (
                    <ModalTriggerDeprecated modal={modal} className="profile-assignment-view-tag">
                      View
                    </ModalTriggerDeprecated>
                  ) : null}
                </span>
              </div>
            );
          }}
        />
      </RespondTo>
    );
  },
});
