import React from "react";
import { Route, Redirect } from "components/router";
import PropTypes from "prop-types";

const prop_types = { to: PropTypes.string };

const SafeRedirect = props =>
  Route({
    render({ location }) {
      if (!__guard__(location != null ? location.pathname : undefined, x => x.match(props.to))) {
        return Redirect({ to: props.to });
      } else {
        return null;
      }
    },
  });

SafeRedirect.propTypes = prop_types;
SafeRedirect.displayName = "SafeRedirect";
export default SafeRedirect;

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
