import _ from "underscore";
import numeral from "numeral";
import get from "lodash.get";

_.mixin({
  get,
  wait(time, func) {
    return _.delay(func, time);
  },

  cloneData(item) {
    // Functions and arrays return true for _.isObject(...)
    if (!_.isObject(item) || _.isFunction(item)) {
      return item;
    } else if (_.isArray(item)) {
      return _.map(item, _.cloneData);
    } else if (_.isObject(item)) {
      return _.mapObject(item, _.cloneData);
    } else {
      return item;
    }
  },

  compMap: _.compose(
    _.compact,
    _.map,
  ),

  toNumber(n) {
    return parseInt(n, 10);
  },

  compactObject(object) {
    const obj = {};
    _.each(object, function(value, key) {
      if (!_.isUndefined(value) && !_.isNull(value)) {
        return (obj[key] = value);
      }
    });
    return obj;
  },

  jsonStringify(data) {
    try {
      return JSON.stringify(data);
    } catch (error) {
      try {
        return JSON.stringify(`${data}`);
      } catch (error1) {
        return "";
      }
    }
  },

  jsonParse(data, fail_default) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return fail_default;
    }
  },

  makeArray(item) {
    if (_.isArray(item)) {
      return item;
    } else {
      return [item];
    }
  },
});

export default {
  regexEscape(str) {
    if (!str) {
      return;
    }
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }, // escape RegExp

  searchCompare(str, query) {
    return (
      !query ||
      (str != null
        ? str.toLowerCase().match(new RegExp(`\\b${this.regexEscape(query != null ? query.toLowerCase() : undefined)}`))
        : undefined)
    );
  },

  currencyFormatter(numString) {
    const num = numeral(numString).value();
    if (num >= 1000000) {
      return numeral(num).format("$0.00a");
    } else {
      return numeral(num).format("$0,0");
    }
  },

  getGivingValue(pool_giving_category, value) {
    if (pool_giving_category === "EverTrueNoData") {
      return "N/A";
    } else if (_.isNull(value)) {
      return "N/A";
    } else {
      return this.currencyFormatter(value);
    }
  },

  validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  },

  isMobile() {
    const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(window.navigator.userAgent);
  },

  isiOS() {
    const regex = /iPhone|iPad|iPod/i;
    return regex.test(window.navigator.userAgent);
  },

  isAndroid() {
    const regex = /Android/i;
    return regex.test(window.navigator.userAgent);
  },

  capitalizeString(string) {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  },

  getVolunteerRolesArray(org) {
    const role_names = ["Volunteer", "Volunteer Lead"];
    const org_roles = org.roles;

    return _.compact(
      _.map(role_names, function(role_name) {
        const role = _.findWhere(org_roles, { name: role_name });
        return role != null ? role.id : undefined;
      }),
    );
  },

  getPageFromResults(paged_object) {
    const { offset, limit, total } = paged_object;
    const page = offset / limit + 1;
    return Math.min(Math.max(1, page), Math.ceil(total / limit));
  },

  getTotalPagesFromResults(paged_object) {
    const { limit, total } = paged_object;
    return Math.ceil(total / limit);
  },

  formatMoney(num){
    return numeral(num).format("$0,0.[00]");
  },
};
