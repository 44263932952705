import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";
import { createComponent } from "@evertrue/et-flux";
import { ProspectsStore, MyProspectsStore } from "core/apps/prospects/prospects-store";
import { ProspectsSource, MyProspectsSource } from "core/apps/prospects/prospects-source";
import { Icon, SearchInput } from "@evertrue/et-components";
import classNames from "classnames";
import RespondTo from "components/respond-to";
import ProspectNav from "apps/prospects/components/prospect-nav";
import ProspectsList from "apps/prospects/components/prospects-list";
import ProspectFilters from "apps/prospects/components/prospect-filters";
import ResponsivePopover from "components/responsive-popover";
import { isLeadVol } from "core/utils/data-utils";
import IsGated from "core/apps/gates/is-gated";

export default createComponent("PoolContactsController", {
  propTypes: {
    pool: PropTypes.object,
    selected: PropTypes.number,
    tab: PropTypes.string,
  },

  getDefaultProps() {
    return { pool: {} };
  },

  getInitialState() {
    return { search_query: "" };
  },

  registerStores() {
    this.on(MyProspectsStore, "pool", ({ pool }) => ({
      assignments: {
        loading: MyProspectsStore.getLoading(),
        loadingInfinite: MyProspectsStore.getLoadingInfinite(),
        contacts: MyProspectsStore.getForPool(pool.id),
        filters: MyProspectsStore.getFilters() || {},
      },
    }));

    this.on(ProspectsStore, "pool", ({ pool }) => ({
      prospects: {
        loading: ProspectsStore.getLoading(),
        loadingInfinite: ProspectsStore.getLoadingInfinite(),
        contacts: ProspectsStore.getForPool(pool.id),
        filters: ProspectsStore.getFilters() || {},
      },
    }));
  },

  componentDidMount() {
    const { id } = this.props.pool;
    const is_lead_vol = isLeadVol(this.props.pool);

    MyProspectsSource.filter(id);
    // fetch prospects for the prospects tab if you're a lead volunteer or self selection is on
    // TODO: does the vms_head_agent gate need to be checked?
    if (this.props.pool.has_self_selection || is_lead_vol) {
      ProspectsSource.filter(id);
    }
  },

  getSource(tab = this.props.tab) {
    return tab === "prospects" ? ProspectsSource : MyProspectsSource;
  },

  handlePageChange(page) {
    const { id, giving_category_label } = this.props.pool;
    this.getSource().paginate(id, page, giving_category_label);
  },

  handleLoadMore() {
    const { id, giving_category_label } = this.props.pool;
    this.getSource().infiniteLoad(id, giving_category_label);
  },

  handleNameSearch(data, query) {
    const { id, giving_category_label } = this.props.pool;
    this.setState({ search_query: query });
    let filters;

    if (query) {
      filters = _.extend({}, data.filters, { full_name: query });
    } else {
      filters = _.omit(data.filters, "full_name");
    }

    this.getSource().filter(id, filters, giving_category_label);
  },

  handleApplyFilters(filters) {
    const { id, giving_category_label } = this.props.pool;
    const all_filters = filters;
    const prev_filters = this.state.prospects.filters || {};

    if (prev_filters.full_name) {
      all_filters.full_name = this.state.filters.full_name;
    }

    if (this.props.tab === "prospects") {
      ProspectsSource.filter(id, all_filters, giving_category_label);
    } else {
      MyProspectsSource.filter(id, all_filters, giving_category_label);
    }
  },

  renderFilters(handleClose) {
    const tab = this.props.tab;
    const filters = tab === "prospects" ? this.state.prospects.filters : this.state.assignments.filters;

    return (
      <ProspectFilters
        givingCategoryLabel={this.props.pool.giving_category_label}
        stageGroupId={this.props.pool.stage_group_id}
        isAssignmentsTab={tab === "assignments"}
        filters={filters}
        onApply={(filterOpts) => {
          this.handleApplyFilters(filterOpts);
          handleClose(filterOpts);
        }}
        onClose={handleClose}
      />
    );
  },
  clearSearchInput() {
    if (this.state.search_query) {
      this.handleNameSearch(this.state.assignments, "");
    }
  },

  render() {
    const { pool, tab, selected } = this.props;
    const has_self_selection = pool.has_self_selection;

    let data = this.state.assignments;
    if (tab === "prospects") {
      data = this.state.prospects;
    }

    const filter_count = _.size(_.omit(data.filters, "assigned_to_me", "full_name"));
    const is_lead_vol = isLeadVol(pool);

    return (
      <RespondTo className="pool-contacts panel-layout">
        <IsGated gate="vms_head_agent">
          {({ show }) => {
            if (has_self_selection || (show && is_lead_vol)) {
              return <ProspectNav clearSearchInput={this.clearSearchInput} key={pool.id} poolId={pool.id} />;
            } else if (!show && tab === "prospects" && pool.id) {
              return <Redirect to={`/pools/${pool.id}/assignments`} />;
            }
            return null;
          }}
        </IsGated>

        <RespondTo className="pool-contacts--body">
          <div className="prospects--container">
            <div className="prospect-list--search">
              <SearchInput
                className="fs-exclude-data"
                debounceTimeout={300}
                query={data.filters.full_name || ""}
                onChange={(query) => {
                  this.handleNameSearch(data, query);
                }}
                value={this.state.search_query}
                onClear={() => this.handleNameSearch(data)}
              />

              <ResponsivePopover triggerStyles={{ position: "relative", marginLeft: 8 }} render={this.renderFilters}>
                {({ is_open }) => (
                  <>
                    <Icon
                      className={classNames("prospect-list--filter-trigger", { "is-open": is_open })}
                      icon="filter-list"
                      size={1}
                    />
                    {filter_count > 0 && <div className="prospect-list--filter-count">{filter_count}</div>}{" "}
                  </>
                )}
              </ResponsivePopover>
            </div>

            <ProspectsList
              pool={pool}
              view={tab}
              selected={selected}
              loading={data.loading}
              loadingInfinite={data.loadingInfinite}
              prospects={data.contacts}
              hasFilters={filter_count > 0}
              onPageChange={this.handlePageChange}
              onLoadMore={this.handleLoadMore}
            />
          </div>
        </RespondTo>
      </RespondTo>
    );
  },
});
