import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { DNAContext } from "core/apps/dna/dna-context";

const getFiscalYearStart = fiscalYear => {
  const fiscal_start = fiscalYear || "07-01";
  const fiscal_date = `${moment().year()}-${fiscal_start}`;
  const date = moment(fiscal_date);

  return date.isAfter() ? date.subtract(1, "years").format("YYYY-MM-DD") : fiscal_date;
};

const getFiscalYearEnd = fiscalYearStart => {
  return moment(fiscalYearStart)
    .add(1, "years")
    .format("YYYY-MM-DD");
};

const DnaConnector = props => {
  return (
    <DNAContext.Consumer>
      {({ keys }) => {
        const fiscal_year = keys["ET.Org.FiscalYear"];
        const fiscal_year_start = getFiscalYearStart(fiscal_year);
        return props.children({
          fiscalYear: fiscal_year,
          fiscalYearEnd: getFiscalYearEnd(fiscal_year_start),
          fiscalYearStart: fiscal_year_start,
        });
      }}
    </DNAContext.Consumer>
  );
};
DnaConnector.propTypes = {
  children: PropTypes.func.isRequired,
};

export default DnaConnector;
