import React from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { Icon, NumericInput, MobileSheet, ModalConfirm, FormManager } from "@evertrue/et-components";
import { AlertActions, alertTimeout } from "core/utils/alert-actions";
import Api from "core/base/api";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import StageSource from "core/apps/prospects/stage-source";
import colors from "utils/colors";
import MiniProspectCardWithStageSelector from "apps/profile/components/mini-prospect-card-with-stage-selector";
import StageSelector from "apps/profile/components/stage-selector";
import IsGated from "core/apps/gates/is-gated";
import ProspectAssignmentSource from "core/apps/prospects/prospect-assignments-source";

const mapStateToProps = () => ({ isMobile: ResponsiveStore.isMobile() });

const ACCENT = colors.cyan200;
const HEADER = "Update Committed Amount";
// eslint-disable-next-line
const getModalContent = ({ state, setState, contact, pool, children, isMobile }) => (
  <div className="committed-amount-modal">
    <IsGated
      gate="volunteers_stages"
      render={({ show }) => (
        <MiniProspectCardWithStageSelector
          stage={state.stage}
          contact={contact}
          pool={pool}
          hideStageSelector={!show}
          handleStageChange={(stage) => {
            setState({ stage });
          }}
        />
      )}
    />
    <label className="committed-amount-modal--label">
      Committed Amount: <Icon icon="committed" style={{ color: ACCENT }} size={1} />
      <NumericInput
        allowDecimal={true}
        className="committed-amount-modal--input"
        iconBackgroundColor={ACCENT}
        maskType="currency"
        value={state.amount}
        onChange={(amount) => setState({ amount })}
        showIcon
      />
    </label>
    {isMobile && (
      <div className="interaction-form--row">
        <label className="interaction-form--stage-label">Stage:</label>
        <StageSelector
          className="et--advanced-combobox-secondary"
          selectedStage={state.stage}
          stageGroupId={pool.stage_group_id}
          onChange={(stage) => setState({ stage })}
        />
      </div>
    )}
  </div>
);

const CommittedAmountModal = (props) => {
  return (
    <FormManager
      data={{ amount: props.assignment.committed_amount_in_dollars, stage: props.assignment.assignment_stage }}
      disableFunc={({ amount, stage }) =>
        amount === props.assignment.committed_amount_in_dollars && stage === props.assignment.assignment_stage
      }
      onSubmit={(data) => {
        // refetch triggered by websocket
        if (data.amount !== props.assignment.committed_amount_in_dollars) {
          Api.VOLUNTEERS.COMMITTED_AMOUNT.post({
            data: { committed_amount_in_dollars: data.amount },
            urlExtend: `/${props.assignment.assignment_id}`,
          }).then((response) => {
            AlertActions.launchToast({
              message: "Successfully updated committed amount.",
              type: "success",
              timeout: alertTimeout,
            });
            // we have websockets for this but not reliable b/c of es delay
            ProspectAssignmentSource.optimisticUpdateAssignment(props.assignment.assignment_id, {
              ...props.assignment,
              committed_amount_in_dollars: response.committed_amount_in_dollars,
            });
          });
        }
        if (data.stage !== props.assignment.assignment_stage) {
          StageSource.updateStage(props.assignment.assignment_id, data.stage);
        }

        props.close();
      }}
    >
      {({ state, setState, handleSubmit, isDisabled }) => {
        const modalContent = getModalContent({
          state,
          setState,
          contact: props.contact,
          pool: props.pool,
          selectedStage: state.stage,
          isMobile: props.isMobile,
        });
        return props.isMobile ? (
          <MobileSheet
            className="responsive-modal is-mobile"
            actions={[
              {
                label: "Cancel",
                className: "mobile-sheet--cancel-button",
                onClick: props.close,
              },
              {
                label: "Save",
                disabled: isDisabled,
                onClick: handleSubmit,
                className: "mobile-sheet--save-button",
              },
            ]}
            label={HEADER}
            onBack={props.close}
            children={modalContent}
          />
        ) : (
          <ModalConfirm
            style={{ width: "440px" }}
            header={HEADER}
            onCancel={props.close}
            closeModal={props.close}
            disableSave={isDisabled}
            children={modalContent}
            onSubmit={handleSubmit}
          />
        );
      }}
    </FormManager>
  );
};

CommittedAmountModal.propTypes = {
  contact: PropTypes.object.isRequired,
  pool: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

CommittedAmountModal.defaultProps = {
  contact: {},
  pool: {},
  assignment: {},
};

export default connect(CommittedAmountModal, [ResponsiveStore], mapStateToProps);
