import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import Utils from "core/utils/utils";
import InteractionSource from "./interaction-source";

const FILTERS = {
  interaction_type(value) {
    return [
      {
        "interaction_type.untouched": { in: _.isArray(value) ? value : [value] },
      },
    ];
  },
};

const InteractionStore = createStore("InteractionStore", {
  getInitialState() {
    return {
      contact_interactions: {},
      loading: false,
      loading_more: false,
      filters: {},
    };
  },

  registerActions() {
    this.on(InteractionSource.actions.loading, this.respondToLoading);
    this.on(InteractionSource.actions.fetchedInteractions, this.respondToFetched);
    this.on(InteractionSource.actions.interactionUpdated, this.respondToInteractionUpdated);
    this.on(InteractionSource.actions.interactionDeleted, this.respondToInteractionDeleted);

    this.on(InteractionSource.actions.fetch, this.fetchWithQuery);
    this.on(InteractionSource.actions.filter, this.respondToFilter);
    this.on(InteractionSource.actions.paginate, this.respondToPaginate);
  },

  respondToLoading(loading) {
    this.setState({ loading });
  },

  respondToFetched(contact_id, resp) {
    const interactions = _.clone(this.getState("contact_interactions"));
    if (contact_id) {
      if (!interactions[contact_id] || resp.offset === 0) {
        interactions[contact_id] = resp;
      } else {
        _.extend(interactions[contact_id], _.omit(resp, "items"));
        interactions[contact_id].items = [...interactions[contact_id].items, ...resp.items];
      }
    }

    // We've retrieved the new interactions (or new page of interactions)
    // So set loading_more to false, since it's not longer loading
    this.setState({ contact_interactions: interactions, loading_more: false });
  },

  respondToInteractionUpdated(contact_id) {
    this.fetchWithQuery(contact_id, { offset: 0 });
  },

  respondToInteractionDeleted(contact_id) {
    this.fetchWithQuery(contact_id, { offset: 0 });
  },

  respondToFilter(contact_id, filters) {
    this.setState({ filters });
    this.fetchWithQuery(contact_id, { offset: 0 });
  },

  respondToPaginate(contact_id) {
    const interactions = Utils.cloneData(this.getState("contact_interactions")[contact_id]);
    const params = _.pick(interactions, "offset", "limit");
    params.offset += params.limit;
    this.fetchWithQuery(contact_id, params);
  },

  fetchWithQuery(contact_id, page) {
    const interactions = Utils.cloneData(this.getState("contact_interactions"));
    const contact_interactions = interactions[contact_id];

    const cached_params = _.pick(contact_interactions, "offset", "limit");
    const params = _.extend({ limit: 10, offset: 0 }, cached_params, page);

    // In this case we want to show a "loading more" spinner
    // instead of a full spinner, so stores this in state.
    if (params.offset > 0) {
      this.setState({ loading_more: true });
    }

    const query = { sort: [{ date_occurred: { order: "desc" } }] };
    _.each(this.getState("filters"), (value, key) => {
      const data = _.isFunction(FILTERS[key]) ? FILTERS[key](value) : null;
      if (!query.must) {
        query.must = [];
      }
      if (data) {
        query.must = query.must.concat(data);
      }
    });

    InteractionSource.fetchWithQuery(contact_id, query, params);
  },

  api: {
    getInteractionsFor(id) {
      return this.getState("contact_interactions")[id];
    },

    getLoading() {
      return this.getState("loading");
    },

    getLoadingMore() {
      return this.getState("loading_more");
    },

    getFilters() {
      return this.getState("filters") || {};
    },

    getInteractionTypes() {
      return [
        { value: "Volunteer Email", label: "Email", icon: "email", prefix: "Sent email to" },
        { value: "Volunteer Phonecall", label: "Phone Call", icon: "call", prefix: "Called at" },
        {
          value: "Volunteer Text Message",
          label: "Text Message",
          icon: "phone-android",
          prefix: "Sent text message to",
        },
        {
          value: "Volunteer Social",
          label: "Social Media Interaction",
          icon: "textsms",
          prefix: "Sent social message on",
        },
        { value: "Volunteer Note", label: "Note", icon: "note-add" },
        { value: "Volunteer Virtual Visit", label: "Virtual Visit", icon: "desktop-mac" },
      ];
    },

    hasMoreToLoad(contact_id) {
      const data = this.getInteractionsFor(contact_id) || {};
      return data && data.total > data.offset + data.limit;
    },
  },
});

export default InteractionStore;
