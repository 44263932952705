import _ from "underscore";
import Decorator from "@evertrue/client-decorator";
import FilterSetup from "./filter-setup";

const { Query, Parameter, Property, ChildDoc } = Decorator.Search;

const _baseQuery = (pool_id) =>
  Query([
    ChildDoc(
      "assignment",
      Query([
        Parameter("must", [
          Property("pool_id", pool_id, { type: "equals" }),
          Property("parent_role", "prospect", { type: "equals" }),
        ]),
      ]),
    ),
  ]);

const FilterUtils = {
  getFacetsFor(field = {}) {
    const key = field.key;
    const filter_config = FilterSetup.getConfig();
    const filter_facets = filter_config[key] ? filter_config[key].facets : null;
    const filter_facet_fields = filter_facets ? filter_facets.fields : null;

    // if we've specified particular facet fields (like linkedin industry and regular industry combined)
    if (filter_facet_fields) {
      return {
        type: "contact",
        fields: _.map(filter_facet_fields, (val, field_key) => ({ field: val, label: field_key })),
      };
    } else if (field.is_custom) {
      return {
        type: "contact",
        fields: [{ field: key }],
      };
    }
    return Decorator.Filters.getFacetsFor(key);
  },

  getQuery(pool_id, filters = {}, givingCategoryLabel) {
    const query = _baseQuery(pool_id);
    const filter_config = FilterSetup.getConfig();
    const filters_custom = {};

    // merge all the filters that have a set query from filterConfig
    _.each(filters, (value, key) => {
      const config = filter_config[key];
      if (config && config.query) {
        query.merge(config.query(value, givingCategoryLabel));
      } else {
        filters_custom[key] = value;
      }
      return null;
    });

    // Custom Filters
    _.each(filters_custom, (value, key) => {
      const config = filter_config[key];
      const type = config ? config.queryType : null;

      // if the custom field is a string/list select the values
      let formatted_val = value;
      if (config && config.data_type === "string") {
        formatted_val = _.pluck(value, "value");
      }

      const custom_query = Decorator.Filters.getCustomQuery(key, formatted_val, type);
      if (!_.isEmpty(query)) {
        query.merge(custom_query);
      }
    });

    const sort = [{ "name_last.untouched": { order: "asc" } }, { "name_first.untouched": { order: "asc" } }];
    return _.extend({}, query.toJSON(), { sort });
  },

  // move to client dec
  formatPropName(prop) {
    let name = `${prop.list_name}.${prop.name}`;
    if (prop.data_type === "string") {
      name += ".untouched";
    }
    return name;
  },

  getFiltersForTab(is_assignment_tab, giving_category) {
    const filters = FilterSetup.getConfig();
    let filters_for_tab = _.filter(
      filters,
      (filter) => filter.show_tab === "both" || filter.show_tab === "prospects" || filter.is_custom,
    );
    if (is_assignment_tab) {
      filters_for_tab = _.filter(
        filters,
        (filter) => filter.show_tab === "both" || filter.show_tab === "assignments" || filter.is_custom,
      );
    }
    if (giving_category === "EverTrueNoData") {
      filters_for_tab = filters_for_tab.filter((filter) => filter.key !== "last_gift_date");
    }
    return filters_for_tab;
  },
};

export default FilterUtils;
