import _ from "underscore";
import Cookies from "js-cookie";
import config from "../config/env";
import { createScopedSession, createUnscopedSession, refreshSession, wipeSession } from "core/base/session-utils";
import { SESSION_KEY, OID_KEY } from "core/apps/auth/constants";
import Storage from "storage";

const is_stage = config.isStageUrl;

const accounts_cookie = is_stage ? "stage_etauth" : "production_etauth";

const TOKEN = SESSION_KEY;

const parseRouteOid = () => {
  try {
    const oids = new URLSearchParams(window.location.search).getAll("oid");
    const oid = _.isArray(oids) ? oids[0] : oids;
    return parseInt(oid, 10) || undefined;
  } finally {
  }
};

const parseCookiesOid = () => {
  try {
    return parseInt(Cookies.get("gt_oid"), 10) || undefined;
  } finally {
  }
};

const hydrateSession = (token, oid) => {
  if (token) {
    // we should have session and a scope
    return refreshSession(token)
      .then((session = {}) => {
        // if we have an oid, so we want to end up with the right scoped session
        if (oid) {
          if (session.type === "SCOPED") {
            // TODO: change oid to number
            if (session.oid == oid) {
              // everything seems good
              return session;
            } else {
              // oh shit, we're in the wrong org, let's change that
              return createScopedSession({ oid });
            }
          } else {
            // we got an unscoped session back, but we have an OID so we should try getting a scoped session
            return createScopedSession({ oid }).catch(err => {
              // we couldn't get out scoped session, so return the consolation prize of an unscoped session
              return session;
            });
          }
        } else {
          // we didn't have a target OID, so just return whatever we got
          return session;
        }
      })
      .catch(err => {
        if (oid) {
          // refreshing didn't work, but we know where we want to go,
          // so try to create a scoped session for that place
          return createScopedSession({ oid });
        } else {
          // we didn't have a valid token, and we don't know what scope we want
          // so just create an unscoped session and roll with it
          return createUnscopedSession();
        }
      });
  } else if (oid) {
    // we have a scope, but no token (probably a cold start from a URL oid)
    // we try to create a scoped session (which will use the skiff cookie + the oid)
    return createScopedSession({ oid }).catch(err => {
      // but if that doesn't work, just try to create an unscoped session
      return createUnscopedSession();
    });
  } else {
    // we have neither a token nor a scope (pure cold start)
    return createUnscopedSession();
  }
};

const runStartupRoutine = () => {
  const accounts_token = Cookies.get(accounts_cookie);
  const local_token = Storage.get(TOKEN);

  const local_oid = Storage.get(OID_KEY);
  const route_oid = parseRouteOid();
  const cookies_oid = parseCookiesOid();

  const token = accounts_token || local_token || undefined;
  const oid = route_oid || local_oid || cookies_oid || undefined;

  // if (oid !== local_session_oid) clearLocalStorage("session");
  if (oid !== local_oid) Storage.remove(OID_KEY);
  if (oid !== cookies_oid) Cookies.remove("gt_oid");

  try {
    if (config.isDevelopment) {
      const data = { token, oid, accounts_token, local_token, route_oid, local_oid, cookies_oid };
      const divider = "\n\n--- Startup Auth Data --- \n\n";
      console.log(divider + _.map(data, (val, key) => `${key}: ${val || " - "}`).join("\n") + divider);
    }
  } finally {
  }

  return hydrateSession(token, oid)
    .then(session => {
      return session;
    })
    .catch(error => {
      wipeSession();
      return Promise.reject(new Error(error));
    });
};
export default runStartupRoutine;
