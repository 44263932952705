import React from "react";
import _ from "underscore";
import { connect } from "@evertrue/et-flux";
import { map, isEmpty } from "underscore";
import classNames from "classnames";
import { object, string, bool, number, func } from "prop-types";
import { Pagination, Loading, EmptyStateMessage } from "@evertrue/et-components";
import ProspectCard from "apps/prospects/components/prospect-card";
import ProspectResolver from "apps/prospects/resolvers/prospect-resolver";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import utils from "utils/util-methods";

const mapStateToProps = () => ({ isMobile: ResponsiveStore.isMobile() });

const { getPageFromResults, getTotalPagesFromResults } = utils;

const propTypes = {
  pool: object,
  view: string,
  loading: bool,
  loadingInfinite: bool,
  prospects: object,
  selected: number,
  hasFilters: bool,
  onPageChange: func.isRequired,
  onLoadMore: func.isRequired,
  isMobile: bool,
};

const defaultProps = {
  pool: {},
  loading: false,
  loadingInfinite: false,
  selected: null,
  hasFilters: false,
  prospects: {},
  emptyStateLabel: "Prospects",
};

const renderEmptyStateMessage = (view, hasFilters) => {
  const emptyStateLabel = view === "prospects" ? "Prospects" : "Assignments";

  if (hasFilters) {
    return `No ${emptyStateLabel.toLowerCase()} match your search`;
  }
  return `You have no ${emptyStateLabel.toLowerCase()} in this pool`;
};

const ProspectList = ({ isMobile, onLoadMore = _.noop, ...props }) => {
  const prospectScrollBody = React.useRef(null);
  const contacts = props.prospects;
  const loading_all = props.loading && isEmpty(contacts.items);
  const hasMoreToLoad = contacts.total > contacts.offset + contacts.limit;
  const _debouncedLoadInfinite = _.debounce(onLoadMore, 300);

  // Logic for infinite scrolling - very similar to useInfiniteScroll but I
  // needed to add the + 100 to scrollTop to get it to work on android (not sure why!)
  React.useEffect(() => {
    const listElm = prospectScrollBody.current;
    if (!listElm) return;

    const scroll = () => {
      if (
        listElm.scrollTop + listElm.clientHeight + 100 >= listElm.scrollHeight &&
        hasMoreToLoad &&
        isMobile &&
        !props.loadingInfinite
      ) {
        _debouncedLoadInfinite();
      }
    };

    listElm.addEventListener("scroll", scroll);
    return () => {
      listElm.removeEventListener("scroll", scroll);
    };
    //eslint-disable-next-line
  }, [hasMoreToLoad, isMobile, props.loadingInfinite]);

  return (
    <div className={classNames("prospect-list--container", { "is-loading": loading_all })}>
      {loading_all && <Loading />}

      <div className="prospect-list">
        <div className="prospect-list--body" ref={prospectScrollBody}>
          {contacts.total === 0 && !loading_all ? (
            <EmptyStateMessage icon="person" text={renderEmptyStateMessage(props.view, props.hasFilters)} />
          ) : (
            <ProspectResolver
              contacts={contacts.items}
              pool={props.pool}
              render={({ prospects, loading }) => {
                if (loading && !loading_all && !isMobile) {
                  return <Loading />;
                }

                return (
                  <React.Fragment>
                    {map(prospects, (prospect, i) => {
                      if (prospect) {
                        const { primary, secondary } = prospect;
                        return (
                          <ProspectCard
                            {...prospect}
                            key={primary.id || i}
                            className={classNames({
                              "is-selected":
                                props.selected === primary.id || (secondary && props.selected === secondary.id),
                            })}
                          />
                        );
                      }
                      return undefined;
                    })}

                    {isMobile && (
                      <div className="prospect-list--infinite-load">
                        {props.loadingInfinite && <Loading size="small" />}
                        {contacts.total === prospects.length && (
                          <div className="prospect-list--infinite-completed">All results loaded</div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                );
              }}
            />
          )}
        </div>
      </div>

      {!isMobile && (
        <Pagination
          className="prospect-list--pagination"
          page={getPageFromResults(contacts)}
          total={getTotalPagesFromResults(contacts)}
          onChange={page => props.onPageChange(page)}
        />
      )}
    </div>
  );
};

ProspectList.propTypes = propTypes;
ProspectList.defaultProps = defaultProps;
export default connect(ProspectList, [ResponsiveStore], mapStateToProps);
