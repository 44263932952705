import React from "react";
import Tracker from "utils/tracker";
import { connect } from "@evertrue/et-flux";
import SessionSource from "apps/auth/sources/session-source";
import SessionStore from "apps/auth/stores/session-store";
import OrgStore from "apps/auth/stores/org-store";
import SafeRedirect from "components/safe-redirect";
import { Loading } from "@evertrue/et-components";
import PropTypes from "prop-types";
import appStartupRoutine from "utils/app-startup-routine";
import OrgSource from "apps/auth/sources/org-source";
import MobileFloatingFooter from "./apps/layout/controllers/mobile-floating-footer";
import ResponsiveStore from "apps/layout/stores/responsive-store";

const mapStateToProps = () => ({
  user: SessionStore.getUser() || {},
  has_session: SessionStore.hasSession(),
  loading_session: SessionStore.getLoading(),
  is_impersonating: SessionStore.getIsImpersonating(),
  organizations: OrgStore.getOrgs(),
  current_org: OrgStore.getCurrentOrg(),
  is_mobile: ResponsiveStore.isMobile(),
});

class AppController extends React.Component {
  static propTypes = {
    render: PropTypes.func,
    loading_session: PropTypes.bool,
    current_org: PropTypes.object,
    user: PropTypes.object,
    is_impersonating: PropTypes.bool,
    has_session: PropTypes.bool,
    is_mobile: PropTypes.bool,
  };

  static defaultProps = {
    render: () => null,
    user: {},
    current_org: {},
  };
  state = {
    loading: true,
  };
  componentDidMount() {
    this.setState({ loading: true });

    appStartupRoutine()
      .then(({ organization, ...session }) => {
        if (organization) {
          OrgSource.setCurrentOrgObject(organization);
        }
        SessionSource.setRefreshedSession(session);
        this.setState({ loading: false });
      })
      .catch(e => {
        console.error(e);

        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.current_org !== this.props.current_org || prevProps.user.id !== this.props.user.id) &&
      this.props.user.id &&
      this.props.current_org.id &&
      !this.props.is_impersonating
    ) {
      Tracker.setup(this.props.user, this.props.current_org.id);
    }
  }

  render() {
    const has_organization = !!this.props.current_org.id;
    if (this.state.loading) return <Loading />;
    return (
      <div className="app-controller">
        {this.props.render(this.props.has_session, has_organization, this.props.current_org.id)}
        {!this.props.has_session ? <SafeRedirect to="/login" /> : undefined}

        {this.props.is_mobile && this.props.has_session && <MobileFloatingFooter />}
      </div>
    );
  }
}

export default connect(
  AppController,
  [SessionStore, OrgStore],
  mapStateToProps,
);
