import _ from "underscore";
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Decorator from "@evertrue/client-decorator";
import { connect } from "@evertrue/et-flux";
import PoolStore from "apps/pools/pool-store";
import ProfileInteractionStore from "core/apps/interactions/interaction-store";
import ProfileInteractionSource from "core/apps/interactions/interaction-source";
import InteractionFormModal from "apps/profile/components/interaction-form";
import ResponsiveStore from "apps/layout/stores/responsive-store";
import Tracker from "utils/tracker";
import SessionStore from "apps/auth/stores/session-store";
import { Icon, ModalTriggerDeprecated, Popover2 } from "@evertrue/et-components";
import RespondTo from "components/respond-to";
import colors from "../../../utils/colors";
import OrgStore from "apps/auth/stores/org-store";
import useIsLeadVol from "hooks/use-is-lead-vol";

const mapStateToProps = () => ({
  user: SessionStore.getUser(),
  org: OrgStore.getCurrentOrg(),
  types: ProfileInteractionStore.getInteractionTypes(),
  pools: PoolStore.getPoolsIndex(),
  is_mobile: ResponsiveStore.isMobile(),
});

const InteractionItem = (props) => {
  const handleDelete = () => {
    ProfileInteractionSource.delete(props.contact.id, props.interaction.id);
    Tracker.send("interaction_action", {
      type: "delete_interaction",
      contact_id: props.contact.id,
      interaction_type: props.interaction.interaction_type,
    });
  };

  const renderModal = () => {
    return (
      <InteractionFormModal
        tab={props.tab}
        isMobile={props.is_mobile}
        contact={props.contact}
        interaction={props.interaction}
        assignment={props.assignment}
        pool={props.pool}
        header="Edit Activity"
        onCancel={() => {}}
      />
    );
  };

  let pool_id, pool_name;
  const { interaction, assignment, user, org } = props;

  const pool_label = _.findWhere(interaction.label, {
    name: "Volunteer Pool ID",
  });

  if (pool_label) {
    pool_id = _.toNumber(pool_label != null ? pool_label.value : undefined);
  }

  if (pool_id) {
    pool_name = props.pools[pool_id] != null ? props.pools[pool_id].name : undefined;
  }

  const type =
    _.findWhere(props.types, {
      value: interaction.interaction_type,
    }) || {};
  const written_by_me = interaction.creator_user_id === user.id;
  const affiliation = Decorator.User.getAffiliationFor(user, org.id) || {};
  const prospect_assigned_to_me = assignment.solicitor_contact_id === affiliation.contact_id;
  const is_lead_vol = useIsLeadVol(props.pool);
  return (
    <RespondTo className="interaction-item">
      <RespondTo className="interaction-item--icon">
        <Icon icon={type.icon || "other-interaction"} />
      </RespondTo>

      <div className="interaction-item--content">
        <div className="interaction-item--header">
          <div className="interaction-item--type-action">
            <div className="interaction-item--type">{type.label}</div>
            {written_by_me && (
              <Popover2
                placement="bottom-end"
                contentClassName="interaction-item--menu"
                trigger={
                  <RespondTo className="profile-section--view-more">
                    <Icon icon="edit" />
                    EDIT
                  </RespondTo>
                }
              >
                <div className="interaction-item--menu-body">
                  <ModalTriggerDeprecated modal={renderModal()}>
                    <div>
                      <Icon icon="edit" />
                      Edit
                    </div>
                  </ModalTriggerDeprecated>

                  <a href="#" onClick={handleDelete}>
                    <Icon icon="delete" />
                    Delete
                  </a>
                </div>
              </Popover2>
            )}
          </div>

          <div className="interaction-item--author-date-row fs-exclude-data">
            {interaction.author && interaction.author.name && (
              <div className="interaction-item--author">{`Volunteer Solicitor: ${interaction.author.name}`}</div>
            )}
            <div className="interaction-item--right-actions">
              <div className="interaction-item--date">{moment(interaction.date_occurred).format("MMM DD, YYYY")}</div>
            </div>
          </div>
        </div>
        {(prospect_assigned_to_me || written_by_me || is_lead_vol) && (
          <div className="interaction-item--body">
            <div className="interaction-item--text fs-exclude-data">{interaction.text}</div>
            <RespondTo className="interaction-item--pool-summary">
              {!!interaction.summary && interaction.summary}
              {pool_name && <RespondTo className="interaction-item--pool">{`Pool: ${pool_name}`}</RespondTo>}
            </RespondTo>
          </div>
        )}
      </div>
    </RespondTo>
  );
};
InteractionItem.propTypes = {
  contact: PropTypes.object,
  interaction: PropTypes.object,
  pool: PropTypes.object,
  assignment: PropTypes.object,
  tab: PropTypes.string,
  // from stores
  user: PropTypes.object,
  org: PropTypes.object,
  types: PropTypes.array,
  pools: PropTypes.object,
  is_mobile: PropTypes.bool,
};

InteractionItem.defaultProps = {
  contact: {},
  interaction: {},
  pool: {},
  assignment: {},
  // from stores
  user: {},
  org: {},
  types: [],
  pools: {},
  is_mobile: false,
};

export default connect(
  InteractionItem,
  [ResponsiveStore, OrgStore, SessionStore, PoolStore, ProfileInteractionStore],
  mapStateToProps,
);
