import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import UtilMethods from "utils/util-methods";
import RespondTo from "components/respond-to";

const PROPTYPES = {
  history: PropTypes.array,
};

const DEFAULT_PROPS = {
  history: [],
};

const ProfileDonorHistory = ({ history }) => {
  return (
    <RespondTo className="profile-donor-history">
      {_.map(_.compact(history), (value, i) => {
        const is_current = i === 0;
        return (
          <dl key={`${value.year}${i}`} className={is_current ? "is-current" : undefined}>
            <dt>
              <span className="profile-donor--year">
                FY:
                <strong> {value.year}</strong>
              </span>
            </dt>
            <dd>{value.amount != null ? UtilMethods.currencyFormatter(value.amount) : "N/A"}</dd>
          </dl>
        );
      })}
    </RespondTo>
  );
};

ProfileDonorHistory.propTypes = PROPTYPES;
ProfileDonorHistory.defaultProps = DEFAULT_PROPS;
export default ProfileDonorHistory;
