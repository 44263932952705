const { createSource } = require("@evertrue/et-flux");

module.exports = createSource("ApiSource", {
  actions: {
    sessionRefreshed: true,
    forbidden: true,
    mfaRequired: true,
  },

  api: {

    forbidden() {
      this.actions.forbidden();
    },

    sessionRefreshed(session) {
      this.actions.sessionRefreshed(session);
    },

    mfaRequired() {
      this.actions.mfaRequired();
    },
  },
});
