import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { Icon } from "components/et-factories";

const PROPTYPES = {
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

const ProfileSectionItem = ({ icon, className, children }) => {
  return (
    <div className={classNames("profile-section-item", className)}>
      {icon && <Icon icon={icon} />}
      {children ? (
        <div className="profile-section-item--content">{children}</div>
      ) : (
        <span className="text-placeholder">None Provided</span>
      )}
    </div>
  );
};

ProfileSectionItem.propTypes = PROPTYPES;
export default ProfileSectionItem;
