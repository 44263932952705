import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import OrgSource from "apps/auth/sources/org-source";
import SessionSource from "apps/auth/sources/session-source";
import SocketSource from "base/socket-source";

export default createStore("OrgStore", {
  getInitialState() {
    return {
      organizations: [],
      current_org_id: undefined,
      org_data: {},
      loading: false, // set to true b/c we want to load this before letting routing kick in. So deep linking works
    };
  },

  registerActions() {
    this.on(OrgSource.actions.loading, this.respondToLoading);
    this.on(OrgSource.actions.fetchedOrgs, this.respondToFetchedOrgs);
    this.on(OrgSource.actions.setCurrentOrg, this.respondToSet);
    this.on(OrgSource.actions.fetchedOrgById, _org => {
      const organizations = this.state.organizations.filter(org => org.id !== _org.id).concat(_org);
      this.setState({ organizations });
    });
    this.on(OrgSource.actions.setCurrentOrgObject, org => {
      this.setState({
        org_data: org,
        current_org_id: org.id,
      });
    });

    this.on(SessionSource.actions.logout, this.respondToLogout);
  },

  lastListenerWillUnmount() {
    if (this.state.current_org_id) {
      return SocketSource.unbindOrg(this.state.current_org_id);
    }
  },

  respondToSet(id) {
    this.setState({ current_org_id: id ? id : undefined });
    SocketSource.subscribeOrg(id);
  },

  respondToLoading(is_loading) {
    this.setState({ loading: is_loading });
  },

  respondToFetchedOrgs(data) {
    this.setState({ organizations: _.sortBy(data, "name") });
  },

  respondToFetchedOrgById(org_data) {
    this.setState({ org_data });
  },

  respondToLogout() {
    OrgSource.clear();
    this.setState({
      current_org_id: undefined,
      org_data: {},
      organizations: [],
    });
  },

  api: {
    getCurrentOrg() {
      const current_id = this.getState("current_org_id");
      const org_data = this.getState("org_data");
      if (current_id) {
        if (org_data.id === current_id) {
          return org_data;
        } else {
          const org_obj = _.find(this.getOrgs(), org => org.id === current_id);
          return org_obj || { id: current_id };
        }
      } else {
        return {};
      }
    },

    getOrgs() {
      return _.reject(this.getState("organizations"), org => !org.name);
    },

    hasManyOrgs() {
      return this.getState("organizations").length > 1;
    },

    getLoading() {
      return this.state.loading;
    },
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
