import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const PROPTYPES = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

const ProfileSection = ({ label, className, children }) => {
  return (
    <div className={classNames("profile-section", className)}>
      <div className="profile-section--subheader">{label}</div>
      {children}
    </div>
  );
};

ProfileSection.propTypes = PROPTYPES;
export default ProfileSection;
