import React from "react";
import { GateContext } from "core/apps/gates/gate-context";
import PropTypes from "prop-types";

// subscribes to the GateProvider rendered at top level of the app
const IsGated = props => (
  <GateContext.Consumer>
    {({ gates = {} }) => {
      const current_gate = gates[props.gate] || {};
      const { enabled = false } = current_gate;
      const obj = { show: enabled };
      if (typeof props.render === "function") {
        return props.render(obj);
      }
      if (typeof props.children === "function") {
        return props.children(obj);
      }

      return enabled ? props.children : null;
    }}
  </GateContext.Consumer>
);

IsGated.defaultProps = {
  gate: "",
};

IsGated.propTypes = {
  render: PropTypes.func,
  gate: PropTypes.string,
  children: PropTypes.any,
};

export default IsGated;
