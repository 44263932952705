import _ from "underscore";
import React from "react";
import classNames from "classnames";
import { Icon, ModalTriggerDeprecated, ModalDeprecated, ModalConfirm } from "@evertrue/et-components";
import PropTypes from "prop-types";
import NotificationSource from "apps/notifications/sources/notification-source";
import NotificiationContent from "apps/notifications/components/notification-content";
import GiftNotificationItem from "./gift-notification-item";

const ModalRenderWrapper = (props) => {
  return props.render(_.omit(props, "render"));
};

class NotificationItem extends React.Component {
  static propTypes = {
    notification: PropTypes.object,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    notification: {},
  };

  handleRead = () => {
    if (!this.props.notification.read_at) {
      NotificationSource.markAsRead(this.props.notification.id);
    }
    this.props.onClick();
  };

  renderGroupedModal(gifts, is_read) {
    return (
      <ModalDeprecated width={400}>
        <ModalConfirm header="Constituents who gave">
          <ModalRenderWrapper
            render={({ closeModal }) => {
              return _.map(gifts, (gift, i) => {
                return (
                  <div className="notification-item is-read" role="button" tabIndex="0" key={i} onClick={closeModal}>
                    <GiftNotificationItem className="notification-item--wrapper" gift={gift} isRead={is_read} />
                  </div>
                );
              });
            }}
          />
        </ModalConfirm>
      </ModalDeprecated>
    );
  }

  render() {
    const { notification } = this.props;
    const activity = notification.activity || { content: {} };
    const gift_details = activity.content;
    const is_grouped_gift = _.isArray(gift_details.gifts);
    const is_read = !!notification.read_at;

    const itemClass = classNames("notification-item", { "is-read": is_read });
    const wrapperClass = "notification-item--wrapper";

    if (is_grouped_gift) {
      return (
        <div className={itemClass} role="button" tabIndex="0" onClick={this.handleRead}>
          <ModalTriggerDeprecated className={wrapperClass} modal={this.renderGroupedModal(gift_details.gifts, is_read)}>
            <div className="notification-item--icon">
              <Icon icon="dollar" size={2} />
            </div>
            <NotificiationContent
              title={<strong>{`${gift_details.gifts.length} more gifts have come in!`}</strong>}
              date={notification.created_at}
            />
          </ModalTriggerDeprecated>
        </div>
      );
    }

    return (
      <div className={itemClass} role="button" tabIndex="0" onClick={this.handleRead}>
        <GiftNotificationItem className={wrapperClass} gift={gift_details} isRead={is_read} />
      </div>
    );
  }
}

export default NotificationItem;
