/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { createSource } from "@evertrue/et-flux";

export default createSource("ResponsiveSource", {
  actions: {
    resize: true,
    breakpoint: true,
    poolExpand: true,
    poolCollapse: true,
  },

  api: {
    resize(dimensions) {
      return this.actions.resize(dimensions);
    },

    breakpoint() {
      return this.actions.breakpoint();
    },

    poolExpand() {
      return this.actions.poolExpand();
    },

    poolCollapse() {
      return this.actions.poolCollapse();
    },
  },
});
