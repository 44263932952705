import PropTypes from "prop-types";
import React from "react";
import { connect } from "@evertrue/et-flux";
import { OverlayActions, MobileSheet, ModalDeprecated, ModalConfirm } from "@evertrue/et-components";
import ResponsiveStore from "apps/layout/stores/responsive-store";

const mapStateToProps = () => ({ is_mobile: ResponsiveStore.isMobile() });

class ResponsiveModal extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    header: PropTypes.any,
    mobileOptions: PropTypes.object,
    desktopOptions: PropTypes.object,
    is_mobile: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.is_mobile && !!this.props.is_mobile) {
      this.handleRemoveSheet();
    }
  }

  handleRemoveSheet = () => {
    OverlayActions.removeOverlay();
  };

  render() {
    const { children, className } = this.props;
    if (this.props.is_mobile) {
      return (
        <MobileSheet
          className={`responsive-modal is-mobile${className ? ` ${className}` : ""}`}
          label={this.props.header}
          onBack={this.handleRemoveSheet}
          {...this.props.mobileOptions}
        >
          {children}
        </MobileSheet>
      );
    }

    return (
      <ModalDeprecated width={this.props.width} className={`responsive-modal${className ? ` ${className}` : ""}`}>
        <ModalConfirm header={this.props.header} {...this.props.desktopOptions}>
          {children}
        </ModalConfirm>
      </ModalDeprecated>
    );
  }
}

export default connect(ResponsiveModal, [ResponsiveStore], mapStateToProps);
