import React from "react";
import PropTypes from "prop-types";
import { Icon, PlainButton } from "@evertrue/et-components";
import ResponsivePopover2 from "components/responsive-popover2";
import { Link, Route } from "react-router-dom";
import useGate from "core/apps/gates/use-gate";
import classNames from "classnames";
import useIsLeadVol from "hooks/use-is-lead-vol";

const TheLink = props => (
  <Route
    render={({ location: { pathname } }) => {
      // Assignments name here is misleading. It means either the prospects or assignments route
      // this just toggles the name on the menu so user can flip btwn sections
      const section_name =
        pathname.includes("assignments") || pathname.includes("prospects") ? "Reporting" : "Assignments";
      return (
        <Link
          onClick={props.onClick}
          className={props.className}
          to={`/pools/${props.pool.id}/${section_name.toLowerCase()}`}
        >
          {!props.isMobile && <Icon size={1} icon={section_name !== "Reporting" ? "group" : "poolreport"} />} Show{" "}
          {section_name}
        </Link>
      );
    }}
  />
);

TheLink.propTypes = {
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
  pool: PropTypes.object,
  className: PropTypes.string,
};

const style = { paddingLeft: 16 };

const PoolNameDropdown = ({ pool = {}, isMobile }) => {
  const { name } = pool;
  const is_lead_vol = useIsLeadVol(pool);

  const table_gate = useGate({ gate: "vms_head_agent_table" });
  const summary_gate = useGate({ gate: "vms_head_agent_reporting_summary" });
  const show_reporting_dropdown = table_gate || summary_gate;

  return show_reporting_dropdown && is_lead_vol ? (
    <ResponsivePopover2
      alignPopover="start"
      mobileContent={({ close }) => (
        <div>
          <div className="pool-name-dropdown--nav">{pool.name}</div>
          <TheLink pool={pool} onClick={close} isMobile={true} className="pool-name-dropdown--nav" />
        </div>
      )}
      content={({ close }) => (
        <div className="pool-name-dropdown--content">
          <TheLink className="pool-name-dropdown--nav" onClick={close} pool={pool} />
        </div>
      )}
      triggerButton={({ toggle, isOpen }) => (
        <PlainButton
          className={classNames("pool-name-dropdown--pool-button", { "is-mobile": isMobile })}
          onClick={toggle}
        >
          {isMobile && (
            <Icon className="pool-name-dropdown--icon is-mobile" icon={`chevron-${isOpen ? "up" : "down"}`} />
          )}
          <span className={classNames("pool-name-dropdown--pool-label ellipsis-overflow", { "is-mobile": isMobile })}>
            {name}
          </span>

          {!isMobile && <Icon className="pool-name-dropdown--icon" icon={`chevron-${isOpen ? "up" : "down"}`} />}
        </PlainButton>
      )}
    />
  ) : (
    <div className="pool-name-dropdown--name">{name}</div>
  );
};

PoolNameDropdown.propTypes = {
  pool: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default PoolNameDropdown;
