import _ from "underscore";
import React from "react";
import Decorator from "@evertrue/client-decorator";
import Api from "base/api";
import { Link } from "react-router-dom";
import {
  ModalDeprecated,
  ModalConfirm,
  SearchBox,
  OverlayActions,
  MobileSheet,
  Loading,
} from "@evertrue/et-components";
import UtilMethods from "utils/util-methods";
import SessionSource from "apps/auth/sources/session-source";
import PropTypes from "prop-types";

class ImpersonationModal extends React.Component {
  static propTypes = {
    org: PropTypes.object,
    user: PropTypes.object,
    is_mobile: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    org: {},
    user: {},
    is_mobile: false,
  };

  state = {
    name: "",
    loading: false,
    users: [],
    impersonation_started: false,
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleChange = (name) => {
    this.setState({ name });
    clearTimeout(this.timeout);
    if (name) {
      this.timeout = _.delay(() => this.searchVolunteerUsers(name), 500);
    } else {
      this.setState({ users: [] });
    }
  };

  handleSelect = (user) => {
    SessionSource.startImpersonation(user);
    this.props.onClose();
  };

  searchVolunteerUsers = (name) => {
    const roles_array = UtilMethods.getVolunteerRolesArray(this.props.org);
    const query = Decorator.User.getUsersSearchQuery(name, roles_array);

    this.setState({ loading: true });
    Api.AUTH.USERS_SEARCH.post({
      data: query,
      success: ({ users = [] } = {}) => {
        // filter out logged in user
        const filtered_users = _.reject(users, (user) => user.id === this.props.user.id);
        this.setState({ users: filtered_users, loading: false });
      },
      error: () => {
        this.setState({ loading: false });
      },
    });
  };

  renderUsers = () => {
    if (this.state.loading) {
      return <Loading />;
    } else if (_.isEmpty(this.state.users) && this.state.name) {
      return <div className="impersonation-modal--text">No results found.</div>;
    } else if (_.isEmpty(this.state.users)) {
      return <div className="impersonation-modal--text">Search to find a Volunteer user to impersonate.</div>;
    }
    return _.map(this.state.users, (user) => {
      const onClick = (e) => {
        e.preventDefault();
        this.handleSelect(user);
      };

      return (
        <Link to="/pools" onClick={onClick} className="impersonation-user-row" key={user.email}>
          <div className="impersonation-user-cell impersonation-user-cell--first">{user.name}</div>
          <div className="impersonation-user-cell">{user.email}</div>
          <div className="impersonation-user-cell impersonation-user-cell--last">Impersonate</div>
        </Link>
      );
    });
  };

  renderContent = () => {
    return (
      <div className="impersonation-modal">
        <SearchBox placeholder="Search users by name" onChange={this.handleChange} name={this.state.name} />
        <div className="impersonation-modal--body">{this.renderUsers()}</div>
      </div>
    );
  };

  render() {
    const { is_mobile, ...modalTriggerProps } = this.props;
    if (is_mobile) {
      return (
        <MobileSheet label="Impersonate User" onBack={() => OverlayActions.removeOverlay()} backgroundColor="#FFF">
          {this.renderContent()}
        </MobileSheet>
      );
    }
    return (
      <ModalDeprecated width={650} {...modalTriggerProps}>
        <ModalConfirm header="Impersonate User" onCancel={_.noop}>
          {this.renderContent()}
        </ModalConfirm>
      </ModalDeprecated>
    );
  }
}

export default ImpersonationModal;
