import React, { Component } from "react";
import PropTypes from "prop-types";
import Api from "base/api";
import ErrorLogger from "utils/error-logger";

export const GateContext = React.createContext();

class GateProvider extends Component {
  static propTypes = {
    currentOrg: PropTypes.object,
    hasSession: PropTypes.bool,
    children: PropTypes.any,
  };

  static defaultProps = {
    currentOrg: {},
    hasSession: false,
    children: () => null,
  };

  state = {
    gates: {},
  };

  is_mounted = null;

  componentDidMount() {
    this.is_mounted = true;
    this.fetchGates();
  }

  componentDidUpdate(prevProps) {
    if (this.props.hasSession && !prevProps.hasSession) {
      this.fetchGates();
    } else if (prevProps.currentOrg.id && !this.props.currentOrg.id) {
      this.clearGateState();
    } else if (this.props.hasSession && prevProps.currentOrg.id !== this.props.currentOrg.id) {
      this.fetchGates();
    }
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  clearGateState = () => {
    this.setState({ gates: {} });
  };

  fetchGates() {
    if (!this.is_mounted) return;

    if (this.props.hasSession && this.props.currentOrg.id) {
      Api.DNA.GATES.get({
        params: { platform: "web", user_id: "me" },
      })
        .then((resp = {}) => {
          this.setState({ gates: resp.features });
        })
        .catch(e => {
          this.setState({ gates: {} });
          ErrorLogger.captureRequest("DNA gates Error: /dna/gates", e);
        });
    }
  }

  render() {
    return <GateContext.Provider value={{ gates: this.state.gates }}>{this.props.children}</GateContext.Provider>;
  }
}

export default GateProvider;
