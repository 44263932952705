import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import Api from "base/api";
import FilterUtils from "core/apps/filters/filter-utils";
import Utils from "core/utils/utils";
import { AdvancedComboboxContent } from "@evertrue/et-components";

const { getFacetsFor } = FilterUtils;

const _formatFacets = (facets, search_term) => {
  // this extra client side filter is needed to filter facets on the search string
  // for cases when the facets search return extra values and/or empty values
  const filtered_facets = _.reject(facets, (facet) => {
    return (
      (facet.value ? facet.value.trim() : null) === "" ||
      (search_term ? !Utils.searchCompare(facet.value, search_term) : null)
    );
  });

  const sorted_facets = _.sortBy(
    _.map(filtered_facets, (facet) => {
      return { value: facet.value, label: facet.value };
    }),
    "label",
  );
  return sorted_facets;
};

class FilterDropdown extends React.Component {
  static propTypes = {
    filter: PropTypes.object,
    value: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    value: [],
    onChange: _.noop,
  };

  state = {
    options: [],
    grouped: false,
    loading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.fetchFacets();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timeout);
  }

  fetchFacets = (term) => {
    this.setState({ loading: true });
    if (this.request) {
      this.request.abort();
    }
    const search = term ? `^${term}.*|.*\\s${term}.*` : undefined;

    const facet_config = getFacetsFor(this.props.filter);
    let keys = _.pluck(facet_config.fields, "field");

    if (keys.length) {
      Api.CONTACTS.FACETS.post({
        data: {
          has_child: {
            type: "assignment",
            query: {
              must: [{ parent_role: { match: "prospect" } }],
            },
          },
          facet: _.map(keys, (key) => ({ field: key, regex: search, size: 250 })),
        },
        beforeSend(xhr) {
          this.request = xhr;
        },
        success: ({ facets = [] }) => {
          let options;
          if (keys.length > 1) {
            const labels = _.indexBy(facet_config.fields, "field");
            options = _.map(keys, (key) => {
              return {
                label: labels[key] != null ? labels[key].label : undefined,
                items: _formatFacets(facets[key], term),
              };
            });
          } else {
            options = _formatFacets(facets[keys[0]], term);
          }

          if (this.mounted) {
            this.setState({
              grouped: keys.length > 1,
              options,
              loading: false,
            });
          }
        },
      });
    }
  };

  handleSearch = (keyword) => {
    clearTimeout(this.timeout);
    this.timeout = _.delay(() => {
      this.fetchFacets(keyword);
    }, 100);
  };

  render() {
    return (
      <div className="filter-dropdown">
        <AdvancedComboboxContent
          value={this.props.value}
          grouped={this.state.grouped}
          options={this.state.options}
          loading={this.state.loading}
          searchable
          multiple
          allowChangeAll
          allowUnselectAll
          onSearch={this.handleSearch}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default FilterDropdown;
