import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { connect } from "@evertrue/et-flux";
import { ModalState, Popover2, PlainButton } from "@evertrue/et-components";
import ResponsiveStore from "apps/layout/stores/responsive-store";

const POPOVER_BOTTOM_MARGIN = 30;
const mapStateToProps = () => ({
  is_mobile: ResponsiveStore.isMobile(),
  window_height: ResponsiveStore.getHeight(),
});

class ResponsivePopover extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    position: PropTypes.string,
    render: PropTypes.func.isRequired,
    triggerStyles: PropTypes.object,
    is_mobile: PropTypes.bool,
    window_height: PropTypes.number,
  };

  state = {
    show: false,
    offset: 200,
  };

  handleClose = () => {
    _.defer(() => {
      if (this.popover && this.popover.handleClose) {
        this.popover.handleClose();
      }
      this.setState({ show: false });
    });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  renderContent = closePopover => {
    const max_height = this.props.window_height - this.state.offset - POPOVER_BOTTOM_MARGIN;
    const style = {};
    if (!this.props.is_mobile) {
      style.height = Math.min(500, max_height);
    }

    return (
      <div className={classNames("responsive-popover--inner", { "is-mobile": this.props.is_mobile })} style={style}>
        {this.props.render(closePopover)}
      </div>
    );
  };

  render() {
    if (this.state.show && this.props.is_mobile) {
      return (
        <ModalState isOpen={this.state.show} onClose={this.handleClose}>
          <React.Fragment>{this.renderContent(this.handleClose)}</React.Fragment>
        </ModalState>
      );
    }

    return (
      <Popover2
        wrapperClassName={classNames("responsive-popover", this.props.className)}
        contentWidth={this.props.width || 300}
        placement={this.props.position}
        trigger={({ togglePopover, is_open, openPopover }) => (
          <PlainButton
            onClick={this.props.is_mobile ? this.handleShow : togglePopover}
            className={classNames("responsive-popover--trigger", this.props.triggerClassName)}
            style={this.props.triggerStyles}
          >
            {typeof this.props.children === "function" ? this.props.children({ is_open }) : this.props.children}
          </PlainButton>
        )}
      >
        {({ closePopover }) => this.renderContent(closePopover)}
      </Popover2>
    );
  }
}
export default connect(
  ResponsivePopover,
  [ResponsiveStore],
  mapStateToProps,
);
