import React from "react";
import Decorator from "@evertrue/client-decorator";
import classNames from "classnames";
import { connect } from "@evertrue/et-flux";
import { ModalTriggerDeprecated } from "@evertrue/et-components";
import ProfileAssignment from "apps/profile/components/profile-assignment";
import PropTypes from "prop-types";
import InteractionForm from "apps/profile/components/interaction-form";
import ResponsiveStore from "apps/layout/stores/responsive-store";

const PoolLink = require("apps/pools/components/pool-link").default;
const RespondTo = require("components/respond-to").default;
const ProspectAvatar = require("apps/prospects/components/prospect-avatar").default;
const StageSelector = require("apps/profile/components/stage-selector").default;

const mapStateToProps = () => ({
  isMobile: ResponsiveStore.isMobile(),
  isTablet: ResponsiveStore.isTablet(),
});

class ProfileHeader extends React.Component {
  static propTypes = {
    contact: PropTypes.object,
    pool: PropTypes.object,
    tab: PropTypes.string,
    assignment: PropTypes.object,
    navItem: PropTypes.string,
    showGiving: PropTypes.bool,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
    canUpdateStagesAndAmounts: PropTypes.bool,
  };

  static defaultProps = {
    contact: {},
    pool: {},
    assignment: {},
    canUpdateStagesAndAmounts: false,
  };

  renderNavItem = (label, route) => {
    let is_selected;
    if (this.props.navItem) {
      is_selected = route === `/${this.props.navItem}`;
    } else {
      is_selected = !route;
    }

    return (
      <PoolLink
        to={`/${this.props.contact != null ? this.props.contact.id : undefined}${route || ""}`}
        className={classNames("profile-header--nav-item", { "is-selected": is_selected })}
      >
        {label}
      </PoolLink>
    );
  };

  render() {
    const { contact, canUpdateStagesAndAmounts } = this.props;
    const year = Decorator.Contacts.getClassYear(contact);
    const is_deceased = contact.properties && contact.properties.deceased && contact.properties.deceased.value;
    const assignment_stage = this.props.assignment && this.props.assignment.assignment_stage;

    return (
      <div>
        <RespondTo className="profile-header">
          <RespondTo to={["tablet", "desktop"]} className="profile-header--avatar">
            <ProspectAvatar prospect={contact} />
          </RespondTo>

          <div className="profile-header--title fs-exclude-data">
            <RespondTo className="profile-header--name-stages">
              <RespondTo className={classNames("profile-header--name", { "is-deceased": is_deceased })}>
                {Decorator.Contacts.getDetailedName(contact)}
                {is_deceased ? <span className="deceased-tag">Deceased</span> : undefined}
              </RespondTo>
              {this.props.pool.stage_group_id !== 0 && (
                <RespondTo to={["tablet", "desktop"]} className="profile-header--stage-section">
                  <span className="profile-header--stage-label">Stage:</span>
                  {canUpdateStagesAndAmounts ? (
                    <StageSelector
                      assignmentId={this.props.assignment.assignment_id}
                      className="profile-header--stages-dropdown"
                      saveOnChange={true}
                      selectedStage={assignment_stage}
                      stageGroupId={this.props.pool.stage_group_id}
                    />
                  ) : assignment_stage ? (
                    assignment_stage
                  ) : (
                    <span className="text-placeholder">None Provided</span>
                  )}
                </RespondTo>
              )}
            </RespondTo>

            {year && (
              <RespondTo className="profile-header--info-row">
                <span className="profile-header--info-col profile-header--class">{year}</span>
              </RespondTo>
            )}
            {this.props.pool.has_self_selection && (
              <ProfileAssignment contact={contact} pool={this.props.pool} tab={this.props.tab} />
            )}

            <ModalTriggerDeprecated
              buttonType="action"
              modal={
                <InteractionForm
                  tab={this.props.tab}
                  isMobile={this.props.isMobile || this.props.isTablet}
                  contact={contact}
                  assignment={this.props.assignment}
                  pool={this.props.pool}
                  header="Add Note / Log Activity"
                />
              }
            >
              Log Activity
            </ModalTriggerDeprecated>
          </div>
        </RespondTo>

        <RespondTo className="profile-header--nav">
          {this.renderNavItem("Info")}
          {this.props.showGiving && this.props.pool.giving_category_label !== "EverTrueNoData"
            ? this.renderNavItem("Giving", "/giving")
            : undefined}
          {this.renderNavItem("Activity", "/activity")}
        </RespondTo>
      </div>
    );
  }
}

export default connect(ProfileHeader, [ResponsiveStore], mapStateToProps);
