import React from "react";
import ProfileHeader from "apps/profile/components/profile-header";
import ProfileBio from "apps/profile/controllers/profile-bio-controller";
import ProfileActivityController from "apps/profile/controllers/profile-activity-controller";
import ProfileGivingController from "apps/profile/controllers/profile-giving-controller";
import PropTypes from "prop-types";
import useIsLeadVol from "hooks/use-is-lead-vol";

const propTypes = {
  tab: PropTypes.string,
  pool: PropTypes.object,
  profile: PropTypes.object,
  assignment: PropTypes.object,
  section: PropTypes.string,
};

const ProfileBodyController = (props) => {
  const { profile } = props;
  const is_assignments = props.tab === "assignments";
  let body; // eslint-disable-line
  const lead_vol_and_head_agent_gate = useIsLeadVol(props.pool);
  const show_all_sections = is_assignments || lead_vol_and_head_agent_gate;
  const canUpdateStagesAndAmounts = is_assignments || (lead_vol_and_head_agent_gate && props.pool.lead_updates_enabled);

  return (
    <div className="profile-body">
      <div className="profile-body--wrapper">
        <ProfileHeader
          assignment={props.assignment}
          contact={profile}
          pool={props.pool}
          tab={props.tab}
          navItem={props.section}
          showGiving={show_all_sections}
          showContactSection={show_all_sections}
          canUpdateStagesAndAmounts={canUpdateStagesAndAmounts}
        />
        <div
          className="profile-body--content"
          ref={(e) => {
            body = e;
          }}
        >
          {(() => {
            switch (props.section) {
              case "giving":
                return (
                  <ProfileGivingController
                    contact={profile}
                    pool={props.pool}
                    canUpdateStagesAndAmounts={canUpdateStagesAndAmounts}
                  />
                );

              case "activity":
                return (
                  <ProfileActivityController
                    key={profile.id}
                    contact={profile}
                    tab={props.tab}
                    assignment={props.assignment}
                    pool={props.pool}
                  />
                );

              default:
                return (
                  <ProfileBio
                    assignment={props.assignment}
                    poolId={props.pool.id}
                    contact={profile}
                    showContactSection={show_all_sections}
                    tab={props.tab}
                  />
                );
            }
          })()}
        </div>
      </div>
    </div>
  );
};

ProfileBodyController.propTypes = propTypes;
export default ProfileBodyController;
