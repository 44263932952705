import _ from "underscore";
import classNames from "classnames";
import React from "react";
import Tracker from "utils/tracker";
import { createComponent } from "@evertrue/et-flux";
import ProfileBodyControllerClass from "apps/profile/controllers/profile-body-controller";
import ContactsResolver from "core/apps/contacts/resolvers/contacts-resolver";
import ProspectAssignmentStore from "core/apps/prospects/prospect-assignments-store";
import ProspectAssignmentSource from "core/apps/prospects/prospect-assignments-source";
import SocialProfileSource from "core/apps/profile/social-profile-source";
import { Icon, EmptyStateMessage, Loading } from "components/et-factories";
import utils from "utils/util-methods";
import { div } from "react-dom-factories";
import PropTypes from "prop-types";

const ProfileBodyController = React.createFactory(ProfileBodyControllerClass);

const RespondTo = React.createFactory(require("components/respond-to").default);
const PoolLink = React.createFactory(require("apps/pools/components/pool-link").default);

const { capitalizeString } = utils;

export default createComponent("ProfileController", {
  propTypes: {
    id: PropTypes.number,
    tab: PropTypes.string,
    pool: PropTypes.object,
    section: PropTypes.string,
  },

  getDefaultProps() {
    return {
      tab: "assignments",
      pool: {},
    };
  },

  registerStores() {
    return this.on(ProspectAssignmentStore, ["pool", "id"], ({ pool, id }) => ({
      assignment: ProspectAssignmentStore.getByPrimaryOrSecondary(pool.id, id),
    }));
  },

  componentDidMount() {
    if (this.props.id && this.props.pool.id) {
      this.fetchAssignments(this.props);
      return SocialProfileSource.fetch(this.props.id);
    }
  },

  componentWillReceiveProps(newProps) {
    if (newProps.id !== this.props.id && newProps.id) {
      this.fetchAssignments(newProps);
      return SocialProfileSource.fetch(newProps.id);
    } else if (!_.isEqual(newProps.pool, this.props.pool)) {
      return this.fetchAssignments(newProps);
    }
  },

  fetchAssignments() {
    const { pool, id } = this.props;
    if (id && pool.id) {
      return ProspectAssignmentSource.fetchUnlessCached(pool.id, id);
    }
  },

  renderTab(key, contact_id, is_active) {
    return PoolLink(
      {
        to: `/${contact_id}`,
        className: classNames("profile--tab", { "is-active": is_active }),
        onClick() {
          return Tracker.send("profile_viewed", { contact_id, is_secondary: key === "secondary" });
        },
      },
      `${key} prospect`,
    );
  },

  render() {
    const typeString = capitalizeString(this.props.tab).slice(0, this.props.tab.length - 1);

    const primary_id = this.state.assignment != null ? this.state.assignment.prospect_contact_id : undefined;
    const secondary_id = _.first(
      this.state.assignment != null ? this.state.assignment.secondary_prospect_contact_ids : undefined,
    );
    const is_joint = !!secondary_id;
    const is_primary = primary_id === this.props.id;

    return ContactsResolver({
      ids: [primary_id, secondary_id],
      poolId: this.props.pool.id,
      render: ({ contacts, loading }) => {
        const profile = contacts[primary_id];
        const secondary_profile = contacts[secondary_id];

        return div(
          { className: "profile" },
          loading
            ? Loading()
            : _.isEmpty(profile)
              ? EmptyStateMessage({
                  icon: "person",
                  text: `No ${typeString} Selected`,
                })
              : RespondTo(
                  { className: "profile--wrapper" },
                  is_joint
                    ? div(
                        { className: "profile--tabs" },
                        this.renderTab("primary", profile.id, is_primary),
                        Icon({ icon: "link", size: 2, className: "profile--tab-icon" }),
                        this.renderTab("secondary", secondary_id, !is_primary),
                      )
                    : undefined,

                  div(
                    { className: classNames("profile--content", { "is-joint": is_joint }) },
                    ProfileBodyController({
                      assignment: this.state.assignment,
                      profile: is_primary ? profile : secondary_profile,
                      tab: this.props.tab,
                      pool: this.props.pool,
                      section: this.props.section,
                      isPrimary: is_primary,
                    }),
                  ),
                ),
        );
      },
    });
  },
});
