import React from "react";
import { Icon, Portal } from "@evertrue/et-components";
import { Link, Route } from "react-router-dom";
import NotificationInboxController from "apps/notifications/controllers/notification-inbox-controller";
import NotificationIcon from "apps/notifications/components/notification-icon";

const MobileFloatingFooter = (props) => {
  const [is_open, setOpen] = React.useState(false);
  const closeNotifications = () => setOpen(false);

  return (
    <Portal>
      <div className="mobile-floating-footer">
        <Route>
          {({ match, location }) => {
            const pool_url = location.pathname.includes("pools") ? location.pathname : "/pools";
            return (
              <Link
                to={pool_url}
                className={!is_open ? "mobile-floating-footer--active-item" : ""}
                onClick={() => {
                  closeNotifications();
                }}
              >
                <Icon icon="pools" className="mobile-floating-footer--pool-link" size={3} />
              </Link>
            );
          }}
        </Route>
        <NotificationIcon
          className={is_open ? "mobile-floating-footer--active-item" : ""}
          onClick={() => {
            setOpen(true);
          }}
        />
        {is_open && (
          <Portal>
            <NotificationInboxController onClose={closeNotifications} />
          </Portal>
        )}
      </div>
    </Portal>
  );
};

MobileFloatingFooter.propTypes = {};

export default MobileFloatingFooter;
