import React from "react";
import numeral from "numeral";
import { Icon, Avatar } from "@evertrue/et-components";
import PropTypes from "prop-types";
import PoolLink from "apps/pools/components/pool-link";
import NotificiationContent from "apps/notifications/components/notification-content";

class GiftNotificationItem extends React.Component {
  static propTypes = {
    gift: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    gift: {},
    className: "",
  };

  renderNotificationTitle(details) {
    const designation = details.gift_designation;

    return (
      <span>
        <strong className="notification-item--name">{details.contact_name || "Your Assignment"} </strong>
        gave
        <strong> {numeral(details.gift_amount).format("$0,0")}</strong>
        {designation !== "n/a" ? <span> to {designation}</span> : null}
      </span>
    );
  }

  renderAvatar(details) {
    if (details.contact_avatar) {
      return <Avatar url={details.contact_avatar} width={35} />;
    }

    return (
      <div className="notification-item--icon">
        <Icon icon="dollar" size={2} />
      </div>
    );
  }

  render() {
    const { gift, className } = this.props;

    return (
      <PoolLink className={className} to={`/${gift.contact_id}`} pool={gift.pool_id} tab={"assignments"}>
        {this.renderAvatar(gift)}
        <NotificiationContent
          title={this.renderNotificationTitle(gift)}
          date={gift.gift_date_millis}
          org={gift.organization_name}
        />
      </PoolLink>
    );
  }
}

export default GiftNotificationItem;
